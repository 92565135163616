import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthenticationService} from './authentication.service';

@Injectable()
export class UnauthenticatedAccessInterceptor implements HttpInterceptor {
  constructor(private auth: AuthenticationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
    /*  if (err.status === 401) {
        // auto logout if 401 response returned from api
        this.auth.logout();
        // location.reload();
      }*/

      const error = err.message || err.statusText;
      console.log(error);
      return throwError(err);
    }));
  }
}
