import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {CurrentContextService} from './current-context.service';
import {LandlordLocationModel} from '../../model/landlord-location.model';
import {PathLocationContextGuardService} from './path-location-context-guard.service';


@Injectable({
  providedIn: 'root'
})
export class PathOfferingContextGuardService implements CanActivate {

  constructor(private router: Router,
              private context: CurrentContextService,
              private _locationGuard: PathLocationContextGuardService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    return this._locationGuard.canActivate(route, state).then((activated: boolean) => {
      if (!activated) {
        return false;
      }

      let offeringCode = route.paramMap.get('offeringCode')?.toLocaleLowerCase();
      let currentLandlordLocation: LandlordLocationModel | null = this.context.getCurrentLandlordLocation();

      if (offeringCode === null
        || offeringCode === undefined
        || offeringCode.toLowerCase() === 'null'
        || currentLandlordLocation == null) {

        this.router.navigate(['404'])
          .catch((error: string) => {
            console.log(error)
          });
        return false;
      }


      // Attempt to set the current offering
      return this.context.setCurrentOfferingByCode(currentLandlordLocation.locationCode, offeringCode)
        .then(() => {
          // Check if the offering was successfully set
          return Promise.resolve(this.context.isOfferingSetSynchronus());
        })
        .then((isOfferingSet): Promise<boolean> => {
          if (!isOfferingSet) {
            this.router.navigate(['/404'])
              .catch(error => console.error(error));
            return Promise.resolve(false);
          }
          return Promise.resolve(true); // Allow navigation
        })
        .catch(error => {
          console.error(error);
          return false; // Deny navigation on error
        });

    })
  }

}
