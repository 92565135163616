import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {CurrentContextService} from '../../../../core/services/security/current-context.service';
import {GoogleAnalyticsService} from '../../../../core/services/google-analytics.service';
import {CustomValidators} from '../../../../shared/validators/custom-validators';
import {Industry, IndustryCategory, Retailer} from '../../../../core/model/retailer.model';
import {RetailerService} from '../../../../core/services/retailer.service';
import {lastValueFrom} from 'rxjs';
import {LoadingService} from "../../../../core/services/loading.service";

@Component({
  selector: 'app-registration-retailer-brand',
  templateUrl: './registration-retailer-brand.component.html',
  styleUrls: ['./registration-retailer-brand.component.scss']
})
export class RegistrationRetailerBrandComponent implements OnInit {
  public registrationForm: FormGroup;
  website = false;
  public industryList: IndustryCategory[] = [];
  mobNumberPattern = '^((\\+91-?)|0)?[0-9]{10}$';
  public errorMsg: string | null = null;
  public loading = false;
  selectedIndustries: string[] = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private retailerService: RetailerService,
    private fb: FormBuilder,
    private context: CurrentContextService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private googleAnalyticsService: GoogleAnalyticsService,
    public loader: LoadingService
  ) {
    this.matIconRegistry.addSvgIcon('icon-soko-plus',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/actions/plus-circle.svg'));

    this.registrationForm = this.resetForm();
    this.addProspectiveStore();
  }

  get prospectiveStores(): FormArray {
    return this.registrationForm.get('prospectiveStores') as FormArray;
  }

  ngOnInit(): void {
    this.loadIndustries();
    this.googleAnalyticsService.customEventEmitter('Retailer Onboarding', 'view', 'retailerRegister_view');
  }

  toggleWebsite(): void {
    this.website = !this.website;
    if (this.website) {
      this.registrationForm.controls['websiteUrl'].setValue('');
      this.registrationForm.controls['websiteUrl'].disable();
    } else {
      this.registrationForm.controls['websiteUrl'].enable();
    }
  }

  public addProspectiveStore(): void {
    this.googleAnalyticsService.customEventEmitter('Retailer Onboarding', 'click', 'retailerRegister_additionalProspectiveLocation_add');
    this.prospectiveStores.push(this.buildProspectiveStoreForm());
  }

  submit(): void {
    this.errorMsg = null;
    const retailer = this.registrationForm.value;
    if (this.website) {
      retailer.websiteUrl = null;
    }

    this.loading = true;

    lastValueFrom(this.retailerService.registerNewRetailer(retailer))
      .then((res: Retailer) => {
          this.loading = false;
          res.retailerNew = true;
          this.context.setCurrentRetailerInContext(res);
          this.router.navigate([`/${this.context.getCurrentLocationCode()}/home`]);
          this.googleAnalyticsService.customEventEmitter('Retailer Onboarding', 'submit', 'retailer_register_submit');
        },
        err => {
          this.loading = false;
          console.log(err);
          if ('ValidationException' === err.headers.get('error_code')) {
            this.errorMsg = err.headers.get('error_message');
          }
        }
      );
  }

  groupClicked(group: IndustryCategory): void {
    this.industryList.forEach(gro => {
      if (gro.id === group.id) {
        gro.industries.forEach(ind => ind.selected = !gro.selected);
        gro.selected = !gro.selected;
      }
    });
    this.updateSelected();
  }

  updateSelected(): void {
    const newSelects: string[] = [];
    this.industryList.forEach(gro => {
        gro.industries.forEach(ind => {
          if (ind.selected) newSelects.push(ind.code);
        });
      }
    );
    this.selectedIndustries = newSelects;
  }

  optionClicked(group: IndustryCategory, item: Industry): void {
    if (group.selected) group.selected = !group.selected;
    item.selected = !item.selected;
    this.updateSelected();
  }

  private resetForm(): FormGroup {
    return this.fb.group({
      companyName: [null, Validators.required],
      industries: [[], Validators.required],
      websiteUrl: [null, Validators.compose([CustomValidators.websiteUrl, Validators.required])],
      mobile: [null, Validators.required],
      socialMediaUrl: [null, Validators.compose([CustomValidators.websiteUrl, Validators.required])],
      prospectiveStores: this.fb.array([])
    });
  }

  private buildProspectiveStoreForm(): FormGroup {
    return this.fb.group({
      address: [null],
      geometry: [null],
      place: [null]
    });
  }

  private loadIndustries(): void {
    this.loading = true;
    lastValueFrom(this.retailerService.getIndustries())
      .then((res: IndustryCategory[]) => {
          this.loading = false;
          this.industryList = res;
          this.industryList.forEach(cat => {
            cat.selected = false;
            cat.industries.forEach(ind => {
              ind.selected = false
            });
          });
        },
        (err: string) => {
          this.loading = false;
          this.errorMsg = 'Could not load industries due to an unexpected error';
          console.log(err);
        }
      );
  }
}
