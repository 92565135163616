<div fxLayout="column" fxLayoutAlign="center">
  <div class="dialog-header my-2" fxLayout="row" fxLayoutAlign="space-between center">
    <span style="width: 24px"></span>
    <div class="mat-headline popup-header">{{ header }}</div>
    <mat-icon (click)="close()" class="btn-cursor mb-3">close</mat-icon>
  </div>
  <div class="separator-band"></div>

  <mat-dialog-content fxLayout="column" fxLayoutAlign="center center">
    <div class="full-width" fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex="30" fxFlex.gt-sm="35" fxLayout="row" fxLayoutAlign="center center">
        <img [src]="imageSrc" class="popup-icon">
      </div>
      <div fxFlex="70" fxFlex.gt-sm="65" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
        <p class="error-popup-description">{{ description }}</p>
        <p *ngIf="boldDescription" class="error-popup-boldDescription">
          {{ boldDescription }}
        </p>
      </div>
    </div>
  </mat-dialog-content>

  <div fxFlex="row" fxLayoutAlign="center center" fxLayoutGap="15px">
    <button (click)="close()" *ngIf="cancelButton" class="mat-button-light"
            style="width: 300px !important;">{{ cancelButton }}</button>
    <button (click)="navTo()" *ngIf="submitButton" class="mat-button-dark"
            style="width: 300px !important;">{{ submitButton }}</button>
  </div>
</div>
