<div fxLayout="column" style="background-color: white">

  <app-page-header [section]="'Support'" [backgroundWhite]="true" [title]="'Contacting Customer Care for Support'" [description]="'Get in touch with the right person at Nter, we’re here to help.'"></app-page-header>

  <div class="page-padding" fxLayout="column">
    <mat-divider fxFlexOffset="70px"></mat-divider>

    <div class="support-item ps-4" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="supportEmail">
      <div fxLayout="column" fxLayoutGap="15px">
        <div class="subtitle-default">Get General Support from Landlord</div>
        <div class="body-1-regular-2">Get help with general matters. Send an email to this address to submit your request.</div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" style="width: 200px;">
        <div class="body-1-primary btn-cursor"
             matTooltip="Copy to clipboard"
             [cdkCopyToClipboard]="supportEmail">{{supportEmail}}</div>
      </div>
    </div>

    <mat-divider *ngIf="supportEmail"></mat-divider>

    <div class="support-item ps-4" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="accountsEmail">
      <div fxLayout="column" fxLayoutGap="15px">
        <div class="subtitle-default">Ask an Accounting Question</div>
        <div class="body-1-regular-2">Get professional feedback regarding your account, billing and subscriptions.</div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" style="width: 200px;">
        <div class="body-1-primary btn-cursor"
             matTooltip="Copy to clipboard"
             [cdkCopyToClipboard]="accountsEmail">{{accountsEmail}}</div>
      </div>
    </div>

    <mat-divider *ngIf="accountsEmail"></mat-divider>

    <div class="support-item ps-4" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="helpdeskUrl">
      <div fxLayout="column" fxLayoutGap="15px">
        <div class="subtitle-default">Private Helpdesk</div>
        <div class="body-1-regular-2">Ask questions, browse articles or contact an agent.</div>
      </div>
      <div fxLayout="column" fxLayoutGap="15px">
        <button class="button-outline button-width" mat-flat-button (click)="goToHelpDesk()">
          <div class="button-text">Visit the Help Desk</div>
        </button>
      </div>
    </div>

    <mat-divider *ngIf="helpdeskUrl"></mat-divider>

    <div class="support-item ps-4" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="posExpert">
      <div fxLayout="column" fxLayoutGap="15px">
        <div class="subtitle-default">Become a point-of-sale Expert</div>
        <div class="body-1-regular-2">Watch tutorials, browse articles or contact an agent to help your retailers fulfil sales and manage inventory.</div>
      </div>
      <div fxLayout="column" fxLayoutGap="15px">
        <button class="button-outline button-width" mat-flat-button (click)="goToHelpCentre()">
          <div class="button-text">Tour the Help Centre</div>
        </button>
      </div>
    </div>

    <mat-divider *ngIf="posExpert"></mat-divider>

    <div class="support-item ps-4" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="column" fxLayoutGap="15px">
        <div class="subtitle-default">Get Technical Support</div>
        <div class="body-1-regular-2">Get help with technical matters. Log a ticket on the helpdesk.</div>
      </div>
      <div fxLayout="column" fxLayoutGap="15px">
        <button class="button-outline button-width" mat-flat-button (click)="logSupportRequest()">
          <div class="button-text">Log a ticket</div>
        </button>
      </div>
    </div>

    <mat-divider></mat-divider>
  </div>

</div>
