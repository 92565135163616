import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDatepicker} from "@angular/material/datepicker";
import {MatDialog} from "@angular/material/dialog";
import {
  CustomDateRangeChangeEventModel
} from "../../../../shared/components/custom-date-filter/custom-date-range-change-event.model";
import {CustomDateRangeModel} from "../../../../shared/components/custom-date-filter/custom-date-range.model";
import {DateUtilsService} from "../../../../shared/services/dateUtils.service";
import {DashboardUtilsService} from "../../dashboard-utils.service";
import {DashboardChart} from "../../dashboards.model";
import {DashboardsInfoDialogComponent} from "../dashboards-info-dialog/dashboards-info-dialog.component";

@Component({
  selector: 'app-dashboards-chart-section',
  templateUrl: './dashboards-chart-section.component.html',
  styleUrls: ['./dashboards-chart-section.component.scss']
})
export class DashboardsChartSectionComponent implements OnInit {
  @Input() sectionName!: string;

  @Input() chart1!: DashboardChart<any>;
  @Input() chart2?: DashboardChart<any>;

  @Input() dateFilterType!: "picker" | "custom-range";

  @Input() customDateRangePresets?: CustomDateRangeModel[];
  @Input() initialPreset?: string;
  @Input() dateRangeIndex?: number;

  @Input() pickerType?: "month" | "year";
  @Input() date1: Date = new Date();
  @Input() date2: Date = new Date();

  @Output() dateRangeChanged = new EventEmitter<{event: CustomDateRangeChangeEventModel, index: number}>();

  @Output() datePickerClosed = new EventEmitter<void>();
  @Output() date1Change = new EventEmitter<Date>();
  @Output() date2Change = new EventEmitter<Date>();

  chartStats1: string[] = [];
  chartStats2?: string[] = [];

  constructor(public dashboardUtils: DashboardUtilsService, public dateUtils: DateUtilsService,
              private matDialog: MatDialog) {
  }

  ngOnInit() {
    this.chartStats1 = Object.keys(this.chart1.stats);
    if (this.chart2) this.chartStats2 = Object.keys(this.chart2.stats);
  }

  getDateRange(event: CustomDateRangeChangeEventModel) {
    this.dateRangeChanged!.emit({event, index: this.dateRangeIndex!});
  }

  closeDatePicker(event: any, picker: MatDatepicker<any>, pickerIndex: number) {
    if (pickerIndex === 1) {
      this.date1 = event;
      this.date1Change.emit(this.date1);
    }
    if (pickerIndex === 2) {
      this.date2 = event;
      this.date2Change.emit(this.date2);
    }
    picker.close();
    this.datePickerClosed!.emit();
  }

  get getPickerStartView(): "multi-year" | "year" | "month" {
    if (this.pickerType == "year") return "multi-year";
    if (this.pickerType == "month") return "year"
    return "month";
  }

  selectYear(event: any, picker: MatDatepicker<any>, pickerIndex: number) {
    if (this.pickerType == "year") this.closeDatePicker(event, picker, pickerIndex);
  }

  selectMonth(event: any, picker: MatDatepicker<any>, pickerIndex: number) {
    if (this.pickerType == "month") this.closeDatePicker(event, picker, pickerIndex);
  }

  pickerDisplayValue(date: Date): string {
    if (this.pickerType == "year") return this.dateUtils.displayYear(date!)!;
    if (this.pickerType == "month") return this.dateUtils.displayMonthAndYear(date!)!;
    return "";
  }

  openInfoDialog() {
    this.matDialog.open(DashboardsInfoDialogComponent, {
      panelClass: 'dialog-large',
      height: '450px',
      width: '600px',
      backdropClass: 'dialog-backdrop-opaque',
    })
  }

}
