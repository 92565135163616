import {Component, Input, OnInit} from '@angular/core';
import {UtilsService} from "../../../../shared/services/utils.service";
import {PaymentConfigData, PaymentMethod} from "../../leasing.model";
import {LeasingService} from "../../leasing.service";

@Component({
  selector: 'app-lease-rates-and-charges',
  templateUrl: './lease-rates-and-charges.component.html',
  styleUrls: ['./lease-rates-and-charges.component.scss']
})
export class LeaseRatesAndChargesComponent implements OnInit {
  @Input() id!: string;

  transactionSource!: PaymentConfigData;
  transactionSourceOld!: PaymentConfigData;
  paymentMethods: PaymentMethod[] = [];

  offeringUuid: string = '549B1AAA-809B-EC11-A22A-501AC55C0B2D'

  constructor(public utils: UtilsService, private leasingService: LeasingService) {
  }

  ngOnInit() {
    this.loadLeaseTransactionSource();
    this.loadPaymentMethods();
  }

  loadLeaseTransactionSource() {
    this.leasingService.getCurrentLeasePaymentConfig().subscribe({
      next: value => this.transactionSource =  {...value},
      error: err => console.log(err)
    })
  }

  loadPaymentMethods() {
    this.leasingService.getPaymentMethods().subscribe({
      next: value => {
        this.paymentMethods = value;
      },
      error: err => console.log(err)
    })
  }

  displayFeeType(type: string): string {
    if (type === 'RATE') return 'Turnover percentage';
    if (type === 'FIXED') return 'Fixed Rate';
    return '-';
  }

  displayRentalType(type: string): string {
    if (type === 'TURNOVER_PERCENTAGE') return 'Rental percentage';
    if (type === 'PRICING_MODEL') return 'Fixed Rental';
    return '-';
  }

}
