import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {CurrentContextService} from "../../../core/services/security/current-context.service";
import {TurnoverCaptureData, TurnoverCaptureMonth} from "./turnover-capture.model";
import {GenericChart} from "../../../shared/model/generic-chart.interface";

@Injectable({
  providedIn: 'root'
})
export class TurnoverCaptureService {

  constructor(private http: HttpClient, private currentContext: CurrentContextService) {}

  public getTurnoverOverCaptureForm() {
    return this.http.get<TurnoverCaptureMonth[]>(this.baseUrl);
  }

  public getTurnoverOverCaptureChart() {
    return this.http.get<GenericChart>(this.baseUrl + `/chart`);
  }

  public saveTurnoverFormItems(formItems: TurnoverCaptureData[]) {
    return this.http.post<TurnoverCaptureMonth[]>(this.baseUrl, formItems);
  }

  get baseUrl() {
    const retailerId = this.currentContext.currentRetailer.id;
    return environment.apiTransactHost + `/api/offering/district/retailer/${retailerId}/forms/turnover`
  }
}
