import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-application-form-row',
  templateUrl: './application-form-row.component.html',
  styleUrls: ['./application-form-row.component.scss']
})
export class ApplicationFormRowComponent {
  @Input() fieldLabel!: string;
  @Input() showError?: boolean = false;
  @Input() errorMessage?: string = "Not a valid value";
  @Input() lastRow?: boolean = false;
}
