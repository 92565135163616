import {Injectable} from "@angular/core";
import {Moment} from "moment/moment";
import * as moment from "moment/moment";

@Injectable({
  providedIn: 'root'
})
export class DateUtilsService {
  displayShortDate(date: Date | string | null): string | null {
    if (date) return moment(date).format("YYYY-MM-DD");
    return null;
  }

  displayLongDate(date: Date | string | null): string | null {
    if (date) return moment(date).format("DD MMMM YYYY");
    return null;
  }

  displayMonthAndYear(date: Date | string | null): string | null {
    if (date) return moment(date).format("MMM YYYY");
    return null;
  }

  displayDateAndMonth(date: Date | string | null): string | null {
    if (date) return moment(date).format("DD MMM");
    return null;
  }

  displayDateWithTime(date: Date | string | null): string | null {
    if (date) return moment(date).format("YYYY-MM-DD (hh:mm)");
    return null;
  }

  displayDateWithFormat(date: Date | string | null, pattern: string) {
    if (date) return moment(date).format(pattern);
    return null;
  }

  displayYear(date: Date | string | number | null | Moment): string | null {
    if (date) return moment(date).format("YYYY");
    return null;
  }

  displayDateDuration(date1: string | Date, date2: string | Date) {
    const start = moment(date1);
    const end = moment(date2);

    const months = end.diff(start, 'months');
    const days = end.diff(start, 'days');

    return months > 0 ? `${months} month${months > 1 ? 's' : ''}` : `${days} day${days > 1 ? 's' : ''}`;
  }

  displayDateDurationDays(date: string | Date) {
    const days = moment(date).diff(moment(), 'days');
    return days > 0 ? moment(date).diff(moment(), 'days') + ' days' : '0 days';
  }

  displayFullDayName(abbreviatedDay: string) {
    const fullDayNames = moment.weekdays();
    const shortDayNames = moment.weekdaysShort();
    const dayIndex = shortDayNames.indexOf(abbreviatedDay);
    return dayIndex !== -1 ? fullDayNames[dayIndex] : abbreviatedDay;
  }

  isBefore(date1: string | Date, date2: string | Date): boolean {
    return moment(date1).isBefore(date2);
  }

  get currentDate() {
    return moment().toDate();
  }
}
