import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';

import {CurrentContextService} from './current-context.service';
import {BehaviorSubject, lastValueFrom, Observable} from 'rxjs';
import {Router} from '@angular/router';
import {SessionKey} from './session-key.enum';
import {BaseService} from '../base.service';
import {environment} from '../../../../environments/environment';
import {UserService} from '../user.service';
import {User} from '../../model/user.model';
import {Retailer} from '../../model/retailer.model';
import {RetailerService} from '../retailer.service';
import {OfferingApplicationService} from '../offering-application.service';
import {AuthTokenInterface} from '../../interfaces/auth-token.interface';
import {JwtHelperService} from '../../../shared/services/jwt-helper.service';
import {LoginSteps, PortalUserAccessMap} from '../../model/user-access.model';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends BaseService {

  private loggedIn = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private contextService: CurrentContextService,
    private userService: UserService,
    private router: Router,
    private retailerService: RetailerService,
    private curateService: OfferingApplicationService,
    private jwtHelper: JwtHelperService
  ) {
    super();
  }

  get isLoggedIn(): Observable<boolean> {
    this.loggedIn.next(this.getToken() !== null && (this.getExpiration() > new Date()));
    return this.loggedIn.asObservable();
  }

  get isVerified(): boolean {
    if (this.contextService.currentUser) {
      return this.contextService.currentUser.verified;
    }
    return false;
  }

  public setToken(res: HttpResponse<Object>): void {
    const token = res.headers.get(SessionKey.TOKEN_KEY) ? res.headers.get(SessionKey.TOKEN_KEY) : '';
    sessionStorage.setItem(SessionKey.TOKEN_KEY, token!);
    let tokenAsInterface: AuthTokenInterface = this.getTokenAsInterface();
    sessionStorage.setItem(SessionKey.TOKEN_EXPIRY_KEY,
      this.jwtHelper.getDateFromTokenExp(tokenAsInterface.exp).toString()
    );

    this.loggedIn.next(true);
  }

  // public setTempToken(res: HttpResponse<Object>): void {
  //   const token = res.headers.get(SessionKey.TEMP_TOKEN_KEY) ? res.headers.get(SessionKey.TEMP_TOKEN_KEY) : '';
  //   sessionStorage.setItem(SessionKey.TOKEN_KEY, token!);
  //   let tokenAsInterface: AuthTokenInterface = this.getTokenAsInterface();
  //   sessionStorage.setItem(SessionKey.TOKEN_EXPIRY_KEY,
  //     this.jwtHelper.getDateFromTokenExp(tokenAsInterface.exp).toString()
  //   );
  //
  //   this.loggedIn.next(true);
  // }

  public doLogin(request: { email: string, secret: string }): Promise<boolean> {
    return this.login(request)
      .then((loginResponse: HttpResponse<object>) => {
        this.setToken(loginResponse);
        let userId = parseInt(<string>loginResponse.headers?.get('user-id'));
        this.contextService.setKey(SessionKey.CURRENT_USER_ID, userId.toString());
        return this.lookupUser(userId)
          .then(() => {
            return Promise.resolve(true);
          })
          .then((userFound: boolean) => {
            if (userFound) {
              return this.loadUserGrants();
            }
            return Promise.resolve(false);
          })
          .then((grantsLoaded: boolean) => {
            if (grantsLoaded) {
              return this.loadRetailerForUser();
            }
            return Promise.resolve(false);
          });
      });
  }

  public doAppFormLogin(request: { email: string, secret: string }): Promise<LoginSteps> {
    let steps: LoginSteps = {
      isUserSet: false,
      isUserFound: false,
      isTokenSet: false,
      isRetailerSet: false
    }

    return this.login(request)
      .then((loginResponse: HttpResponse<object>) => {
        this.setToken(loginResponse);
        let userId = parseInt(<string>loginResponse.headers?.get('user-id'));
        this.contextService.setKey(SessionKey.CURRENT_USER_ID, userId.toString());
        steps.isTokenSet = true;
        return this.lookupUser(userId)
          .then((user: User) => {
            if (user) {
              steps.isUserFound = true;
              steps.isUserSet = true;
            }
            return Promise.resolve(steps);
          })
          .catch((error: HttpErrorResponse) => {
            console.log("Error looking up user", error);
            steps.isUserFound = false;
            return Promise.resolve(steps);
          })
          .then((currentSteps: LoginSteps) => {
            if (currentSteps.isUserSet && currentSteps.isUserFound) {
              return this.loadRetailerForUser(false)
                .then((isRetailerSet: boolean) => {
                  if (isRetailerSet) {
                    steps.isRetailerSet = true;
                  }
                  return Promise.resolve(steps);
                })
                .catch((error: HttpErrorResponse) => {
                  console.log("Error loading retailer", error);
                  steps.isRetailerSet = false;
                  return Promise.resolve(steps);
                });
            }
            return Promise.resolve(steps);
          })
      });

  }


  public logout(changeRoute: boolean = true): void {
    const uri = environment.apiSecurityHost + '/logout';
    lastValueFrom(this.http.post(uri, null))
      .catch((error: string) => {
        console.log(error);
      })
      .finally(() => {
        this.clearContextForLogout(changeRoute);
      });

  }

  private clearContextForLogout(changeRoute: boolean): void {
    let currentLocationCode: any = this.contextService.getCurrentLocationCode();

    sessionStorage.clear();
    this.contextService.reset();
    this.loggedIn.next(false);

    if (changeRoute) {
      this.router.navigate([`${currentLocationCode}/login`])
        .catch((error: string) => {
          console.error(error);
        });
    }
  }

  public getExpiration(): Date {
    const expiration = sessionStorage.getItem(SessionKey.TOKEN_EXPIRY_KEY);
    return new Date(expiration!);
  }

  public getToken(): string {
    return sessionStorage.getItem(SessionKey.TOKEN_KEY)!;
  }

  public getTokenAsInterface(): AuthTokenInterface {
    return this.jwtHelper.decodeToken(sessionStorage.getItem(SessionKey.TOKEN_KEY)!) as AuthTokenInterface;
  }

  private login(request: { email: string, secret: string }): Promise<HttpResponse<Object>> {
    const uri = environment.apiSecurityHost + '/login';
    return lastValueFrom(this.http.post(uri, request, {observe: 'response'}));
  }

  private lookupUser(userId: number): Promise<User> {
    return lastValueFrom(this.userService.getUserById(userId))
      .then((response: User) => {
        this.contextService.setCurrentUserInContext(response);
        return Promise.resolve(response);
      });
  }

  private loadRetailerForUser(switchRoute: boolean = true): Promise<boolean> {
    return lastValueFrom(this.retailerService.getRetailer())
      .then((res: Retailer) => {
        if (switchRoute && (res === null || res === undefined)) {
            this.router.navigate([`${this.contextService.getCurrentLandlordLocation()?.locationCode}/retailer/brand-info`])
              .catch((error: string) => {
                console.log(error);
              });
          return Promise.resolve(false);
        } else if (res === null || res === undefined) {
            return Promise.resolve(false);
          }
          this.contextService.setCurrentRetailerInContext(res);
          return Promise.resolve(true);
        }
      )
      .catch((error: string) => {
        // if there is an error loading the retailer clear the offering incase we need to go to brand information
        this.contextService.sendClearCurrentOfferingEvent();
        console.error(error);
        return Promise.resolve(false);
      });
  }

  verifyUser(): void {
    let currentUser: User = this.contextService.currentUser;
    currentUser.verified = true;
    this.contextService.setCurrentUserInContext(currentUser);
  }

  private loadUserGrants(): Promise<Awaited<boolean>> {
    return lastValueFrom(this.http.get<PortalUserAccessMap>(`${environment.apiSecurityHost}/portal/rbac/user/features`))
      .then((map: PortalUserAccessMap) => {
        this.contextService.setCurrentUserGrants(map);
        return Promise.resolve(true);
      });
  }


}
