<div class="p-3 full-width full-screen-height flex-column" fxLayoutAlign="center center" fxLayoutGap="15px">
  <div class="full-width reset-password-header-div" fxLayout="column" fxLayoutGap="20px"  fxLayoutAlign="center center">
    <h1 class="title-large">Forgot your Password?</h1>
    <h2 class="subtitle-regular">Please enter your registered email address to reset your password.</h2>
  </div>

  <div class="p-2 full-width flex-row" fxLayoutAlign="center center">
    <form (ngSubmit)="onSubmit()" [formGroup]="forgotPasswordForm" class="pre-auth-form" fxLayout="column" fxLayoutAlign="center center">
      <mat-form-field appearance="outline" class="custom-text-field" floatLabel="always" hideRequiredMarker>
        <mat-label>Email</mat-label>
        <input formControlName="email"
               matInput
               pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$"
               placeholder="Email address"
               type="email"/>
        <mat-error class="link-error" *ngIf="forgotPasswordForm.get('email')!.hasError('required')">Email is required</mat-error>
        <mat-error class="link-error" *ngIf="forgotPasswordForm.get('email')!.hasError('pattern')">Email is not valid</mat-error>
      </mat-form-field>

      <button class="button-primary" mat-button type="submit" fxFlexOffset="90px" [disabled]="loader.loading$ | async">
        <div class="button-text">Reset Password</div>
      </button>

    </form>
  </div>

  <div (click)="goToLoginPage()" class="heading-regular btn-cursor" style="text-align: center">Already have a password? Log in</div>
  <app-footer-links [showCopyrightOnly]="true"></app-footer-links>
</div>
