<div class="question-card full-width ps-0" fxLayout="column" fxLayoutGap="10px">
    <div class="full-width" fxLayout="row" fxLayoutAlign="start start">
        <div class="question-card-header" fxLayout="column" fxLayoutAlign="start start" style="width: 24px">
            <mat-icon class="more-icon">more_vert</mat-icon>
        </div>
        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px" fxLayoutGap.gt-sm="15px" fxFlexOffset="20px"
            class="full-width">
            <div class="pt-1 heading-bold">
                {{fieldLabel}}
            </div>
            <div class="custom-text-field-full-width form-input-field form-field-padding-wrapper custom-select-field-full-width" matFormField
                appearance="outline" hideRequiredMarker color="primary">
                <div matInput class="form-input">
                    <ng-content></ng-content>
                </div>
                <mat-error class="link-error" *ngIf="showError">{{ errorMessage }}</mat-error>
            </div>
        </div>
    </div>
    <div *ngIf="!lastRow" class="full-width" fxLayout="column">
        <mat-divider class="full-width" fxFlexOffset="30px"></mat-divider>
    </div>
</div>
