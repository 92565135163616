import {MatTableDataSource} from "@angular/material/table";
import {ChartConfiguration} from "chart.js";
import {GenericChart} from "../../../shared/model/generic-chart.interface";
import {DateUtilsService} from "../../../shared/services/dateUtils.service";
import {chartColors} from "../chart-options/chart-colors.model";
import {defaultChartOptions} from "../chart-options/chart-options.model";
import {ChartOptionsService} from "../chart-options/chart-options.service";
import {ChartConfig, ChartDataConfig} from "../common/chart-config";
import {RetailerProductPerformance} from "../dashboards.model";
import {DashboardsRetailerReportComponent} from "./dashboards-retailer-report.component";

export class DashboardsRetailerReportChartConfig extends ChartConfig<DashboardsRetailerReportComponent> {
  constructor(componentInstance: DashboardsRetailerReportComponent,
              private chartOptionsService: ChartOptionsService,
              private dateUtils: DateUtilsService) {
    super(componentInstance);
  }

  get bestMonthlyStats(): ChartDataConfig {
    return {
      options: null,
      next: value => {
        this.instance.bestStats = value;
      },
      error: err => console.log(err)
    };
  }

  get salesPerformance(): ChartDataConfig {
    let chartOptions: ChartConfiguration["options"] = JSON.parse(JSON.stringify(defaultChartOptions))!;
    this.chartOptionsService.setCurrencyAxis(chartOptions!, 'y')!;

    this.chartOptionsService.displaySecondaryYAxis(chartOptions!);
    this.chartOptionsService.setCurrencyPerSqmAxis(chartOptions!, 'y1')!;

    this.chartOptionsService.setTooltips(chartOptions!, ['Trading Density'], ['Sales'], [])!;
    this.chartOptionsService.setLegendLabels(chartOptions!);

    return {
      options: chartOptions,
      next: (value: GenericChart) => {
        const salesData = value.chartSeries[0];
        const tdData = value.chartSeries[1];

        this.instance.salesPerformanceChart.stats.totalSales.statValue = Object(value.params)['totalSales'];
        this.instance.salesPerformanceChart.stats.tradingDensity.statValue = Object(value.params)['tradingDensity'];
        this.chartOptionsService.chartDateRange(this.instance.salesPerformanceChart, value.params);

        this.instance.salesPerformanceChart.chartData = {
          labels: salesData.chartData.map((m: any) => m.label.split(' ')),
          datasets: [
            {
              label: salesData.name,
              data: salesData.chartData.map((m: any) => m.data),
              order: 1,
              yAxisID: 'y',
              ...this.chartOptionsService.barChart(chartColors.BLUE)
            },
            {
              label: tdData.name,
              data: tdData.chartData.map((m: any) => m.data),
              order: 0,
              yAxisID: 'y1',
              ...this.chartOptionsService.lineChart(chartColors.BRIGHT_PINK)
            },
          ]
        }
        this.instance.salesPerformanceChart.loading = false;
      },
      error: err => {
        console.log(err);
        this.instance.salesPerformanceChart.loading = false;
      }
    };
  }

  get rentPerformance(): ChartDataConfig {
    let chartOptions: ChartConfiguration<'bar'>["options"] = JSON.parse(JSON.stringify(defaultChartOptions))!;
    this.chartOptionsService.setStackedAxis(chartOptions!, 'y');
    this.chartOptionsService.setCurrencyPerSqmAxis(chartOptions!, 'y')!;

    this.chartOptionsService.displaySecondaryYAxis(chartOptions!);
    this.chartOptionsService.setPercentageAxis(chartOptions!, 'y1', false)!;

    this.chartOptionsService.setTooltips(chartOptions!, ['Rental Rate'], [], ['Rent Ratio'])!;
    this.chartOptionsService.setLegendLabels(chartOptions!);

    return {
      options: chartOptions,
      next: value => {
        const rentalRate = value.chartSeries[0];
        const rentRatio = value.chartSeries[1];

        this.instance.rentPerformanceChart.stats.rentalRate.statValue = Object(value.params)['rentalRate'];
        this.instance.rentPerformanceChart.stats.rentRatio.statValue = Object(value.params)['rentRatio'];
        this.chartOptionsService.chartDateRange(this.instance.rentPerformanceChart, value.params);

        this.instance.rentPerformanceChart.chartData = {
          labels: rentalRate.chartData.map((m: any) => m.label.split(' ')),
          datasets: [
            {
              label: rentalRate.name,
              data: rentalRate.chartData.map((m: any) => m.data),
              order: 1,
              yAxisID: 'y',
              ...this.chartOptionsService.barChart(chartColors.YELLOW)
            },
            {
              label: rentRatio.name,
              data: rentRatio.chartData.map((m: any) => m.data),
              order: 0,
              yAxisID: 'y1',
              ...this.chartOptionsService.barChart(chartColors.GREEN_75)
            },
          ]
        }
        this.instance.rentPerformanceChart.loading = false;
      },
      error: err => {
        console.log(err);
        this.instance.rentPerformanceChart.loading = false;
      }
    };
  }

  get avgBasketSize(): ChartDataConfig {
    let chartOptions: ChartConfiguration["options"] = JSON.parse(JSON.stringify(defaultChartOptions))!;
    this.chartOptionsService.setCurrencyAxis(chartOptions!, 'y')!;
    this.chartOptionsService.setTooltips(chartOptions!, [], ['Average basket size'], [])!;
    this.chartOptionsService.setLegendLabels(chartOptions!);

    return {
      options: chartOptions,
      next: value => {
        const avgBasketSize = value.chartSeries[0];

        this.instance.avgBasketSizeChart.chartData = {
          labels: avgBasketSize.chartData.map((m: any) => m.label.split(' ')),
          datasets: [
            {
              label: avgBasketSize.name,
              data: avgBasketSize.chartData.map((m: any) => m.data),
              ...this.chartOptionsService.areaChart(chartColors.MAROON, chartColors.MAROON)
            }
          ]
        }
        this.instance.avgBasketSizeChart.loading = false;
      },
      error: err => {
        console.log(err);
        this.instance.avgBasketSizeChart.loading = false;
      }
    };
  }

  get busiestSalesTimes(): ChartDataConfig {
    let chartOptions: ChartConfiguration["options"] = JSON.parse(JSON.stringify(defaultChartOptions))!;
    this.chartOptionsService.setCurrencyAxis(chartOptions!, 'y')!;
    this.chartOptionsService.setTimeTooltips(chartOptions!, [], ['Hourly Sales'], [])!;
    this.chartOptionsService.setLegendLabels(chartOptions!);

    return {
      options: chartOptions,
      next: value => {
        const sales = value.chartSeries[0];

        this.instance.busiestSalesTimesChart.stats.salesPerHour.statValue = Object(value.params)['salesPerHour'];
        this.chartOptionsService.chartDateRange(this.instance.busiestSalesTimesChart, value.params);

        this.instance.busiestSalesTimesChart.chartData = {
          labels: sales.chartData.map((m: any) => m.intLabel),
          datasets: [
            {
              label: sales.name,
              data: sales.chartData.map((m: any) => m.data),
              ...this.chartOptionsService.barChart(chartColors.LIGHT_PURPLE)
            }
          ]
        }
        this.instance.busiestSalesTimesChart.loading = false;
      },
      error: err => {
        console.log(err);
        this.instance.busiestSalesTimesChart.loading = false;
      }
    };
  }

  get busiestSalesDays(): ChartDataConfig {
    let chartOptions: ChartConfiguration["options"] = JSON.parse(JSON.stringify(defaultChartOptions))!;
    this.chartOptionsService.setCurrencyAxis(chartOptions!, 'y')!;
    this.chartOptionsService.setDayTooltips(chartOptions!, [], ['Daily Sales'], [])!;
    this.chartOptionsService.setLegendLabels(chartOptions!);

    return {
      options: chartOptions,
      next: value => {
        const sales = value.chartSeries[0];

        this.instance.busiestSalesDaysChart.stats.salesPerDay.statValue = Object(value.params)['salesPerDay'];
        this.chartOptionsService.chartDateRange(this.instance.busiestSalesDaysChart, value.params);

        this.instance.busiestSalesDaysChart.chartData = {
          labels: sales.chartData.map((m: any) => m.label),
          datasets: [
            {
              label: sales.name,
              data: sales.chartData.map((m: any) => m.data),
              ...this.chartOptionsService.barChart(chartColors.PASTEL_PINK)
            }
          ]
        }
        this.instance.busiestSalesDaysChart.loading = false;
      },
      error: err => {
        console.log(err);
        this.instance.busiestSalesDaysChart.loading = false;
      }
    };
  }

  get bestSellersList(): ChartDataConfig {
    return {
      options: null,
      next: value => {
        this.instance.bestSellersDatasource = new MatTableDataSource<RetailerProductPerformance>(value);
        this.instance.bestSellersDatasource.paginator = this.instance.bestSellersPaginator;
        this.instance.bestSellersLoading = false;
      },
      error: err => {
        console.log(err);
        this.instance.bestSellersLoading = false;
      }
    };

  }

  get inventoryForecastList(): ChartDataConfig {
    return {
      options: null,
      next: value => {
        this.instance.inventoryForecastDatasource = new MatTableDataSource<RetailerProductPerformance>(value);
        this.instance.inventoryForecastDatasource.paginator = this.instance.forecastPaginator;
        this.instance.inventoryForecastLoading = false;
      },
      error: err => {
        console.log(err);
        this.instance.inventoryForecastLoading = false;
      }
    };

  }

}
