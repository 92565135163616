import {Injectable} from '@angular/core';

// @ts-ignore
declare var gtag;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  constructor() {
  }

  public customEventEmitter(
    eventCategory: string,
    eventAction: string,
    eventLabel: string,
    eventValue?: number): void {

    gtag('event', eventAction, {
      event_category: eventCategory,
      event_action: eventAction,
      event_label: eventLabel,
      value: eventValue ? eventValue : null,
    });
  }

  public googleEventEmitter(eventName: string): void {
    gtag('event', eventName);
  }
}
