<div class="page-content page-padding" fxLayout="column">
  <div fxFlexOffset="20px">
    <div fxLayout="row">
      <span class="subtitle-default">Transaction Schedule</span>
    </div>
  </div>

  <div class="table-card mat-elevation-z3" fxLayout="column" fxFlexOffset="30px">
    <span class="heading-default">All Transactions</span>
    <form [formGroup]="filterForm" fxFlexOffset="20px">
      <div fxLayout="row" fxLayoutAlign="space-between center">

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="50px">
          <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always">
            <mat-label>Point-of-sale Status</mat-label>
            <mat-select class="custom-select-field-value" placeholder="Select" multiple formControlName="posStatus">
              <mat-option class="custom-select-field-option"  *ngFor="let status of saleStatuses" [value]="status">{{utils.displayStatus(status)}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always" *ngIf="transactionStatuses">
            <mat-label>Pay-out Status</mat-label>
            <mat-select class="custom-select-field-value" placeholder="Select" multiple formControlName="processingStatus">
              <mat-option class="custom-select-field-option"  *ngFor="let status of transactionStatuses" [value]="status.val">{{status.label}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <app-custom-date-filter fxFlexAlign="end" [customDateRangePresets]="dateFilters"
                                [showBorder]="false"
                                (dateRange)="getDateRange($event)"
                                [initialPreset]="'1'"
                                [showClearButton]="true">

        </app-custom-date-filter>

      </div>
    </form>

    <table mat-table class="custom-table" [dataSource]="dataSource" fxFlexOffset="25px" fxFlex="60">
      <ng-container matColumnDef="table-label">
        <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="displayedColumns.length"> Transactions </th>
      </ng-container>

      <ng-container matColumnDef="transactionInvoiceNumber">
        <th mat-header-cell *matHeaderCellDef> Transaction ID </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{ utils.displayString(element.transactionInvoiceNumber, 12) }} </td>
      </ng-container>

      <ng-container matColumnDef="transactionDate">
        <th mat-header-cell *matHeaderCellDef> Sales Date </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{dateUtils.displayShortDate(element.transactionDate)}} </td>
      </ng-container>

      <ng-container matColumnDef="saleAmountIncl">
        <th mat-header-cell *matHeaderCellDef> Total Sales </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayRandAmount(element.saleAmountIncl)}} </td>
      </ng-container>

      <ng-container matColumnDef="transactionFeeIncl">
        <th mat-header-cell *matHeaderCellDef> Transaction Fee </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{utils.displayRandAmount(element.transactionFeeIncl)}} </td>
      </ng-container>

      <ng-container matColumnDef="turnoverRentalFeeIncl">
        <th mat-header-cell *matHeaderCellDef> Rental Fee </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{utils.displayRandAmount(element.turnoverRentalFeeIncl)}} </td>
      </ng-container>

      <ng-container matColumnDef="netBalancePayableRetailerInclRounded">
        <th mat-header-cell *matHeaderCellDef> Net Balance </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{utils.displayRandAmount(element.netBalancePayableRetailerInclRounded)}} </td>
      </ng-container>

      <ng-container matColumnDef="posStatus">
        <th mat-header-cell *matHeaderCellDef> Point-of-sale Status </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{utils.displayStatus(element.pointOfSaleStatus)}} </td>
      </ng-container>

      <ng-container matColumnDef="processingStatus">
        <th mat-header-cell *matHeaderCellDef> Pay-out Status </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{utils.displayStatus(element.processingStatus)}} </td>
      </ng-container>

      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator #paginator class="custom-paginator"
                   [length]="dataSource.totalElements"
                   [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
</div>
