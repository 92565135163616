import {Component, OnInit, ViewChild} from '@angular/core';
import {DateUtilsService} from '../../../shared/services/dateUtils.service';
import {OfferingApplicationFormComponent} from '../../applications/application-form/offering-application-form/offering-application-form.component';
import {LoadingService} from "../../../core/services/loading.service";
import {OfferingApplicationService} from "../../../core/services/offering-application.service";
import {LocationOffering} from "../../../core/model/location-offering.interface";
import {CurrentContextService} from "../../../core/services/security/current-context.service";

@Component({
  selector: 'app-forms',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss']
})
export class ApplicationsComponent implements OnInit{
  @ViewChild("offeringApplicationForm") offeringApplicationForm!: OfferingApplicationFormComponent;

  lastUpdated!: Date;
  lastUpdatedMessage: string | null = null;

  offering!: LocationOffering;

  constructor(private dateUtils: DateUtilsService, public loader: LoadingService, public offeringApplicationService: OfferingApplicationService, private contextService: CurrentContextService) {
    this.offeringApplicationService.toggleFormReadOnly(true);
  }

  toggleApplicationReadOnly(readonly: boolean): void {
    this.offeringApplicationService.toggleFormReadOnly(readonly);
    this.offeringApplicationForm.loadRetailerApplication();
  }

  ngOnInit(){
    this.offering = this.contextService.getCurrentOffering()!;
  }

  changeLastUpdated($event: Date): void {
    this.lastUpdated = $event;
    this.lastUpdatedMessage = (this.lastUpdated !== null && this.lastUpdated !== undefined) ?
      `Last updated on ${this.dateUtils.displayShortDate(this.lastUpdated)}. ` : '';
  }

  triggerSubmit(): void {
    this.offeringApplicationForm.submitRetailerOfferingApplication()
      .then(submitted => this.offeringApplicationService.toggleFormReadOnly(submitted));
  }

  canSubmit(): boolean {
    return this.offeringApplicationForm.checkFormValidity(true);
  }

}
