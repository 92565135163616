import {Component, EventEmitter, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {LoadingService} from "../../../../core/services/loading.service";

@Component({
  selector: 'app-application-form-login',
  templateUrl: './application-form-login.component.html',
  styleUrls: ['./application-form-login.component.scss']
})
export class ApplicationFormLoginComponent {
  @Output() signup = new EventEmitter<void>();
  @Output() submitError = new EventEmitter<string>();

  loginForm: FormGroup;
  loading: boolean = false;
  hidePassword = true;


  constructor(private domSanitizer: DomSanitizer,
              private matIconRegistry: MatIconRegistry,
              public loader: LoadingService) {
    this.matIconRegistry.addSvgIcon('icon-show-password',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/actions/icon-show-password.svg'));
    this.matIconRegistry.addSvgIcon('icon-hide-password',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/actions/icon-hide-password.svg'));

    this.loginForm = new FormGroup({
      email: new FormControl({value: '', disabled: this.loading}, {
        validators: [Validators.required, Validators.email],
      }),
      password: new FormControl({value: '', disabled: this.loading}, {validators: [Validators.required]}),
    });

  }

  get formValid(): boolean {
    return this.loginForm.valid;
  }

  gotoSignUp(): void {
    this.signup.emit();
  }

  public hasError(formControlName: string, errorName?: string): boolean {
    const control = this.loginForm.get(formControlName);
    return control!.touched && (errorName ? control!.hasError(errorName) : control!.invalid);
  }
}
