<div fxLayout="column">

  <app-page-header [banner]="false" [section]="'Transaction schedule'" [title]="'Keep an eye on transactions'" [description]="'The transaction module allows you to see all transactions for your retail offering. Everything happens automatically, your work here is done!'"></app-page-header>

  <app-transaction-summary></app-transaction-summary>

  <div class="section-divider">
    <mat-divider></mat-divider>
  </div>

  <app-transaction-schedule></app-transaction-schedule>

</div>
