<form class="full-width info-card-grey form-info-card px-0" [formGroup]="retailerReigstrationForm"
    fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px">

  <mat-error *ngIf="errorMsg" class="body-1-error form-error mb-0 mt-3">{{ errorMsg }}</mat-error>
  <mat-error class="body-1-error mb-0 mt-3" *ngIf="retailerReigstrationForm.get('companyName')!.hasError('name-exists')">A retailer with this name or website already exists</mat-error>

  <app-application-form-row fieldLabel="Company name" errorMessage="Company name is required" fxFlexOffset="30px"
        [showError]="hasError('companyName')">
        <input matInput formControlName="companyName" placeholder="Enter your answer" required="true">
    </app-application-form-row>

    <app-application-form-row fieldLabel="Industry" errorMessage="Industry name is required"
        [showError]="hasError('industries')">
        <mat-select [(value)]="selectedIndustries" class="custom-select-field-value" formControlName="industries"
            multiple placeholder="Select">
            <mat-optgroup *ngFor="let group of industryList" class="btn-cursor">
                <mat-label (click)="groupClicked(group)" class="custom-select-field-section" fxLayout="row"
                    fxLayoutAlign="start center" fxLayoutGap="5px">
                    <span>{{group.name}}</span>
                    <mat-icon class="btn-cursor">add</mat-icon>
                </mat-label>
                <mat-option (click)="optionClicked(group, ind)" *ngFor="let ind of group.industries" [value]="ind.code"
                    class="custom-select-field-option">{{ ind.name }}</mat-option>
            </mat-optgroup>
        </mat-select>
    </app-application-form-row>

    <app-application-form-row fieldLabel="Website" errorMessage="Website is required"
        [showError]="hasError('websiteUrl')">
        <input formControlName="websiteUrl" matInput placeholder="Input text" />
        <mat-error class="link-error" *ngIf="hasError('websiteUrl', 'websiteUrlInvalid')">Website is not a valid url</mat-error>
    </app-application-form-row>

    <app-application-form-row fieldLabel="Social Media Link" errorMessage="Social Media link is required"
        [showError]="hasError('socialMediaUrl')">
        <input formControlName="socialMediaUrl" id="socialMediaUrl" placeholder="Input text" matInput />
        <mat-error class="link-error" *ngIf="hasError('socialMediaUrl', 'socialMediaUrlInvalid')">Social Media link is not valid</mat-error>
    </app-application-form-row>

    <app-application-form-row fieldLabel="Mobile" errorMessage="Phone number is required" [lastRow]="true"
        [showError]="hasError('mobile')">
        <input [pattern]="mobNumberPattern" formControlName="mobile" matInput type="text" placeholder="Input text">
        <mat-error class="link-error" *ngIf="hasError('mobile', 'pattern')">Mobile number not valid, please enter 9 digits</mat-error>
    </app-application-form-row>

</form>
