import {Component, OnInit} from '@angular/core';
import {LeaseSummary} from "../leasing.model";
import {LeasingService} from "../leasing.service";

@Component({
  selector: 'app-lease-view',
  templateUrl: './current-lease.component.html',
  styleUrls: ['./current-lease.component.scss']
})
export class CurrentLeaseComponent implements OnInit {
  leaseSummary!: LeaseSummary;

  constructor(private leasingService: LeasingService) {
  }

  ngOnInit() {
    this.loadActiveLease();
  }

  loadActiveLease() {
    this.leasingService.getCurrentLease().subscribe({
      next: value => this.leaseSummary = value,
      error: err => console.log(err)
    })
  }

}
