import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {GenericService} from '../../shared/services/generic.service';
import {GrantedRole, GrantForPortalRequest, RBACModule, RBACRole, RevokeGrant} from '../model/user-access.model';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserAccessService extends GenericService<GrantedRole> {

  constructor(http: HttpClient) {
    super(http, environment.apiSecurityHost + `/portal/rbac/retailer`);
  }

  saveAccessRequest(request: GrantForPortalRequest): Observable<void> {
    return this.http.post<void>(environment.apiSecurityHost + '/portal/rbac/user/grant', request);
  }

  getAllRoles(retailerId: number): Observable<RBACRole[]> {
    return this.http.get<RBACRole[]>(this.baseUrl + `/${retailerId}/roles`);
  }

  getAllModules(retailerId: number): Observable<RBACModule[]> {
    return this.http.get<RBACModule[]>(this.baseUrl + `/${retailerId}/modules`);
  }

  revokeAccess(request: RevokeGrant[]): Observable<void> {
    return this.http.post<void>(environment.apiSecurityHost + '/portal/rbac/user/revoke', request);
  }
}
