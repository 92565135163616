import {Injectable} from "@angular/core";
import {GenericService} from "../../shared/services/generic.service";
import {DailyTransactionsSummary, GenericChart} from "./accounts.model";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {CurrentContextService} from "../../core/services/security/current-context.service";

@Injectable({
  providedIn: 'root'
})
export class AccountsService extends GenericService<DailyTransactionsSummary> {

  constructor(http: HttpClient, private currentContext: CurrentContextService) {
    super(http, environment.apiTransactHost + `/api/offering/district/retailer`);
  }

  getDailyTransactionChartData(params?: {name: string, val: any}[]): Observable<GenericChart> {
    let requestParams = new HttpParams();
    if (params) {
      params.filter(f => f.val !== null).forEach(ea => {requestParams = requestParams.append(ea.name, ea.val)});
    }
    return this.http.get<GenericChart>(this.getBaseUrl + `/transactions/summary/chart`, {params: requestParams})
  }

  get getBaseUrl(): string {
    const retailerId = this.currentContext.currentRetailer.id;
    return environment.apiTransactHost + `/api/offering/district/retailer/${retailerId}/accounts`;
  }
}
