import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CommonModule} from '@angular/common';
import {PageNotFoundComponent} from './core/components/page-not-found/page-not-found.component';
import {LoginComponent} from './features/pre-auth/login/login.component';
import {ForgotPasswordComponent} from './features/pre-auth/password-reset/forgot-password/forgot-password.component';
import {CheckYourEmailComponent} from './features/pre-auth/password-reset/check-your-email/check-your-email.component';
import {
  VerifyUpdatePasswordOtpComponent
} from './features/pre-auth/password-reset/verify-update-password-otp/verify-update-password-otp.component';
import {ResetPasswordComponent} from './features/pre-auth/password-reset/password-reset/password-reset.component';
import {HomeScreenComponent} from './core/components/home-screen-component/home-screen.component';
import {AuthGuard} from './core/services/security/auth-guard.service';
import {
  RegistrationRetailerBrandComponent
} from './features/pre-auth/retailer-registration/register-retailer-brand/registration-retailer-brand.component';
import {RegisterUserComponent} from './features/pre-auth/register-user/register-user.component';
import {VerifyAccountComponent} from './features/pre-auth/verify-account/verify-account.component';
import {NewApplicationPageComponent} from './features/applications/new-application-page/new-application-page.component';
import {CurrentLeaseComponent} from "./features/leases/current-lease/current-lease.component";
import {LeasingHistoryComponent} from "./features/leases/leasing-history/leasing-history.component";
import {TransactionsComponent} from "./features/accounts/transactions/transactions.component";
import {PayoutsComponent} from "./features/accounts/payouts/payouts.component";
import {ApplicationsComponent} from './features/forms/applications/applications.component';
import {
  SettingsBrandInformationComponent
} from './features/settings/settings-brand-information/settings-brand-information.component';
import {SettingsUsersComponent} from './features/settings/settings-users/settings-users.component';
import {
  SettingsBillingAndBankingComponent
} from './features/settings/settings-billing-and-banking/settings-billing-and-banking.component';
import {
  VerifyBankingDetailsComponent
} from './features/settings/settings-billing-and-banking/verify-banking-details/verify-banking-details.component';
import {
  DashboardsRetailerReportComponent
} from "./features/dashboards/dashboards-retailer-report/dashboards-retailer-report.component";
import {CustomerSupportComponent} from "./features/support/customer-support/customer-support.component";
import {PosHelpCenterComponent} from "./features/support/pos-help-center/pos-help-center.component";
import {PublicRetailSpacesComponent} from './features/pre-auth/public-retail-spaces/public-retail-spaces.component';
import {TurnoverCaptureComponent} from "./features/forms/turnover-capture/turnover-capture.component";
import {PathLocationContextGuardService} from './core/services/security/path-location-context-guard.service';
import {PathOfferingContextGuardService} from './core/services/security/path-offering-context-guard.service';

const routes: Routes = [

  {path: 'forgot-password', component: ForgotPasswordComponent},
  {path: 'forgot-password/check-your-email', component: CheckYourEmailComponent},
  {path: 'forgot-password/password-reset', component: ResetPasswordComponent},
  {path: 'forgot-password/verify', component: VerifyUpdatePasswordOtpComponent},

  //Login
  {path: ':locationCode/login', component: LoginComponent, canActivate: [PathLocationContextGuardService]},
  {path: ':locationCode/join', component: RegisterUserComponent, canActivate: [PathLocationContextGuardService]},
  {
    path: ':locationCode/account/verify',
    component: VerifyAccountComponent,
    canActivate: [PathLocationContextGuardService]
  }, // account otp

  {
    path: ':locationCode/:offeringCode/apply',
    component: NewApplicationPageComponent,
    canActivate: [PathOfferingContextGuardService]
  },


  //Public Offerings View
  // {path: 'retail-spaces', component: PublicRetailSpacesComponent},
  // {path: 'retail-spaces/:clientId', component: PublicRetailSpacesComponent},
  // {path: 'retail-spaces/:clientId/:offeringUuid', component: PublicRetailSpacesComponent},

  //Registration
  {
    path: ':locationCode/retailer/brand-info',
    component: RegistrationRetailerBrandComponent,
    canActivate: [AuthGuard, PathLocationContextGuardService]
  },

  //curation
  {
    path: 'user-profile/curation/:locationCode/:offeringCode',
    component: NewApplicationPageComponent,
    canActivate: [AuthGuard, PathOfferingContextGuardService]
  },

  //leases
  {path: 'leases/current-lease', component: CurrentLeaseComponent, canActivate: [AuthGuard]},
  {path: 'leases/history', component: LeasingHistoryComponent, canActivate: [AuthGuard]},

  //accounts
  {path: 'accounts/transactions', component: TransactionsComponent, canActivate: [AuthGuard]},
  {path: 'accounts/payouts', component: PayoutsComponent, canActivate: [AuthGuard]},

  //forms
  {path: 'forms/applications', component: ApplicationsComponent, canActivate: [AuthGuard]},
  {path: 'forms/turnover', component: TurnoverCaptureComponent, canActivate: [AuthGuard]},

  //dashboards
  {path: 'dashboards', component: DashboardsRetailerReportComponent},

  //settings
  {path: 'settings/brand-information', component: SettingsBrandInformationComponent, canActivate: [AuthGuard]},
  {path: 'settings/billing', component: SettingsBillingAndBankingComponent, canActivate: [AuthGuard]},
  {path: 'settings/users', component: SettingsUsersComponent, canActivate: [AuthGuard]},
  {path: 'settings/billing/verify', component: VerifyBankingDetailsComponent, canActivate: [AuthGuard]}, // billing otp

  // support
  {path: 'support', component: CustomerSupportComponent, canActivate: [AuthGuard]},
  {path: 'support/pos-help-center', component: PosHelpCenterComponent, canActivate: [AuthGuard]},

  {
    path: ':locationCode/home',
    component: HomeScreenComponent,
    canActivate: [AuthGuard, PathLocationContextGuardService]
  },
  {
    path: ':locationCode/:offeringCode/home',
    component: HomeScreenComponent,
    canActivate: [AuthGuard, PathLocationContextGuardService]
  },
  {
    path: ':locationCode/retail-spaces',
    component: PublicRetailSpacesComponent,
    canActivate: [PathLocationContextGuardService]
  },


  {path: '404', component: PageNotFoundComponent},

  {path: '**', redirectTo: '404', pathMatch: 'full'},
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
