<div class="page-content page-padding" fxLayout="column">

  <div fxFlexOffset="10px">
    <div fxLayout="row">
      <span class="subtitle-default">Daily Transaction Summary</span>
    </div>
  </div>

  <div class="table-card mat-elevation-z3" fxLayout="column" fxFlexOffset="30px" *ngIf="dataSource">
    <span class="heading-default">Daily transaction summary</span>
    <form fxFlexAlign="end">
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="50px">
        <app-custom-date-filter [customDateRangePresets]="dateFilters"
                                [showBorder]="false"
                                (dateRange)="getDateRange($event)"
                                [initialPreset]="'2'"
                                [showClearButton]="true"></app-custom-date-filter>
      </div>
    </form>

    <div fxLayout="row" fxFlexOffset="25px">
      <table mat-table class="custom-table chart-table" [dataSource]="dataSourceDisplayed">
        <ng-container matColumnDef="table-label">
          <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="displayedColumns.length"> Daily Transaction Summary </th>
        </ng-container>

        <ng-container matColumnDef="transactionDate">
          <th mat-header-cell *matHeaderCellDef> Date </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{dateUtils.displayShortDate(element.transactionDate)}} </td>
        </ng-container>

        <ng-container matColumnDef="saleAmountIncl">
          <th mat-header-cell *matHeaderCellDef> Total Sales </th>
          <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayRandAmount(element.saleAmountIncl)}} </td>
        </ng-container>

        <ng-container matColumnDef="transactionFeeIncl">
          <th mat-header-cell *matHeaderCellDef> Transaction Fee </th>
          <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{utils.displayRandAmount(element.transactionFeeIncl)}} </td>
        </ng-container>

        <ng-container matColumnDef="turnoverRentalFeeIncl">
          <th mat-header-cell *matHeaderCellDef> Rental Fee </th>
          <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{utils.displayRandAmount(element.turnoverRentalFeeIncl)}} </td>
        </ng-container>

        <ng-container matColumnDef="netBalancePayableRetailerInclRounded">
          <th mat-header-cell *matHeaderCellDef> Net Balance </th>
          <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{utils.displayRandAmount(element.netBalancePayableRetailerInclRounded)}} </td>
        </ng-container>

        <ng-container matColumnDef="chart">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell class="custom-table-cell btn-cursor" (click)="checkTableRows()"
              *matCellDef="let element; let i = index" [attr.rowspan]="10" [ngClass]="i === 0 ? 'table-chart-show' : 'table-chart-hide'">
            <div class="subtitle-default mt-3">Daily Transaction Summary</div>
            <div class="full-width">
              <canvas baseChart
                      [height]="370"
                      [data]="chartData"
                      [plugins]="chartPlugins"
                      [options]="chartOptions"
                      [type]="'line'"></canvas>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
        <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

    </div>
    <mat-paginator #paginator class="custom-paginator"
                   [length]="dataSource.totalElements"
                   [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>

</div>
