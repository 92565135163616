import {Component, Inject, OnInit} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {RetailerSocialMediaProfileServiceService} from './retailer-social-media-profile-service.service';
import {MatDialog} from '@angular/material/dialog';
import {DOCUMENT} from '@angular/common';
import {CurrentContextService} from 'src/app/core/services/security/current-context.service';
import {CustomValidators} from '../../../../shared/validators/custom-validators';
import {ErrorPopupComponent} from '../../../../shared/components/dialogs/error-popup/error-popup.component';
import {lastValueFrom} from 'rxjs';
import {SocialMediaProfile} from './social-media-profile.model';
import {LoadingService} from '../../../../core/services/loading.service';

@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.scss']
})
export class SocialMediaComponent implements OnInit {

  loading = false;
  edit: boolean = false;
  socialMediaProfilesForm: FormGroup;

  // @ts-ignore
  constructor(private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer,
              private fb: FormBuilder,
              private router: Router,
              private service: RetailerSocialMediaProfileServiceService,
              private context: CurrentContextService,
              private dialogService: MatDialog,
              @Inject(DOCUMENT) public document: Document,
              public loader: LoadingService) {
    this.socialMediaProfilesForm = this.fb.group({
      profiles: this.fb.array([])
    });
  }

  ngOnInit(): void {
    this.buildForm();
  }

  get profiles(): FormArray {
    return this.socialMediaProfilesForm.controls['profiles'] as FormArray;
  }

  onSubmit(): void {
    const values = this.profiles.controls.map(ea => {
      const result: any = ea.value;
      result.socialMediaProfileType = ea.value.socialMediaProfileType.value;
      return result;
    });

    lastValueFrom(this.service.save(this.retailerId(), values))
      .then(() => {
          this.profiles.clear();
          this.buildForm();
          this.edit = false;
        },
        (error: string) => {
          this.showErrorDialog();
          console.log(error)
        }
      );
  }

  toggleEdit(): void {
    this.edit = !this.edit;
    if (this.edit) {
      this.socialMediaProfilesForm.enable();
    } else {
      this.socialMediaProfilesForm.disable();
    }
  }

  private showErrorDialog(): void {
    this.dialogService.open(ErrorPopupComponent, {
      height: '300px',
      width: '350px',
      data: {
        description: 'An unexpected error occurred',
      },
    });
  }

  public getSrcURI(item: AbstractControl): string {
    if (item.value.socialMediaProfileType.value) {
      const type = item.value.socialMediaProfileType.value.code;
      return `../../../../assets/icons/addBrand/${type}.svg`;
    }
    return ''
  }

  private retailerId(): number {
    const currentRetailer = this.context.currentRetailer;
    return currentRetailer.id;
  }

  private buildForm(): void {
    this.loading = true;
    lastValueFrom(this.service.getProfiles(this.retailerId()))
      .then((res: SocialMediaProfile[]) => {
          this.loading = false;
          res.forEach(ea => {
            const profileType = ea.socialMediaProfileType;
            this.profiles.push(this.fb.group({
              id: [ea.id],
              socialMediaProfileType: [this.fb.group({
                id: [profileType.id],
                code: [profileType.code],
                name: [profileType.name],
                description: [profileType.description]
              })],
              retailerId: [ea.retailerId, Validators.required],
              profileUserName: [ea.profileUserName],
              url: [ea.url, CustomValidators.websiteUrl],
              socialMediaProfileTypeUuid: [profileType.id]
            }));
            this.socialMediaProfilesForm.disable();
          });
        },
        (error: string) => {
          console.log(error);
          this.loading = false;
          this.showErrorDialog();
        }
      );
  }
}
