<div class="full-width page-padding page-content" fxLayout="column">
  <div fxLayout="row" fxFlexOffset="20px">
    <span class="subtitle-default">Legal Documents</span>
  </div>

  <div class="table-card mat-elevation-z3" fxLayout="column" fxFlexOffset="30px">
    <span class="heading-default">Documents</span>

    <table mat-table class="custom-table" [dataSource]="dataSource" fxFlexOffset="25px">

      <ng-container matColumnDef="table-label">
        <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="displayedColumns.length"> Lease Documents </th>
      </ng-container>

      <ng-container matColumnDef="fileName">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayString(element.fileName, 20)}} </td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef> Category </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayStatus(element.documentTypeCode)}} m<sup>2</sup> </td>
      </ng-container>

      <ng-container matColumnDef="versionNo">
        <th mat-header-cell *matHeaderCellDef> Version # </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{element.versionNo}} </td>
      </ng-container>

      <ng-container matColumnDef="documentType">
        <th mat-header-cell *matHeaderCellDef> Document Type </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayString(element.mimeType, 20)}} </td>
      </ng-container>

      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator #paginator class="custom-paginator"
                   [length]="dataSource ? dataSource.data.length : 0"
                   [pageSize]="5"
                   [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>

</div>
