import {Component, OnInit, ViewChild} from '@angular/core';
import {CurrentContextService} from '../../../core/services/security/current-context.service';
import {
  IndustryCategory,
  Retailer,
  RetailerAddress,
  RetailerContacts,
  RetailerIndustry
} from '../../../core/model/retailer.model';
import {User} from '../../../core/model/user.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CustomValidators} from '../../../shared/validators/custom-validators';
import {RetailerService} from '../../../core/services/retailer.service';
import {lastValueFrom} from 'rxjs';
import {MapsAddressUtilService} from '../../../shared/services/maps-address-util.service';
import {SocialMediaComponent} from './social-media/social-media.component';
import {LoadingService} from "../../../core/services/loading.service";

@Component({
  selector: 'app-settings-brand-information',
  templateUrl: './settings-brand-information.component.html',
  styleUrls: ['./settings-brand-information.component.scss']
})
export class SettingsBrandInformationComponent implements OnInit {

  @ViewChild('socialMediaComponent') socialMediaComponent!: SocialMediaComponent;

  currentRetailer: Retailer | null;
  currentUser: User | null;
  retailerForm: FormGroup;
  loading: boolean = false;
  errorMsg: string | null = null;
  // userForm: FormGroup;
  initLocation: any;
  industryList: IndustryCategory[] = [];
  newLocation: boolean = false;
  place: any;
  geometry: any;

  edit: boolean = false;


  constructor(private contextService: CurrentContextService,
              private retailerService: RetailerService,
              private mapsUtils: MapsAddressUtilService,
              public loader: LoadingService) {
    this.currentRetailer = this.contextService.currentRetailer;
    this.currentUser = this.contextService.currentUser;

    this.retailerForm = new FormGroup({
      companyName: new FormControl({value: this.currentRetailer.companyName, disabled: false}, Validators.required),
      industries: new FormControl({value: null, disabled: false}, Validators.required),
      websiteUrl: new FormControl({
        value: this.currentRetailer.websiteUrl,
        disabled: false
      }, [Validators.required, CustomValidators.websiteUrl]),
      description: new FormControl({value: this.currentRetailer.description, disabled: false}, Validators.required),
      phoneNumber: new FormControl({value: this.displayPhoneNumber(this.currentRetailer.contacts), disabled: true}),
      physicalAddress: new FormControl({value: this.displayAddress(this.currentRetailer.addresses), disabled: false}),

    });
    const codes: string[] = [];
    this.currentRetailer.industries.forEach(item => {
      codes.push(item.industryType.code);
    });
    this.retailerForm.get('industries')!.setValue(codes);
    if (this.arrayNotNullFirstElementPresent(this.currentRetailer.addresses)) {
      const arr = this.currentRetailer.addresses[0].formattedAddress.split(',');
      this.initLocation = arr.splice(0, 3).join(',');
    }
  }

  ngOnInit(): void {
    this.loadIndustries();
    this.retailerForm.disable();
  }

  private loadIndustries(): void {
    lastValueFrom(this.retailerService.getIndustries())
      .then((res: IndustryCategory[]) => {
          this.industryList = res;
        },
        (err: string) => {
          console.log(err);
        }
      );
  }

  saveUpdatesToBrandInformation(): void {
    this.loading = true;
    const profile = ({
      ...this.retailerForm.getRawValue(),
      id: this.contextService.currentRetailer.id,
    });
    this.newLocation = (this.initLocation != this.retailerForm.get('physicalAddress')!.value);
    if (this.newLocation) {
      // new location selected
      const addressData = this.mapsUtils.parseAddressData(this.geometry, this.place);
      profile.address = addressData.address;
      profile.addressPlace = addressData.addressPlace;
    } else {
      // old location
      profile.address = null;
      profile.addressPlace = null;
    }

    lastValueFrom(this.retailerService.updateRetailer(profile))
      .then((res: Retailer) => {
          this.loading = false;
          this.contextService.setCurrentRetailerInContext(res);
          this.edit = false;
        },
        (err: any) => {
          this.loading = false;
          console.log(err);
          if ('ValidationException' === err.headers.get('error_code')) {
            this.errorMsg = err.headers.get('error_message');
          }
        }
      );
  }

  onAddressAutocompleteSelected(event: any): void {
    this.place = event;
  }

  onAddressLocationSelected(event: any): void {
    this.geometry = event;
  }

  displayIndustries(industries: RetailerIndustry[]): string {
    let industryList: string[] = [];
    industries.forEach((industry: RetailerIndustry) => {
      industryList.push(industry.industryType.name);
    });
    return industryList.join(', ');

  }

  displayPhoneNumber(retailerContacts: RetailerContacts[]): string {
    if (this.arrayNotNullFirstElementPresent(retailerContacts))
      return retailerContacts[0].value;
    return '';
  }

  displayAddress(addresses: RetailerAddress[]): string {
    if (this.arrayNotNullFirstElementPresent(addresses))
      return addresses[0].formattedAddress;
    return '';
  }

  private arrayNotNullFirstElementPresent(input: any[]): boolean {
    return (input.length > 0 && input[0] !== null && input[0] !== undefined);
  }

  toggleEdit(): void {
    this.edit = !this.edit;
    if (this.edit) {
      this.retailerForm.enable();
    } else {
      this.retailerForm.disable();
    }
  }
}
