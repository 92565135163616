import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {CurrentContextService} from './current-context.service';

@Injectable({
  providedIn: 'root'
})
export class PathLocationContextGuardService implements CanActivate {

  constructor(private router: Router,
              private context: CurrentContextService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let locationCode = route.paramMap.get('locationCode')?.toLocaleLowerCase();

    // Immediately navigate to 404 if the location code starts with a 404 or null
    if (locationCode === undefined
      || locationCode === null
      || locationCode.toLowerCase() === 'null'
      || locationCode.toLowerCase() === '404'
    ) {
      this.router.navigate(['404'])
        .catch((error: string) => {
          console.error(error);
        });
      return Promise.resolve(false);
    }

    return this.context.setCurrentLocationCode(locationCode!)
      .then((): Promise<boolean> => {
        if (!this.context.isCurrentLocationSet()) {
          return this.router.navigate(['/404'])
            .catch((error: string) => {
              console.log(error);
              return Promise.resolve(false);
            });
        }
        return Promise.resolve(true);
      });
  }

}
