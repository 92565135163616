import {Injectable} from "@angular/core";
import {LeaseDocument, LeaseGridSpace, LeaseSummary, PaymentConfigData, PaymentMethod} from "./leasing.model";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {GenericService} from "../../shared/services/generic.service";
import {CurrentContextService} from "../../core/services/security/current-context.service";

@Injectable({
  providedIn: 'root'
})
export class LeasingService extends GenericService<LeaseSummary> {
  constructor(http: HttpClient, private context: CurrentContextService) {
    super(http, environment.apiLeaseHost + `/api/offering/district/retailer`);
  }

  getCurrentLease(): Observable<LeaseSummary> {
    return this.http.get<LeaseSummary>(this.getBaseUrl + `/current-lease`);
  }

  getCurrentLeaseSpaces(): Observable<LeaseGridSpace[]> {
    return this.http.get<LeaseGridSpace[]>(this.getBaseUrl + `/current-lease/spaces`);
  }

  getCurrentLeasePaymentConfig(): Observable<PaymentConfigData> {
    return this.http.get<PaymentConfigData>(this.getBaseUrl + `/current-lease/rates`);
  }

  getPaymentMethods(): Observable<PaymentMethod[]> {
    return this.http.get<PaymentMethod[]>(this.getBaseUrl + `/current-lease/rates/payment-methods`);
  }

  getCurrentLeaseDocuments(): Observable<LeaseDocument[]> {
    return this.http.get<LeaseDocument[]>(this.getBaseUrl + `/current-lease/documents`);
  }

  get getBaseUrl() {
    const retailerId = this.context.currentRetailer.id;
    return environment.apiLeaseHost + `/api/offering/district/retailer/${retailerId}/leasing`
  }

  getRentalType(typeCode: string | null): string {
    if (typeCode === 'TURNOVER_PERCENTAGE') return 'Percentage rental';
    if (typeCode === 'PRICING_MODEL') return 'Fixed rental';
    return '-';
  }
}
