import {Component} from '@angular/core';
import {LocationOffering} from '../../model/location-offering.interface';
import {CurrentContextService} from '../../services/security/current-context.service';
import {LandlordsService} from '../../services/landlords.service';
import {AuthenticationService} from '../../services/security/authentication.service';
import {ThemeService} from "../../../../themes/theme-service.service";
import {ActivatedRoute, Params, Router} from '@angular/router';
import {lastValueFrom} from 'rxjs';
import {UtilsService} from '../../../shared/services/utils.service';

@Component({
  selector: 'app-home-screen',
  templateUrl: './home-screen.component.html',
  styleUrls: ['./home-screen.component.scss']
})
export class HomeScreenComponent {
  offerings: LocationOffering [] = [];
  accessibleOfferings: string[] = [];
  selectedOffering: LocationOffering | null = null;
  offeringNameSearchValue: string = "";


  constructor(private contextService: CurrentContextService,
              private landlordsService: LandlordsService,
              private authService: AuthenticationService,
              private themeService: ThemeService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private utilsService: UtilsService) {

    authService.isLoggedIn
      .subscribe((loggedIn: boolean) => {
        if (loggedIn) {
          this.activatedRoute.queryParams.subscribe((params: Params) => {
            if (params.hasOwnProperty('selectedOffering')) {
              lastValueFrom(this.landlordsService.getOffering(params['selectedOffering']))
                .then((res: LocationOffering) => {
                  this.enterPortal(res);
                })
                .catch((error: string) => {
                  console.log(error);
                });
            }
          });

          this.accessibleOfferings = this.contextService.getAccessibleOfferingsFromToken();
          // todo fix this method to not reload the page
          this.contextService.getAccessibleOfferingsAsObject()
            .then((offerings: LocationOffering[]) => {
              this.offerings = offerings;
              let locationCode: string | null = this.contextService.getCurrentLocationCode();
              if (locationCode) {
                this.offerings = this.offerings.filter((offering: LocationOffering) => {
                  return offering.locationCode == locationCode;
                });
              }

              let offeringCode: string | null = this.activatedRoute.snapshot.paramMap.get('offeringCode');

              let currentOffering: LocationOffering | null = this.contextService.getCurrentOffering();

              // check if the param map has a location code and an offering code
              if (locationCode && offeringCode) {
                // check if the current offering isn't already set to what's in the url params
                if (!(currentOffering && currentOffering.offeringCode == offeringCode)) {
                  let index: number = this.offerings.findIndex((offering) => offering.offeringCode.toLowerCase() == offeringCode!.toLocaleLowerCase());
                  if (index >= 0) {
                    this.selectedOffering = this.offerings[index];
                    this.contextService.setCurrentOffering(this.selectedOffering);
                  } else {
                    this.router.navigate(['404'])
                      .catch((err: string) => {
                        console.log(err)
                      })
                  }
                }
              }
            });

        }
      });


    if (!this.selectedOffering) {
      this.selectedOffering = contextService.getCurrentOffering();
    }

    this.contextService.getOfferingChangeEvent()
      .subscribe((offering: LocationOffering) => {
        this.selectedOffering = offering;
      });

    this.contextService.getClearCurrentOfferingEvent()
      .subscribe((next: boolean) => {
        if (next) {
          this.selectedOffering = null;
          this.themeService.setThemeColors(null);
        }
      });
  }

  enterPortal($event: LocationOffering): void {
    this.selectedOffering = $event;
    this.contextService.setCurrentOffering($event);
  }

  getTitle() {
    return `Welcome to Your ${this.selectedOffering!.offeringName} Portal`;
  }

  searchOfferings($event: string): void {
    this.offeringNameSearchValue = $event;
  }
}
