import {Component, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {CustomerSupportService} from "./customer-support.service";
import {CurrentContextService} from '../../../core/services/security/current-context.service';

@Component({
  selector: 'app-customer-support',
  templateUrl: './customer-support.component.html',
  styleUrls: ['./customer-support.component.scss']
})
export class CustomerSupportComponent implements OnInit {

  supportEmail: string | null = null;
  accountsEmail: string | null = null;
  helpdeskUrl: string | null = null;
  posExpert: boolean = true;

  constructor(private matDialog: MatDialog,
              private router: Router,
              private supportService: CustomerSupportService,
              private currentContext: CurrentContextService) {
  }

  ngOnInit() {
    this.supportService.getSupportContacts().subscribe({
      next: value => {
        const support = value.filter(f => f.contactTypeCode === 'GENERAL_SUPPORT_EMAIL');
        const accounts = value.filter(f => f.contactTypeCode === 'ACCOUNTS_SUPPORT_EMAIL');
        const helpdesk = value.filter(f => f.contactTypeCode === 'PRIVATE_HELPDESK_URL');

        this.supportEmail = support.length > 0 ? support[0].value : null;
        this.accountsEmail = accounts.length > 0 ? accounts[0].value : null;
        this.helpdeskUrl = helpdesk.length > 0 ? helpdesk[0].value : null;
      },
      error: err => console.log(err)
    });

    let currentLocationCode: string | null = this.currentContext.getCurrentLocationCode();
    if (currentLocationCode && currentLocationCode.toLowerCase() == 'sook-mall-of-africa') {
      this.posExpert = false;
    }

  }

  goToHelpCentre() {
    this.router.navigate(['support/pos-help-center']);
  }

  goToHelpDesk() {
    window.open(this.helpdeskUrl!, '_blank');
  }

  logSupportRequest() {
    window.open('https://sokodistrict.atlassian.net/servicedesk/customer/portal/1', '_blank');
  }

}
