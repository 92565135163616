<div class="px-3 py-2 pb-3 full-width full-screen-height" fxLayout="column" fxLayoutAlign="center center">

  <div class="full-width flex-row">
    <div class="full-width pre-auth-header-div" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
      <h1 class="title-large" fxFlexOffset.gt-sm="20px">Check your mail</h1>
      <h2 class="subtitle-regular">We have sent you a reset password OTP on your registered email address.</h2>
    </div>
  </div>
  <div class="full-width flex-row" fxLayoutAlign="center center" fxFlexOffset="90px">
    <form class="pre-auth-form" fxFlexOffset.gt-sm="20px">
      <button (click)="onSubmit()" [disabled]="loading || (loader.loading$ | async)" class="button-primary" mat-button>
        <div class="button-text" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
          {{ loading ? 'Working...' : 'Continue'}}
          <mat-icon *ngIf="loading">
            <mat-spinner diameter="20"></mat-spinner>
          </mat-icon>
        </div>
      </button>
    </form>
  </div>
  <div class="p-2 heading-regular">Didn’t receive an OTP?&nbsp;
    <span (click)="!loading ? onResendOTP() : null" class="btn-cursor">Resend OTP.</span>
    <mat-error class="link-error" *ngIf="errMessage">{{errMessage}}</mat-error>
  </div>

  <app-footer-links [showCopyrightOnly]="true"></app-footer-links>

</div>
