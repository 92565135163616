import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {LeaseGridSpace, LeaseSummary} from "../../leasing.model";
import {SelectionModel} from "@angular/cdk/collections";
import {UtilsService} from "../../../../shared/services/utils.service";
import {PaginatorService} from "../../../../shared/services/paginator.service";
import {DateUtilsService} from "../../../../shared/services/dateUtils.service";
import {LeasingService} from "../../leasing.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-lease-spaces',
  templateUrl: './lease-spaces.component.html',
  styleUrls: ['./lease-spaces.component.scss']
})
export class LeaseSpacesComponent implements OnInit, AfterViewInit {
  @Input() id!: string;
  @ViewChild('paginator') paginator!: MatPaginator;

  displayedColumns: string[] = ['spaceName', 'size', 'startDate', 'endDate'];
  dataSource!: MatTableDataSource<LeaseGridSpace>;
  selection = new SelectionModel<LeaseGridSpace>(true, []);

  lease?: LeaseSummary;

  constructor(public utils: UtilsService,
              public dateUtils: DateUtilsService,
              private paginatorService: PaginatorService,
              private leasingService: LeasingService,
              private matDialog: MatDialog) {
  }

  ngOnInit() {
    this.loadSpaces();
  }

  ngAfterViewInit() {
    this.paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
    }
  }

  loadSpaces() {
    this.leasingService.getCurrentLeaseSpaces().subscribe({
      next: value => this.dataSource = new MatTableDataSource<LeaseGridSpace>(value),
      error: err => console.log(err)
    })
  }

}
