import {Component, Input} from '@angular/core';
import {DateRange} from "@angular/material/datepicker";
import {DateUtilsService} from "../../../../shared/services/dateUtils.service";
import {DashboardUtilsService} from "../../dashboard-utils.service";
import {DashboardChart} from "../../dashboards.model";

@Component({
  selector: 'app-dashboards-stat-card',
  templateUrl: './dashboards-stat-card.component.html',
  styleUrls: ['./dashboards-stat-card.component.scss']
})
export class DashboardsStatCardComponent {
  @Input() chart!: DashboardChart<any>;
  @Input() chartStats!: string[];
  @Input() icon!: string;
  @Input() fullNumber: boolean = true;
  @Input() dateRange?: DateRange<Date>;

  constructor(public dashboardUtils: DashboardUtilsService, public dateUtils: DateUtilsService) {
  }

}
