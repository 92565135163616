import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {LocationOffering, LocationOfferingFilterRequest} from '../model/location-offering.interface';
import {PlatformOfferingTypesModel} from '../model/platform-offering-types.model';
import {LandlordLocationModel} from '../model/landlord-location.model';


@Injectable({
  providedIn: 'root'
})
export class LandlordsService {

  offeringTypes: string[] = [
    'DISTRICT',
    'STORAGE',
    'KIOSK',
    'SPACES',
    'GENERAL_LEASING',
    'POP_UPS',
    'DIGITAL_ADVERTISING',
    'MARKETS',
    'EXHIBITIONS',
  ];

  constructor(private http: HttpClient) {
  }


  getAllOfferings(filterRequest?: LocationOfferingFilterRequest): Observable<LocationOffering[]> {
    let offeringTypesFilter = filterRequest?.offeringTypes;

    let params = new HttpParams();
    params = params.append('offeringTypes', (offeringTypesFilter !== null && offeringTypesFilter != undefined && offeringTypesFilter.length > 0) ? offeringTypesFilter.join(",") : this.offeringTypes.join(","));
    if (filterRequest?.regions) {
      params = params.append('regions', filterRequest!.regions?.join(","));
    }
    if (filterRequest?.offeringName) {
      params = params.append('offeringName', filterRequest.offeringName)
    }

    return this.http.get<LocationOffering[]>(environment.apiLandlordHost +
      `/api/public/landlord/location/offering`, {params: params}
    );
  }

  getOfferingTypes(): Observable<PlatformOfferingTypesModel[]> {
    return this.http.get<PlatformOfferingTypesModel[]>(environment.apiSecurityHost + '/api/public/offering/types');
  }

  getOffering(uuid: string): Observable<LocationOffering> {
    return this.http.get<LocationOffering>(environment.apiLandlordHost + `/api/public/landlord/location/offering/${uuid}`);
  }

  getOfferingByCode(locationCode: string, offeringCode: string): Observable<LocationOffering> {
    return this.http.get<LocationOffering>(environment.apiLandlordHost + `/api/public/landlord/location/code/${locationCode}/offering/code/${offeringCode}`);
  }

  getLandlordLocationByCode(locationCode: string): Observable<LandlordLocationModel> {
    return this.http.post<LandlordLocationModel>(environment.apiLandlordHost + `/api/public/landlord/location`,{locationCode: locationCode});
  }
}
