import {Component, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../../core/services/user.service';
import {ErrorPopupComponent} from '../../../../shared/components/dialogs/error-popup/error-popup.component';
import {CurrentContextService} from '../../../../core/services/security/current-context.service';
import {RetailerPaymentMethodsService} from '../../service/payments.service';
import {AuthenticationService} from '../../../../core/services/security/authentication.service';
import {lastValueFrom} from 'rxjs';
import {IconPopupComponent} from '../../../../shared/components/dialogs/icon-popup/icon-popup.component';
import {LoadingService} from "../../../../core/services/loading.service";

@Component({
  selector: 'app-verify-banking-details',
  templateUrl: './verify-banking-details.component.html',
  styleUrls: ['./verify-banking-details.component.scss']
})
export class VerifyBankingDetailsComponent {
  public otpForm;
  public errMessage: string | null = null;
  public loading: boolean = false;
  public email: string = '';
  public len = 0;

  @ViewChild('ngOtpInput') ngOtpInputRef: any;

  otpInputConfig = {
    length: 4,
    allowNumbersOnly: true,
    containerClass: 'otp-wrapper-element',
    inputClass: 'otp-input-element'
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private fb: FormBuilder,
    private context: CurrentContextService,
    private authService: AuthenticationService,
    private dialogService: MatDialog,
    private paymentService: RetailerPaymentMethodsService,
    public loader: LoadingService
  ) {
    this.otpForm = this.resetForm();
  }

  ngOnInit(): void {
    this.getUserEmail();
  }

  onOtpChange(event: any): void {
    this.otpForm.get('pin')!.setValue(event);
    this.getPinAmount();
    this.len = this.otpForm.value.pin.toString().length;
  }

  public getPinAmount(): boolean {
    return this.otpForm.value.pin ? this.otpForm.value.pin.toString().length < 4 : true;
  }

  public onVerify(): void {
    this.loading = true;
    if (this.otpForm.value.pin) {
      lastValueFrom(this.paymentService.paymentMethodOtpConfirm(this.otpForm.value.pin))
        .then(() => {
          this.loading = false;
          this.router.navigate(['settings/billing'])
            .catch((err: string) => {
              console.log(err);
            });
        }, err => {
          this.loading = false;
          this.handleError(err);
        });
    }
  }

  public onResendOTP(): void {
    this.loading = true;
    this.errMessage = null;
    lastValueFrom(this.paymentService.createOtpRequest())
      .then(() => {
        this.loading = false;
        // show success message
        this.dialogService.open(IconPopupComponent, {
          height: '300px',
          width: '350px',
          data: {
            description: 'We have sent a One Time Pin to',
            boldDescription: this.context.currentUser.email,
          },
        });
      }, err => {
        this.handleError(err);
      });
  }

  private getUserEmail(): void {
    this.email = this.context.currentUser.email;
  }

  private resetForm(): FormGroup {
    return this.fb.group({
      pin: [null, Validators.required],
    });
  }

  private handleError(err: any): void {
    switch (err.status) {
      case 400:
        if ('ValidationException' === err.headers.get('error_code')) {
          this.errMessage = '' + err.headers.get('error_message');
          if (this.errMessage.includes('400 : [ValidationException: ')) {
            let newMessage = this.errMessage.replace('400 : [ValidationException:', '');
            newMessage = newMessage.replace(']', '');
            this.errMessage = newMessage;
          }
          this.otpForm.get('pin')!.setValue(null);
          this.ngOtpInputRef.setValue(null);
        }
        break;
      default:
        this.dialogService.open(ErrorPopupComponent, {
          height: '300px',
          width: '350px',
          data: {
            description: 'an unexpected error: ' + err,
          },
        });
    }
  }
}
