import {Component} from '@angular/core';
import {CurrentContextService} from '../../services/security/current-context.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {

  constructor(private context: CurrentContextService) {
    this.context.reset(true);
  }
}
