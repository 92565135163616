import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Category} from "./questions.model";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class HelpCenterService {

  constructor(private http: HttpClient) {
  }

  getAllCategories(): Observable<Category[]> {
    return this.http.get<Category[]>(this.baseUrl + '/category');
  }

  getLocalDoc(docName: string): Observable<Blob> {
    return this.http.get('assets/support/pdf/' + docName, { responseType: 'blob' });
  }

  get baseUrl(): string {
    return environment.apiSharedServicesHost + '/api/public/support'
  }
}
