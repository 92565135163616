<div class="full-width page-padding page-content" fxLayout="column">
  <div fxLayout="row" fxFlexOffset="20px">
    <span class="subtitle-default">Spaces</span>
  </div>

  <div class="table-card mat-elevation-z3" fxLayout="column" fxFlexOffset="30px">
    <span class="heading-default">Current Spaces</span>

    <table mat-table class="custom-table" [dataSource]="dataSource" fxFlexOffset="25px">

      <ng-container matColumnDef="table-label">
        <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="displayedColumns.length"> Lease Spaces </th>
      </ng-container>

      <ng-container matColumnDef="spaceName">
        <th mat-header-cell *matHeaderCellDef> Space Name </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayString(element.spaceName, 20)}} </td>
      </ng-container>

      <ng-container matColumnDef="size">
        <th mat-header-cell *matHeaderCellDef> Size (sum) </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{element.spaceSize}} m<sup>2</sup> </td>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef> Start Date </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{dateUtils.displayShortDate(element.leaseStartDate)}} </td>
      </ng-container>

      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef> End Date </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{dateUtils.displayShortDate(element.leaseEndDate)}} </td>
      </ng-container>

      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator #paginator class="custom-paginator"
                   [length]="dataSource ? dataSource.data.length : 0"
                   [pageSize]="5"
                   [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>

</div>
