import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {GoogleAnalyticsService} from '../../../../core/services/google-analytics.service';
import {LoadingService} from "../../../../core/services/loading.service";

@Component({
  selector: 'app-retailer-await-curation',
  templateUrl: './retailer-await-curation.component.html',
  styleUrls: ['./retailer-await-curation.component.scss']
})
export class RetailerAwaitCurationComponent implements OnInit {

  constructor(private googleAnalyticsService: GoogleAnalyticsService,
              private router: Router,
              public loader: LoadingService) {
  }

  ngOnInit(): void {
    this.googleAnalyticsService.customEventEmitter('Retailer Onboarding', 'view', 'curate_view');
  }

  navToDistrict(): void {
    this.router.navigate(['/district/district-about']);
    this.googleAnalyticsService.customEventEmitter('Retailer Onboarding', 'click', 'curate_exploreDistrict_click');
  }

}
