import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {
  @Input() banner: boolean = false;
  @Input() fullHeight: boolean = false;
  @Input() section?: string;
  @Input() title!: string;
  @Input() description!: string;
  @Input() subtitle?: string;
  @Input() descriptionAboveLine: boolean = false;
  @Input() backgroundWhite: boolean = false;
  @Input() hasSearchBar: boolean = false;
  @Input() searchBarLabel?: string;
  @Output() searchBarValueChange: EventEmitter<string> = new EventEmitter<string>();

  searchBarForm: FormGroup;


  constructor(private activatedRoute: ActivatedRoute) {
    this.searchBarForm = new FormGroup({
      searchValue: new FormControl('')
    });

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.hasOwnProperty('searchVal')) {
        this.searchBarForm.get('searchValue')?.setValue(params['searchVal']);
      }
    });

    this.searchBarForm.get('searchValue')!.valueChanges.subscribe((value: string) => {
      this.searchBarValueChange.emit(value);
    });
  }
}
