import {ChartConfiguration} from "chart.js";
import DataLabelsPlugin from "chartjs-plugin-datalabels";

export const barChartOptions: ChartConfiguration['options'] = {
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    line: {
      tension: 0.5
    }
  },
  scales: {
    x: {
      border: {display: false},
      grid: {display: false},
      ticks: {
        maxRotation: 0,
        font: {
          family: 'Roboto'
        },
      }
    },
    y: {
      ticks: {
        font: {
          family: 'Roboto'
        },
        maxTicksLimit: 5,
        callback: function(label: any, index: any, labels: any) { return 'R' + (label > 1000 ? Number(label)/1000 + 'k' : label) }
      },
      min: 0,
      // grid: {
      //   display: false
      // }
    }
  },
  plugins: {
    legend: {
      position: 'top',
      align: 'end',
      labels: {
        usePointStyle: true,
        pointStyle: 'circle',
        boxWidth: 5,
        boxHeight: 5,
      }
    },
    datalabels: {
      display: false
    }
  },
  layout: {
    padding: 20
  }
}

export const barChartPlugins = [ DataLabelsPlugin ];
