<div
  class="retailer-await-container dark-view"
  fxLayout="column"
  fxLayout.gt-sm="row"
  fxLayoutAlign="start start"
  fxLayoutAlign.gt-sm="start stretch"
>
  <div
    class="retailer-await-picture-container"
    fxFlex="50"
    fxLayout="row"
    fxLayout.gt-sm="column"
    fxLayoutAlign="center start"
  >
    <picture>
      <source
        class="retailer-await-image"
        media="(min-width: 960px)"
        srcset="assets/images/Brandinspace-06-large.png"
      />
      <img
        class="retailer-await-image"
        src="assets/images/Brandinspace-06.png"
      />
    </picture>
  </div>
  <div
    class="retailer-await-text-container"
    fxFlex.gt-sm
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutAlign.gt-sm="center start">
      <div class="mat-headline retailer-await-headline">
        Your brand has been successfully registered!
      </div>
      <div class="mat-tagline retailer-await-text">
        Sit back and relax while we validate your profile details. We will send you an email when you can come back to
        start designing your very own store.
        <br><br>
        In the meanwhile, go ahead and check out the district
        <span (click)="navToDistrict()" class="btn-cursor" style="text-decoration: underline;">here!</span>
      </div>
      <div class="btn-container" fxFlexOffset="20px" fxFlexOffset.gt-sm="20px">
        <button (click)="navToDistrict()" class="page-button" mat-button [disabled]="loader.loading$ | async"><div class="button-text">Explore the district</div></button>
      </div>
    </div>
  </div>
</div>
