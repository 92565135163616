import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-footer-links',
  templateUrl: './footer-links.component.html',
  styleUrls: ['./footer-links.component.scss']
})
export class FooterLinksComponent {

  @Input() showLinksOnly: boolean;
  @Input() showCopyrightOnly: boolean;
  @Input() footerText?: FOOTER_TEXT_STYLE;
  @Input() customText?: string;


  constructor() {
    this.showLinksOnly = false;
    this.showCopyrightOnly = false;
  }

  get getDate(): any {
    return new Date();
  }

  openPrivacyPolicyLink(): void {
    window.open('https://www.iubenda.com/privacy-policy/85763951');
  }

  openTermsAndConditionsLink() {
    window.open('https://www.iubenda.com/terms-and-conditions/85763951');
  }

  getText(): string {
    const defaultText = 'By logging in, you agree to our Terms of use and acknowledge that you have read our Privacy Policy.';
    if (this.footerText) {
      switch (this.footerText) {
        case FOOTER_TEXT_STYLE.NONE:
          return '';
        case FOOTER_TEXT_STYLE.LOGIN:
          return defaultText;
        case FOOTER_TEXT_STYLE.SIGNUP:
          return 'By creating a profile, you agree to our Terms of Use and acknowledge that you have read our Privacy Policy.';
        case FOOTER_TEXT_STYLE.APPLICATION:
          return 'By submitting this form, you agree to our Terms of Use and acknowledge that you have read our Privacy Policy.';
        case FOOTER_TEXT_STYLE.CUSTOM:
          return (this.customText) ? this.customText : defaultText;
      }
    }
    return defaultText;
  }
}

export enum FOOTER_TEXT_STYLE {
  NONE = 'NONE',
  LOGIN = 'LOGIN',
  SIGNUP = 'SIGNUP',
  APPLICATION = 'APPLICATION',
  CUSTOM = 'CUSTOM'
}
