import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-dashboards-info-dialog',
  templateUrl: './dashboards-info-dialog.component.html',
  styleUrls: ['./dashboards-info-dialog.component.scss']
})
export class DashboardsInfoDialogComponent {

  constructor(public dialogRef: MatDialogRef<DashboardsInfoDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  close() {
    this.dialogRef.close();
  }

}
