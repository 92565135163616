import {RequestPasswordUpdateOTP} from "./request-password-update-otp.model";
import {UPDATE_PASSWORD_OTP_TYPE} from "./update-password-otp-type.enum.model";

export class PasswordUpdate {
  public otp!: string;
  public otpType!: UPDATE_PASSWORD_OTP_TYPE;
  public secret!: string;
  public secretConfirm!: string;
  public user!: RequestPasswordUpdateOTP;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}
