import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators,} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {UserService} from '../../../core/services/user.service';
import {ErrorPopupComponent} from '../../../shared/components/dialogs/error-popup/error-popup.component';
import {CurrentContextService} from '../../../core/services/security/current-context.service';
import {IconPopupComponent} from '../../../shared/components/dialogs/icon-popup/icon-popup.component';
import {AuthenticationService} from '../../../core/services/security/authentication.service';
import {GoogleAnalyticsService} from '../../../core/services/google-analytics.service';
import {VerifyOTPRequest} from '../../../core/model/user.model';
import {lastValueFrom} from 'rxjs';
import {LoadingService} from "../../../core/services/loading.service";


@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.scss'],
})
export class VerifyAccountComponent implements OnInit {
  public otpForm;
  public errMessage: string | null = null;
  public loading: boolean = false;
  public len = 0;

  @ViewChild('ngOtpInput') ngOtpInputRef: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private fb: FormBuilder,
    private context: CurrentContextService,
    private authService: AuthenticationService,
    private dialogService: MatDialog,
    private googleAnalyticsService: GoogleAnalyticsService,
    public loader: LoadingService
  ) {
    this.otpForm = this.resetForm();
  }

  get userEmail(): string {
    if(this.context.currentUser) {
      return this.context.currentUser.email;
    }
    if (this.route.snapshot.paramMap.get('userEmail')){
      return this.route.snapshot.paramMap.get('userEmail')!;
    }
    return '';
  }

  ngOnInit(): void {
    this.googleAnalyticsService.customEventEmitter('Retailer Onboarding', 'view', 'verify_view');
    this.route.queryParams.subscribe((params: Params) => {
      if (params.hasOwnProperty('sendOtp')) {
        this.onResendOTP(false);
      }
    });
  }

  onOtpChange(event: any) {
    this.otpForm.get('pin')!.setValue(event);
    this.len = this.otpForm.value.pin.toString().length;
  }

  onVerify() {
    const id = this.context.currentUser.id;
    const pin = this.otpForm.value.pin;
    const request: VerifyOTPRequest = {id: id, pin: pin};

    this.loading = true;
    this.errMessage = null;
    lastValueFrom(this.userService.verifyNewUserAccount(request))
      .then(
        (res) => {
          this.loading = false;
          this.authService.setToken(res);

          // set user as verified in the context
          this.authService.verifyUser();
          const returnUrl = this.route.snapshot.paramMap.get('returnUrl');
          let currentLocationCode = this.context.getCurrentLocationCode();

          let routeTo = `${currentLocationCode}/retailer/brand-info`;
          if (this.context.currentRetailer) routeTo = `/${currentLocationCode}/home`;
          if (returnUrl) routeTo = returnUrl;

          this.router.navigate([routeTo])
            .catch((error: string) => {
              console.log(error)
            });
          this.googleAnalyticsService.customEventEmitter('Retailer Onboarding', 'submit', 'verify_submit');
        },
        (err) => {
          this.loading = false;
          switch (err.status) {
            case 400:
              if ('ValidationException' === err.headers.get('error_code')) {
                this.errMessage = '' + err.headers.get('error_message');
                this.otpForm.get('pin')!.setValue(null);
                this.ngOtpInputRef.setValue(null);
              }
              this.googleAnalyticsService.customEventEmitter('Retailer Onboarding', 'submit', 'verify_failure');
              break;
            default:
              console.log(err);
              this.errMessage = 'An unexpected error occurred, please try again';
          }
        }
      );
  }

  onResendOTP(showPopUp: boolean = true): void {
    this.loading = true;
    this.errMessage = null;
    this.userService.requestNewOTP(this.context.currentUser.id).subscribe(
      () => {
        this.loading = false;
        // show success message
        if (showPopUp) {
          this.dialogService.open(IconPopupComponent, {
            height: '300px',
            width: '475px',
            data: {
              header: 'Resend OTP',
              description: 'We have sent a One Time Pin to',
              boldDescription: this.context.currentUser.email,
              imageSrc: '../../../../assets/icon-verification-dark.svg'
            },
          });
        }
        this.googleAnalyticsService.customEventEmitter('Retailer Onboarding', 'click', 'verify_resendOtp');
      },
      (err) => {
        this.loading = false;
        switch (err.status) {
          case 400:
            if ('ValidationException' === err.headers.get('error_code')) {
              this.errMessage = '' + err.headers.get('error_message');
              this.otpForm.get('pin')!.setValue(null);
              this.ngOtpInputRef.setValue(null);
            }
            break;
          default:
            this.dialogService.open(ErrorPopupComponent, {
              height: '300px',
              width: '450px',
              data: {
                header: 'Oops!',
                description: 'An unexpected error occurred',
              },
            });
        }
      }
    );
  }

  private resetForm(): FormGroup {
    return this.fb.group({
      pin: [null, Validators.required],
    });
  }
}
