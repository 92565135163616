import {
  defaultChartOptions
} from "../../dashboards/chart-options/chart-options.model";
import {ChartTypeRegistry, TooltipItem} from "chart.js";

export interface TurnoverCaptureData {
  uuid: string,
  leaseUuid: string,
  retailerId: number,
  turnoverMonth: string,
  turnoverYeah: number,
  billingPeriodUuid: string,
  turnoverPeriodStatus: TurnoverCaptureStatus,
  periodStartDate: string,
  periodEndDate: string,
  turnoverType: TurnoverType,
  turnoverAmount: number | null,
  updateUsername: string | null,
  updateDate: string | null,
  districtUuid: string,
  canEdit: boolean
}

export interface TurnoverCaptureMonth {
  turnoverMonth: string,
  totalCapturedAmount: number | null,
  monthComplete: boolean,
  leaseUuid: string,
  turnoverFormItems: TurnoverCaptureData[]
}

export enum TurnoverCaptureStatus {
  COMPLETE = 'COMPLETE',
  INCOMPLETE = 'INCOMPLETE'
}

export enum TurnoverType {
  TURNOVER = 'TURNOVER',
  POS = 'POS'
}
