<div class="page-padding page-content full-screen-height-page-default" fxLayout="column">

  <div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <span class="subtitle-default">Leasing Schedule</span>
      <div *ngIf="dataSource && dataSource.totalElements == 0">
        <app-info-panel [type]="'info'" [message]="'You don\'t have a leasing history at the moment'"></app-info-panel>
      </div>
    </div>
  </div>

  <div class="table-card mat-elevation-z3" fxLayout="column" fxFlexOffset="30px" *ngIf="dataSource && dataSource.totalElements > 0">
    <span class="heading-default"> My lease schedule {{currentContext.getCurrentOffering() ? 'of ' + currentContext.getCurrentOffering()?.offeringName : ''}} </span>

    <table mat-table class="custom-table" [dataSource]="dataSource" fxFlexOffset="25px">
      <ng-container matColumnDef="table-label">
        <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="displayedColumns.length"> Leases </th>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID </th>
        <td mat-cell class="custom-table-cell btn-cursor"
            matTooltip="Copy to clipboard" [cdkCopyToClipboard]="element.uuid"
            *matCellDef="let element"> {{utils.displayUuid(element.uuid)}} </td>
      </ng-container>

      <ng-container matColumnDef="companyName">
        <th mat-header-cell *matHeaderCellDef> Company Name </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayString(element.retailerCompanyName, 20)}} </td>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef> Start Date </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{dateUtils.displayShortDate(element.leaseStartDate)}} </td>
      </ng-container>

      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef> End Date </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{dateUtils.displayShortDate(element.leaseEndDate)}} </td>
      </ng-container>

      <ng-container matColumnDef="spaceName">
        <th mat-header-cell *matHeaderCellDef> Spaces </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{utils.displayString(element.spaceNames, 20)}} </td>
      </ng-container>

      <ng-container matColumnDef="size">
        <th mat-header-cell *matHeaderCellDef> Size </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{element.totalSize}} </td>
      </ng-container>

      <ng-container matColumnDef="rentalType">
        <th mat-header-cell *matHeaderCellDef> Rental Type </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{element.rentalType ? displayRentalType(element.rentalType): null}} </td>
      </ng-container>

      <ng-container matColumnDef="monthlyRentalFee">
        <th mat-header-cell *matHeaderCellDef> Monthly Rental Fee </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{element.monthlyPricePerSqm ? 'R ' + element.monthlyPricePerSqm.toFixed(2) + ' /m²' : null}} </td>
      </ng-container>

      <ng-container matColumnDef="expiresIn">
        <th mat-header-cell *matHeaderCellDef> Days Until Expiration </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{dateUtils.displayDateDurationDays(element.leaseEndDate)}} </td>
      </ng-container>

      <ng-container matColumnDef="leaseStatus">
        <th mat-header-cell *matHeaderCellDef> Current Lease Status </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element">
          <div [class]="status.className" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" *ngIf="getLeaseStatus(element) as status">
            <mat-icon [class.material-icons-outlined]="status.outline">{{status.icon}}</mat-icon>
            <span class="footer-default">{{status.status}}</span>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator #paginator class="custom-paginator"
                   [length]="dataSource.totalElements"
                   [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>

</div>
