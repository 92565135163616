import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {LeaseDocument, LeaseGridSpace} from "../../leasing.model";
import {SelectionModel} from "@angular/cdk/collections";
import {UtilsService} from "../../../../shared/services/utils.service";
import {PaginatorService} from "../../../../shared/services/paginator.service";
import {DateUtilsService} from "../../../../shared/services/dateUtils.service";
import {LeasingService} from "../../leasing.service";

@Component({
  selector: 'app-lease-documents',
  templateUrl: './lease-documents.component.html',
  styleUrls: ['./lease-documents.component.scss']
})
export class LeaseDocumentsComponent implements OnInit, AfterViewInit{
  @Input() id!: string;
  @ViewChild('paginator') paginator!: MatPaginator;

  displayedColumns: string[] = ['fileName', 'category', 'versionNo', 'documentType'];
  dataSource!: MatTableDataSource<LeaseDocument>;
  selection = new SelectionModel<LeaseGridSpace>(true, []);

  constructor(public utils: UtilsService, public dateUtils: DateUtilsService, private paginatorService: PaginatorService, private leasingService: LeasingService) {
  }

  ngOnInit() {
    this.loadDocuments();
  }

  ngAfterViewInit() {
    this.paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
    }
  }

  loadDocuments() {
    this.leasingService.getCurrentLeaseDocuments().subscribe({
      next: value => this.dataSource = new MatTableDataSource<LeaseDocument>(value),
      error: err => console.log(err)
    })
  }
}
