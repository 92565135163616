<div fxLayout="column">
  <app-page-header [banner]="false"
                   [section]="'Users and Access'"
                   [title]="'Grant your users access and permissions'"
                   [description]="'This is your settings page where you can add different roles and set permissions for your team members specific to your business operations.'">
  </app-page-header>

  <div class="page-content page-padding full-screen-height-page-default" fxLayout="column">
    <div fxLayout="row" fxLayoutGap="20px" fxFlexOffset="10px">
      <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
        <div><span class="subtitle-default">Roles and permissions</span></div>
        <div fxLayoutGap="10px" fxFlexOffset="20px">
          <button (click)="addUserToRoles()" class="button-action" mat-flat-button  [disabled]="loader.loading$ | async"><div class="button-text">Add</div></button>
          <button (click)="revokeUserRole()" [disabled]="userTableSelection.isEmpty() || (loader.loading$ | async)" class="button-action" mat-flat-button><div class="button-text">Remove</div></button>
        </div>
      </div>
      <div class="info-panel p-2 pe-3" fxLayout="column"
           fxLayoutAlign="start center" fxLayoutGap="10px" *ngIf="revokingUserFromRole">
        <div fxLayout="row" fxLayoutGap="20px">
          <mat-icon [class]="getPanelType().icon">error</mat-icon>
          <span class="info-panel-text">Do you want to remove the selected user(s) from their role</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="start end">
          <button class="button-action" mat-flat-button (click)="confirmRevoke()" [disabled]="loader.loading$ | async">
            <div class="button-text">Yes</div>
          </button>
          <button class="button-action" mat-flat-button (click)="cancelRevoke()" [disabled]="loader.loading$ | async">
            <div class="button-text">No</div>
          </button>
        </div>
      </div>
    </div>

    <div class="table-card mat-elevation-z3" fxLayout="column" fxFlexOffset="30px">

      <span class="heading-default">Manage user roles and permissions</span>

      <!--    Edit row-->
      <div *ngIf="addingUserToRole === true" class="edit-table-row" fxFlexOffset="20px" fxLayout="row"
           fxLayoutAlign="space-between center" fxLayoutGap="15px">
        <div [formGroup]="addUserToRoleForm" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
          <div class="body-1-primary">NEW_ID</div>
          <mat-form-field appearance="outline" class="custom-select-field" floatLabel="always">
            <mat-label>Roles</mat-label>
            <mat-select class="custom-select-field-section" formControlName="roleCodes" multiple>
              <div *ngFor="let role of availableRoles">
                <mat-option [value]="role.code" class="custom-select-field-option">{{role.name}}</mat-option>
              </div>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="custom-text-field ms-3" floatLabel="always">
            <mat-label>First Name</mat-label>
            <input formControlName="firstName" matInput placeholder="Input user first name" type="text"/>
          </mat-form-field>

          <mat-form-field appearance="outline" class="custom-text-field ms-3" floatLabel="always">
            <mat-label>Last Name</mat-label>
            <input formControlName="lastName" matInput placeholder="Input user last name" type="text"/>
          </mat-form-field>

          <mat-form-field appearance="outline" class="custom-text-field ms-3" floatLabel="always">
            <mat-label>User</mat-label>
            <input formControlName="email" matInput placeholder="Input user email address" type="text"/>
          </mat-form-field>
        </div>

        <div class="edit-row-button" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
          <button class="button-primary" mat-flat-button [disabled]="loader.loading$ | async">
            <mat-icon>check</mat-icon>
            <div (click)="saveAddUserToRole()" class="button-text">Save</div>
          </button>

          <button (click)="addingUserToRole = false" class="button-close-top" mat-icon-button [disabled]="loader.loading$ | async">
            <mat-icon>clear</mat-icon>
          </button>
        </div>

      </div>
      <!--Filters-->
      <!--    <form (ngSubmit)="filterResults()" *ngIf="addingUserToRole === false" [formGroup]="filterForm"
                style="margin-top: 10px">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="50px" fxFlexOffset="20px">
              <mat-form-field class="custom-text-field ms-3" appearance="outline" floatLabel="always">
                <mat-label>Module</mat-label>
                <mat-select class="custom-select-field-value" formControlName="moduleCode" multiple>
                  <div *ngFor="let module of availableModules">
                    <mat-option [value]="module.code" class="custom-select-field-option">{{module.name}}</mat-option>
                  </div>
                </mat-select>
              </mat-form-field>
              <button class="button-primary" mat-flat-button type="submit">
                <div class="button-text">Search</div>
              </button>
            </div>
          </form>

          -->
      <!--    Commented out because we only have role groups-->
      <!--      Table start-->

      <div fxFlexOffset="25px" fxLayout="row">
        <table [dataSource]="grantedModuleDataSource" class="custom-table half-table" fxFlex="50" mat-table>
          <ng-container matColumnDef="table-label">
            <th *matHeaderCellDef [attr.colspan]="grantedRolesDisplayedColumns.length" class="custom-table-label"
                mat-header-cell>
              Permissions
            </th>
          </ng-container>

          <ng-container matColumnDef="id">
            <th *matHeaderCellDef mat-header-cell> ID</th>
            <td *matCellDef="let element" class="custom-table-cell btn-cursor" mat-cell> {{element.roleId}} </td>
          </ng-container>

          <ng-container matColumnDef="module">
            <th *matHeaderCellDef mat-header-cell>Module</th>
            <td *matCellDef="let element" class="custom-table-cell" mat-cell>{{element.moduleName}}</td>
          </ng-container>

          <ng-container matColumnDef="role">
            <th *matHeaderCellDef mat-header-cell> Role</th>
            <td *matCellDef="let element" class="custom-table-cell" mat-cell> {{element.roleName}} </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th *matHeaderCellDef mat-header-cell> Description</th>
            <td *matCellDef="let element" class="custom-table-cell"
                mat-cell> {{element.roleDescription ? utils.displayString(element.roleDescription, 20) : null}} </td>
          </ng-container>

          <ng-container matColumnDef="select">
            <th *matHeaderCellDef mat-header-cell></th>
            <td *matCellDef="let row" class="custom-table-cell" mat-cell>
              <mat-checkbox (change)="$event ? grantedModuleTableSelection.toggle(row) : null" (click)="setSelectedRoleUsers(row)"
                            [checked]="grantedModuleTableSelection.isSelected(row)"
                            color="primary">
              </mat-checkbox>
            </td>
          </ng-container>

          <tr *matHeaderRowDef="['table-label']" class="custom-table-header" mat-header-row></tr>
          <tr *matHeaderRowDef="grantedRolesDisplayedColumns" class="custom-table-header" mat-header-row></tr>
          <tr (click)="setSelectedRoleUsers(row)" *matRowDef="let row; columns: grantedRolesDisplayedColumns;" [class.selected-row]="selectedModule === row.roleId"
              class="custom-table-row btn-cursor"
              mat-row></tr>
        </table>

        <table [dataSource]="userDataSource" class="custom-table half-table" fxFlex="50" mat-table>
          <ng-container matColumnDef="table-label">
            <th *matHeaderCellDef [attr.colspan]="userDisplayedColumns.length" class="custom-table-label"
                mat-header-cell></th>
          </ng-container>

          <ng-container matColumnDef="select">
            <th *matHeaderCellDef mat-header-cell></th>
            <td *matCellDef="let row" class="custom-table-cell" mat-cell>
              <mat-checkbox (change)="$event ? userTableSelection.toggle(row) : null" (click)="$event.stopPropagation()" *ngIf="row.userId"
                            [checked]="userTableSelection.isSelected(row)"
                            color="primary">
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="userId">
            <th *matHeaderCellDef mat-header-cell> User Id</th>
            <td *matCellDef="let element" class="custom-table-cell btn-cursor" mat-cell> {{element.userId}} </td>
          </ng-container>

          <ng-container matColumnDef="userFirstname">
            <th *matHeaderCellDef mat-header-cell> Name</th>
            <td *matCellDef="let element" [cdkCopyToClipboard]="element.userFirstname"
                class="custom-table-cell btn-cursor" mat-cell
                matTooltip="Copy to clipboard"> {{element.userFirstname ? utils.displayString(element.userFirstname, 30) : null}} </td>
          </ng-container>

          <ng-container matColumnDef="userLastname">
            <th *matHeaderCellDef mat-header-cell> Surname</th>
            <td *matCellDef="let element" [cdkCopyToClipboard]="element.userLastname"
                class="custom-table-cell btn-cursor" mat-cell
                matTooltip="Copy to clipboard"> {{element.userLastname ? utils.displayString(element.userLastname, 30) : null}} </td>
          </ng-container>

          <ng-container matColumnDef="userEmail">
            <th *matHeaderCellDef mat-header-cell> User Email</th>
            <td *matCellDef="let element" [cdkCopyToClipboard]="element.userEmail"
                class="custom-table-cell btn-cursor" mat-cell
                matTooltip="Copy to clipboard"> {{element.userEmail ? utils.displayString(element.userEmail, 30) : null}} </td>
          </ng-container>

          <tr *matHeaderRowDef="['table-label']" class="custom-table-header" mat-header-row></tr>
          <tr *matHeaderRowDef="userDisplayedColumns" class="custom-table-header" mat-header-row></tr>
          <tr *matRowDef="let row; columns: userDisplayedColumns;" class="custom-table-row" mat-row></tr>
        </table>
      </div>

      <mat-paginator #paginator [length]="this.totalElements"
                     [pageSizeOptions]="[5, 10, 25, 100]"
                     [pageSize]="10"
                     class="custom-paginator"
                     (page)="pageChangeEvent($event)"></mat-paginator>
    </div>
  </div>

</div>
