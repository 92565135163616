import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {Contact} from "./contacts.model";
import {CurrentContextService} from "../../../core/services/security/current-context.service";

@Injectable({
  providedIn: 'root'
})
export class CustomerSupportService {
  constructor(private http: HttpClient, private currentContext: CurrentContextService) {
  }
  getSupportContacts(): Observable<Contact[]> {
    return this.http.get<Contact[]>(this.baseUrl);
  }

  get baseUrl(): string {
    const landlordId = this.currentContext.getCurrentOffering()?.landlordUuid;
    return environment.apiLandlordHost + `/portal/landlord/${landlordId}/contacts/support`;
  }
}
