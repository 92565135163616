<div fxLayout="column">

  <app-page-header [banner]="false" [section]="'Lease'" [title]="'View your lease information'" [description]="'This is your lease summarising the terms of your agreement. You can also view all the associated legal documents.'"></app-page-header>

  <app-lease-details [leaseSummary]="leaseSummary"></app-lease-details>

  <div class="full-width" fxLayout="column" *ngIf="leaseSummary">

    <div class="section-divider"><mat-divider></mat-divider></div>

    <app-lease-rates-and-charges [id]="leaseSummary.uuid"></app-lease-rates-and-charges>

    <div class="section-divider"><mat-divider></mat-divider></div>

    <app-lease-spaces [id]="leaseSummary.uuid"></app-lease-spaces>

    <div class="section-divider"><mat-divider></mat-divider></div>

    <app-lease-documents [id]="leaseSummary.uuid"></app-lease-documents>

  </div>

</div>
