<div class="full-width page-padding page-content" fxLayout="column">
  <div fxLayout="row" fxFlexOffset="20px">
    <span class="subtitle-default">Rates and Charges</span>
  </div>

  <div class="full-width" fxLayout="row" fxLayoutGap="20px" fxFlexOffset="30px" *ngIf="transactionSource">
    <div class="info-card" fxLayout="column">

      <span class="heading-default">Lease Rates</span>

      <div fxLayout="column" fxLayoutGap="30px" fxFlexOffset="20px">
        <div fxLayout="row" fxLayoutGap="15px">
            <mat-form-field class="custom-text-autofill" appearance="outline" floatLabel="always">
              <mat-label>Rate Type</mat-label>
              <input matInput type="text" [value]="displayRentalType(transactionSource.rentalPricingOption)" disabled>
            </mat-form-field>

            <mat-form-field class="custom-text-autofill" appearance="outline" floatLabel="always">
              <mat-label>Rate per m<sup>2</sup></mat-label>
              <input matInput type="text" [value]="transactionSource.rentalPricingPerSqmValue ? ('R ' + transactionSource.rentalPricingPerSqmValue + ' / m²') : '-'" disabled>
            </mat-form-field>

            <mat-form-field class="custom-text-autofill" appearance="outline" floatLabel="always" *ngIf="transactionSource.rentalPricingOption === 'TURNOVER_PERCENTAGE'">
              <mat-label>% Rate</mat-label>
              <input matInput type="number" [value]="transactionSource.rentalPricingTurnoverPercentageValue ? (transactionSource.rentalPricingTurnoverPercentageValue + ' %') : '-'" disabled>
            </mat-form-field>
        </div>
      </div>

      <div fxLayout="column" fxFlexOffset="30px" fxLayoutAlign="start end" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="space-between cneter" class="full-width">
          <span class="heading-default">Transaction Fee Charges</span>
        </div>


        <mat-form-field class="custom-text-autofill" appearance="outline" floatLabel="always">
          <mat-label>Apply Transaction Fees</mat-label>
          <input matInput disabled>
          <span matPrefix class="body-1-regular-1 ms-3">{{transactionSource.applyTransactionFee ? 'Yes' : 'No'}}</span>
          <div matSuffix class="me-3" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
            <mat-slide-toggle color="primary" [checked]="transactionSource.applyTransactionFee"></mat-slide-toggle>
          </div>
        </mat-form-field>
      </div>



      <div fxLayout="column" fxLayoutGap="10px" fxFlexOffset="20px">
        <div fxLayout="row">
          <div class="body-1-default" fxFlex="25">Payment Method</div>
          <div class="body-1-default" fxFlex="25">Rate Type</div>
          <div class="body-1-default" fxFlex="25">Rate</div>
        </div>

        <mat-divider></mat-divider>

        <div fxLayout="column" *ngFor="let method of paymentMethods">
          <div fxLayout="row">
            <div class="body-1-regular-2" fxFlex="25">{{utils.displayStatus(method.collectionType)}} - {{method.name}}</div>
            <div class="body-1-regular-2" fxFlex="25">{{displayFeeType(method.transactionFeeType)}}</div>
            <div class="body-1-regular-2" fxFlex="25" *ngIf="method.transactionFeeType === 'FIXED'">R {{method.transactionFee ? method.transactionFee.toFixed(2) : null}}</div>
            <div class="body-1-regular-2" fxFlex="25" *ngIf="method.transactionFeeType === 'RATE'">{{method.transactionFee}} %</div>
          </div>

          <mat-divider fxFlexOffset="10px"></mat-divider>
        </div>



      </div>

    </div>
  </div>

</div>
