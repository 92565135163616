import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {RegisterUserRequest} from "../../../core/model/user.model";
import {
  OfferingApplicationFormComponent
} from '../application-form/offering-application-form/offering-application-form.component';
import {LoadingService} from "../../../core/services/loading.service";
import {OfferingApplicationService} from "../../../core/services/offering-application.service";
import {FormState} from "../application-form/offering-application.model";
import {
  ApplicationFormLoginComponent
} from "../application-form-auth/application-form-login/application-form-login.component";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {lastValueFrom, map, Observable} from 'rxjs';
import {StepperOrientation} from '@angular/cdk/stepper';
import {BreakpointObserver} from '@angular/cdk/layout';
import {
  ApplicationSignupUserComponent
} from 'src/app/features/applications/application-form-auth/application-signup-user/application-signup-user.component';
import {
  ApplicationSignupRetailerComponent
} from 'src/app/features/applications/application-form-auth/application-signup-retailer/application-signup-retailer.component';
import {MatStepper} from '@angular/material/stepper';
import {Retailer} from "../../../core/model/retailer.model";
import {AuthenticationService} from "../../../core/services/security/authentication.service";
import {UserService} from "../../../core/services/user.service";
import {GoogleAnalyticsService} from "../../../core/services/google-analytics.service";
import {HttpErrorResponse, HttpResponse} from "@angular/common/http";
import {RetailerService} from "../../../core/services/retailer.service";
import {CurrentContextService} from "../../../core/services/security/current-context.service";
import {FOOTER_TEXT_STYLE} from "../../../core/components/footer/footer-links/footer-links.component";
import {LoginSteps} from '../../../core/model/user-access.model';

@Component({
  selector: 'app-new-application-page',
  templateUrl: './new-application-page.component.html',
  styleUrls: ['./new-application-page.component.scss']
})
export class NewApplicationPageComponent implements AfterViewInit {
  @ViewChild("pageStepper") pageStepper!: MatStepper;
  @ViewChild("signupUserComponent") signupUserComponent!: ApplicationSignupUserComponent;
  @ViewChild("loginComponent") loginComponent!: ApplicationFormLoginComponent;
  @ViewChild("signupRetailerComponent") signupRetailerComponent!: ApplicationSignupRetailerComponent;
  @ViewChild("offeringApplicationForm") offeringApplicationForm!: OfferingApplicationFormComponent;

  protected steps: {
    label: string,
    editable: boolean,
    optional: boolean,
    complete: boolean,
    nextStepIndex: number,
    previousStepIndex: number,
    hasError: boolean
  }[] = [
    {
      label: 'User',
      editable: true,
      optional: false,
      complete: false,
      nextStepIndex: 1,
      previousStepIndex: 0,
      hasError: false
    },
    {
      label: 'Retailer',
      editable: true,
      optional: false,
      complete: false,
      nextStepIndex: 2,
      previousStepIndex: 0,
      hasError: false
    },
    {
      label: 'Application',
      editable: true,
      optional: false,
      complete: false,
      nextStepIndex: 3,
      previousStepIndex: 1,
      hasError: false
    },
    {
      label: 'Submit',
      editable: false,
      optional: false,
      complete: false,
      nextStepIndex: 3,
      previousStepIndex: 2,
      hasError: false
    }
  ]

  stepperOrientation: Observable<StepperOrientation>;

  displayMessage: any;

  signup: boolean = true;
  isLoggedIn: boolean = false;
  retailerCreated: boolean = false;
  locationCode: string | null = null;
  offeringCode: string | null = null;

  constructor(public loader: LoadingService,
              public offeringApplicationService: OfferingApplicationService,
              public router: Router,
              private authService: AuthenticationService,
              private userService: UserService,
              private retailerService: RetailerService,
              private googleAnalyticsService: GoogleAnalyticsService,
              private contextService: CurrentContextService,
              private activatedRoute: ActivatedRoute,
              private _snackBar: MatSnackBar,
              breakpointObserver: BreakpointObserver) {
    this.offeringApplicationService.toggleFormReadOnly(false);

    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({matches}) => (matches ? 'horizontal' : 'vertical')));
  }

  ngAfterViewInit() {
    this.locationCode = this.activatedRoute.snapshot.paramMap.get('locationCode') as string || this.activatedRoute.snapshot.queryParamMap.get('locationCode') as string;
    this.offeringCode = this.activatedRoute.snapshot.paramMap.get('offeringCode') as string;
    if (this.offeringApplicationService.getFormState == FormState.REGISTER) {
      this.nextStep(0, 2);
      this.steps[0].editable = false;
      this.steps[0].complete = true;
      this.steps[1].editable = false;
      this.steps[1].complete = true;

      this.isLoggedIn = true;
      this.retailerCreated = true;
    }
  }

  public step1Next(): void {
    if(this.signup) {
      if(this.signupUserComponent.canSubmitUser()) {
        this.steps[0].complete = true;
        this.steps[1].editable = true;
        this.nextStep(0, 1);
      }
    }
    else{
      if(this.loginComponent.formValid) {
        this.loginUser()
          .then((loggedIn) => {
            if (loggedIn instanceof Boolean && !loggedIn) {
              this.steps[0].complete = false;
            }

            let loginSteps: LoginSteps = loggedIn as LoginSteps;
            if (loginSteps.isTokenSet && loginSteps.isUserSet && loginSteps.isUserFound) {
              this.steps[0].complete = true;
            }
            if (loginSteps.isRetailerSet) {
              this.steps[1].complete = true;
              this.steps[1].editable = false;
              this.nextStep(0, 2);
            } else {
              this.nextStep(0, 1);
            }
          });
      }
    }
  }

  //check if can go to retailer step 1, or login step 0,
  // effectively, do the inverse of step1Next()
  public step2Back(): void {
    if(this.signup) {
      this.pageStepper.previous();
      /* todo
          need to add an onchange listener so that when the user changes the form,
            the stepper state is updated to complete = false
              //this.stepperStates[0].complete = false;
              //this.stepperStates[1].editable = false;
      */
     return;
    }

    this.nextStep(null, 0);
    //needs to retain state of step 0, currently showing the signup form inspipe of login form
  }

  public signUpRetailerNext(): void {
    if (!this.signup && this.canSubmit()) {
      this.retailerSubmit()
        .then((isSuccessful: boolean) => {
          if (isSuccessful) {
            this.nextStep(1, 2);
          }
        });
    } else {
      this.nextStep(1, 2);
    }
  }


  triggerSubmit(): void {
    if (this.isLoggedIn && this.retailerCreated) {
      console.log('trying to submit and logged in')
      this.submitApplicationForm()
        .then(formSubmitted => {
          if (formSubmitted) {
            this.completeSubmission()
          } else {
            this.goToErrorStep();
          }
        });
    } else {
      if (this.signup) {
        console.log('trying to submit and signing up')
        this.userSubmit()
          .then(userSubmitted => {
            if (userSubmitted) {
              this.steps[0].editable = false;
              return this.retailerSubmit();
            }
            this.goToErrorStep();
            return Promise.resolve(false);
          })
          .then(retailerSubmitted => {
            if (retailerSubmitted) {
              this.steps[1].editable = false;
              return this.submitApplicationForm();
            }
            this.goToErrorStep();
            return Promise.resolve(false);
          })
          .then(formSubmitted => {
            this.steps[2].editable = false;
            this.steps[2].complete = true;
            if (formSubmitted) {
              this.completeSubmission()
            } else {
              this.goToErrorStep();
            }
          })
      } else {
        console.log('trying to submit and logged out')
        this.loginUser()
          .then(loggedIn => {
            if (loggedIn instanceof Boolean && !loggedIn) {
              this.goToErrorStep();
              return Promise.resolve(false);
            } else {
              let loggedInSteps = loggedIn as LoginSteps;
              if (loggedInSteps.isUserFound && loggedInSteps.isRetailerSet) {
                this.steps[0].editable = false;
                return this.submitApplicationForm()
              }
            }

            this.goToErrorStep();
            return Promise.resolve(false);
          })
          .then(formSubmitted => {
            this.steps[2].editable = false;
            this.steps[2].complete = true;
            if (formSubmitted) {
              this.completeSubmission();
            } else {
              this.goToErrorStep();
            }
          });
      }
    }
  }

  completeSubmission() {
    this.steps[2].complete = true;
    this.steps[2].hasError = false;
    this.nextStep(2, 3);
    console.log('Form submitted!');
  }

  // triggerCancel(): void {
  //   this.offeringApplicationForm.cancelCurrentProcess();
  // }

  toggleSignup(signup: boolean) {
    this.signup = signup;
  }

  nextStep(currentStep: number | null, nextStep: number) {
    if (currentStep) this.steps[currentStep].complete = true;
    setTimeout(() => this.pageStepper.selectedIndex = nextStep, 300);
  }

  assessmentStatusChange($event: string): void {
    switch ($event.toLowerCase()) {
      case 'new': {
        this.displayMessage = 'You are creating a new application';
        break;
      }
      case 'pending': {
        this.displayMessage = 'Your application is pending curation. You may update it if need be.';
        break;
      }
      default : {
        this.displayMessage = "Submit or update your application";
      }
    }
  }

  showInfoPanel(): boolean {
    return !this.offeringApplicationService.isReadonly
      && this.offeringApplicationService.getFormState == FormState.EMBED
      && this.offeringApplicationService.getProcessStep == 3;
  }

  openPortalInNewTab() {
    window.open(`${environment.apiPortal}/${this.locationCode}/login`, '_blank');
  }

  get isFormPublic(): boolean {
    return this.offeringApplicationService.getFormState == FormState.REFERRER
      || this.offeringApplicationService.getFormState == FormState.EMBED;
  }

  routeUserAfterSubmit() {
    switch (this.offeringApplicationService.getFormState) {
      case FormState.EMBED:
        this.openPortalInNewTab();
        break;
      case FormState.REFERRER:
        this.contextService.sendClearCurrentOfferingEvent();
        this.router.navigate([`${this.locationCode}/home`])
          .catch((error: string) => {
            console.log(error);
          });
        break;
      case FormState.REGISTER:
        this.contextService.sendClearCurrentOfferingEvent();
        this.router.navigate([`${this.locationCode}/home`])
          .catch((error: string) => {
            console.log(error);
          });
        break;
      case FormState.IN_APP:
        this.contextService.sendClearCurrentOfferingEvent();
        break;
    }
  }

  loginUser() {
    let value = this.loginComponent.loginForm.value;

    return this.authService.doAppFormLogin({email: value.email, secret: value.password})
      .then((loginSteps: LoginSteps) => {
          if (loginSteps.isTokenSet && loginSteps.isUserSet && loginSteps.isUserFound) {
            this.steps[0].hasError = false;
            this.isLoggedIn = true;
          } else {
            this.displayMessage = 'Could not authenticate user details';
            this.steps[0].hasError = true;
            return Promise.resolve(loginSteps);
          }

          if (!loginSteps.isRetailerSet) {
            this.displayMessage = 'Could not associate user with a company';
            this.steps[0].hasError = false;
            this.steps[1].hasError = true;
            this.steps[1].complete = false;
            return Promise.resolve(loginSteps);
          }
          return Promise.resolve(loginSteps);
        },
        (error: HttpErrorResponse) => {
          console.log(error)
          console.log(error.headers.get('error_message'));
          this.steps[0].hasError = true;
          // login failed -> reset password and show error
          this.loginComponent.loginForm.get('password')!.setValue(null);
          this.loginComponent.loginForm.get('password')!.setErrors({'login-failed': true});
          this.displayMessage = 'Could not authenticate user details';
          this.openSnackBar('Username or password is incorrect');
          return Promise.resolve(false);
        }
      );
  }

  userSubmit() {
    if (this.signupUserComponent.passwordInvalid(true)) {
      return Promise.resolve(false);
    }

    if (this.isLoggedIn) return Promise.resolve(true);

    const user: RegisterUserRequest = { user: this.signupUserComponent.userRegistrationForm.value };

    return lastValueFrom(this.userService.registerNewUserWithApplication(user))
      .then((res: HttpResponse<Object>) => {
          this.googleAnalyticsService.customEventEmitter('Retailer Onboarding', 'submit', 'hello_signup_submit');
          this.authService.setToken(res);
          this.isLoggedIn = true;
          this.steps[0].hasError = false;
          return Promise.resolve(true);
        },
        (err) => {
          console.log(err);
          console.log(err.headers.get('error_message'));
          this.steps[0].hasError = true;
          this.displayMessage = 'Could not create new user';
          if (err.status == 400 && 'ValidationException' === err.headers.get('error_code')) {
            this.signupUserComponent.userRegistrationForm.get('email')?.setErrors({'email-exists': true});
            this.openSnackBar("A user with this email already exists");
          } else {
            this.openSnackBar(this.displayMessage);
          }
          return Promise.resolve(false);
        }
      )
  }

  retailerSubmit() {
    const retailer = this.signupRetailerComponent.retailerReigstrationForm.value;
    if (this.signupRetailerComponent.website) {
      retailer.websiteUrl = null;
    }

    return lastValueFrom(this.retailerService.registerNewRetailer(retailer))
      .then((res: Retailer) => {
          res.retailerNew = true;
          this.contextService.setCurrentRetailerInContext(res);
          this.googleAnalyticsService.customEventEmitter('Retailer Onboarding', 'submit', 'retailer_register_submit');
          this.retailerCreated = true;
          this.steps[1].hasError = false;
          return Promise.resolve(true);
        },
        err => {
          console.log(err);
          console.log(err.headers.get('error_message'));
          this.steps[1].hasError = true;
          this.displayMessage = 'Could not register new retailer';
          if (err.status == 400 && 'ValidationException' === err.headers.get('error_code')) {
            this.signupRetailerComponent.retailerReigstrationForm.get('companyName')?.setErrors({'name-exists': true});
            this.openSnackBar("A retailer with this name or website already exists");
          } else {
            this.openSnackBar(this.displayMessage);
          }
          this.retailerCreated = false;
          return Promise.resolve(false);
        }
      );
  }

  submitApplicationForm() {
    return this.offeringApplicationForm.submitRetailerOfferingApplication();
  }

  submitError(err: string) {
    this.steps[2].hasError = true;
    this.openSnackBar(err);
  }

  canSubmit(): boolean {
    if (this.pageStepper) {
      switch (this.pageStepper.selectedIndex) {
        case 0: {
          if (this.signup && this.signupUserComponent) {
            return this.signupUserComponent.canSubmitUser();
          } else {
            return this.loginComponent && this.loginComponent.formValid;
          }
        }
        case 1: {
          return this.signupRetailerComponent.canSubmitRetailer();
        }
        case 2: {
          return this.offeringApplicationForm.checkFormValidity(true);
        }
      }
    }
    return false;
  }

  canTriggerSubmit(): boolean {
    if ((!this.signupUserComponent && !this.loginComponent) || !this.signupRetailerComponent || !this.offeringApplicationForm) return false;
    if (this.isLoggedIn && this.retailerCreated) {
      return this.offeringApplicationForm.checkFormValidity(true);
    } else {
      if (this.signup && this.signupUserComponent) {
        return this.signupUserComponent.canSubmitUser()
          && this.signupRetailerComponent.canSubmitRetailer()
          && this.offeringApplicationForm.checkFormValidity(true)
      } else {
        return this.loginComponent
          && this.loginComponent.formValid
          && this.offeringApplicationForm.checkFormValidity(true);
      }
    }
  }

  goToErrorStep() {
    for (let [i, step] of this.steps.entries()) {
      if (step.hasError) {
        step.complete = false;
        step.editable = true;
        this.pageStepper.selectedIndex = i;
        console.log('go to step ', i);
        break;
      }
    }
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '',
      { duration: 2000, verticalPosition: 'top', horizontalPosition: 'right' });
  }

  protected readonly FOOTER_TEXT_STYLE = FOOTER_TEXT_STYLE;
}
