import {Auditable} from "../../core/model/auditable.model";

export class LeaseSummary {
  uuid!: string;
  retailerId!: number;
  districtUuid?: string;
  leaseType?: string;
  leaseStatus!: string;
  districtName?: string;
  districtKey?: string;
  districtDescription?: string;
  spaceNames?: string;
  retailerCompanyName?: string;
  retailerWebsiteUrl?: string;
  retailerDescription?: string;
  leaseEndDate!: string;
  leaseStartDate!: string;
  totalSize!: number;
  termMonths?: number;
  monthlyPricePerSqm?: number | null;
  rentalType?: string | null;
  leaseLocation!: string | null;
  retailerIndustryTypes!: string | null;
  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export enum LeaseStatus {
  PLANNED = 'PLANNED',
  UNSIGNED = 'UNSIGNED',
  INVALID = 'INVALID',
  CANCELED = 'CANCELED',
  APPROVED = 'APPROVED',
  UPCOMING = 'UPCOMING',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  TERMINATED = 'TERMINATED'
}

export interface PaymentConfigData {
  uuid: string;
  leaseUuid: string;
  transactionSource: string;
  transactionSourceConfig: TransactionSourceConfig;
  applyTransactionFee: boolean;
  active: boolean;
  transactionFeeType: string;
  transactionFee: number;
  rentalPricingOption: string;
  rentalPricingTurnoverPercentageValue: number;
  rentalPricingPerSqmValue: number;
}

export interface TransactionSourceConfig {
  domainPrefix: string | null;
  outletId: string | null;
  paymentTypeName: string | null;
  accessToken: string | null;
  paymentSourceName: string | null;
}

export class LeaseGridSpace {
  uuid!: string;
  leaseUuid!: string;
  districtSpaceId!: number;
  leaseStartDate!: string;
  leaseEndDate!: string;
  availabilityStatus!: string;
  spaceSize!: number;
  spaceName!: string;
}


export class LeaseDocument extends Auditable {
  documentUuid!: string;
  documentTypeCode!: string;
  leaseUuid!: string;
  fileName!: string;
  mimeType!: string;
  docUrl!: string;
  versionNo!: number;
  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}

export class LeaseDocumentType {
  code!: string;
  name!: string;
  description!: string;
  active!: boolean;
}

export class PaymentMethod {
  uuid!: string;
  districtUuid!: string;
  transactionSource!: string;
  name!: string;
  collectionType!: string;
  transactionFeeType!: string;
  transactionFee!: number;
}

export interface DistrictSpace {
  id: number,
  districtUuid: string,
  spaceType: string,
  spaceName: string,
  spaceSize: string,
  industryTypeId: number,
  industryCategoryId: number,
  availabilityStatus: string
}
