<div fxLayout="column" class="view-container">

  <mat-sidenav-container class="sidenav-container body-1-regular-2">

    <!-- Right sidenav -->
    <mat-sidenav #rightSidenav mode="side" opened position="end" class="sidenav-right"
                 [fixedInViewport]="false" [fixedTopGap]="0"
                 [fixedBottomGap]="0">

      <div class="sidenav-right-content" fxLayout="column" fxLayoutGap="15px">
        <div class="info-card heading-card" fxLayout="row" fxLayoutAlign="strt center" fxLayoutGap="10px">
          <div class="square-icon primary-bg-40">
            <mat-icon >add_to_queue</mat-icon>
          </div>
          <span class="heading-bold">{{dateUtils.displayDateWithFormat(dateUtils.currentDate, 'MMMM YYYY')}}</span>
        </div>

        <div class="info-card" fxLayout="column" fxLayoutGap="25px">
          <span class="heading-regular">Monthly turnover</span>
          <div class="ps-2" fxLayout="column" fxLayoutGap="25px">
            <span class="heading-bold">This summarises the total sales made or turnover captured within the selected month.</span>
            <span class="body-1-regular-1">Total turnover</span>
          </div>

          <div fxLayout="column" fxLayoutGap="10px" *ngIf="selectedMonth">
            <div class="amount-container">
              <span>{{utils.displayRandAmountWithDecimal(selectedMonth.totalCapturedAmount, true)}}</span>
            </div>

            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px"
                 *ngIf="!selectedMonth.monthComplete">
              <button class="form-icon-button form-icon-button-primary" mat-icon-button>
                <mat-icon class="material-icons-outlined" svgIcon="exclamation"></mat-icon>
              </button>
              <span class="body-1-default">There is still info missing</span>
            </div>
          </div>
        </div>

        <div class="info-card" fxLayout="column" fxLayoutGap="30px">
          <span class="heading-regular">Statistics</span>

          <span class="body-1-regular-1" fxFlexAlign="center">Turnover for the last 3 months</span>
          <div>
            <canvas class="dashboard-canvas" baseChart
                    [height]="250"
                    [data]="turnoverCaptureData"
                    [options]="turnoverCaptureChartOptions"
                    [type]="'bar'"></canvas>
          </div>
        </div>
      </div>

    </mat-sidenav>

    <!-- Main content -->
    <mat-sidenav-content cdkScrollable class="sidenav-main-content hide-scroll-bar" fxLayout="column" fxLayoutGap="30px">
      <app-page-header [section]="'Turnover'"
                       [title]="'Submit your current turnover information'"
                       [description]="'This is your current turnover metrics summarising all the sales according to automatic integrate point-of-sale module or manual submissions.'">
      </app-page-header>

      <div class="page-padding" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">

        <div class="full-width" fxLayout="row" fxLayoutAlign="space-between start">
          <div class="subtitle-default">Turnover forms</div>
          <div fxLayout="row" fxLayoutGap="15px" *ngIf="editedItems.length > 0">
            <button class="button-outline-white" mat-flat-button style="width: 90px;" (click)="cancelChanges()">
              <span class="button-text">Cancel</span>
            </button>
            <button class="button-primary" mat-flat-button style="width: 90px;" (click)="saveTurnoverFormItems()">
              <span class="button-text">Save</span>
            </button>
          </div>
        </div>

        <div class="full-width form-container">
          <mat-accordion class="form-accordion" multi>
            <mat-expansion-panel *ngFor="let month of datasource; let i = index" hideToggle
                                 [class.month-selected]="isSelectedMonth(month.turnoverMonth)"
                                 (click)="selectedMonth = month"
                                 [expanded]="!month.monthComplete || i == 0">
              <mat-expansion-panel-header>
                <div class="full-width" fxLayout="row" fxLayoutAlign="space-between start">
                  <span class="heading-regular">{{month.turnoverMonth}}</span>

                  <button class="drawer-button" mat-icon-button>
                    <mat-icon class="panel-arrow" svgIcon="back-arrow-button"></mat-icon>
                  </button>
                </div>

              </mat-expansion-panel-header>

              <div>
                <table mat-table class="form-table" [dataSource]="month.turnoverFormItems">

                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef> Status </th>
                    <td mat-cell  class="custom-table-cell hover-cell" *matCellDef="let element">
                      <div class="form-hover-button" fxLayout="row" fxLayoutAlign="start center">
                        <button class="form-icon-button" [class.form-icon-button-primary]="element.turnoverPeriodStatus == TurnoverCaptureStatus.INCOMPLETE" mat-icon-button>
                          <mat-icon class="material-icons-outlined" [svgIcon]="element.turnoverPeriodStatus == TurnoverCaptureStatus.INCOMPLETE ? 'exclamation' : 'done'"></mat-icon>
                        </button>

                        <div class="form-button-tooltip form-button-tooltip-right" *ngIf="element.turnoverPeriodStatus == TurnoverCaptureStatus.INCOMPLETE">
                          <span class="body-1-default tooltip-text">Please enter your turnover.</span>
                        </div>
                      </div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="fromDate">
                    <th mat-header-cell *matHeaderCellDef> From </th>
                    <td mat-cell class="custom-table-cell" *matCellDef="let element">
                      {{ dateUtils.displayLongDate(element.periodStartDate) }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="toDate">
                    <th mat-header-cell *matHeaderCellDef> Until </th>
                    <td mat-cell class="custom-table-cell" *matCellDef="let element">
                      {{ dateUtils.displayLongDate(element.periodEndDate) }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="turnoverType">
                    <th mat-header-cell *matHeaderCellDef> Type </th>
                    <td mat-cell class="custom-table-cell" *matCellDef="let element">
                      {{ element.turnoverType == TurnoverType.TURNOVER ? utils.displayStatus(element.turnoverType) : element.turnoverType }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef> Amount </th>
                    <td mat-cell class="custom-table-cell" *matCellDef="let element">

                      <div *ngIf="element.turnoverPeriodStatus == TurnoverCaptureStatus.COMPLETE">
                        {{ utils.displayRandAmountWithDecimal(element.turnoverAmount) }}
                      </div>

                      <div *ngIf="element.turnoverPeriodStatus == TurnoverCaptureStatus.INCOMPLETE">
                        <mat-form-field class="custom-text-field" appearance="outline" floatLabel="always">
                          <div matPrefix class="ms-2 body-1-regular-1" *ngIf="element.turnoverAmount">R </div>
                          <input matInput placeholder="Enter amount" type="number" [(ngModel)]="element.turnoverAmount" (ngModelChange)="changeFormValue($event, element)">
                        </mat-form-field>
                      </div>

                    </td>
                  </ng-container>

                  <ng-container matColumnDef="capturedBy">
                    <th mat-header-cell *matHeaderCellDef> Captured by </th>
                    <td mat-cell class="custom-table-cell" *matCellDef="let element">
                      {{ element.updateUsername ? element.updateUsername : '-' }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="updateDate">
                    <th mat-header-cell *matHeaderCellDef> Last edited </th>
                    <td mat-cell class="custom-table-cell" *matCellDef="let element">
                      {{ element.updateDate ? dateUtils.displayDateWithTime(element.updateDate) : '-' }}
                    </td>
                  </ng-container>

                  <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </mat-sidenav-content>

  </mat-sidenav-container>

</div>
