import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {SocialMediaProfile} from './social-media-profile.model';
import {BaseService} from '../../../../core/services/base.service';
import {environment} from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RetailerSocialMediaProfileServiceService extends BaseService {

  baseUrl: string = environment.apiRetailersHost + '/portal/retailer/';

  constructor(private http: HttpClient) {
    super();
  }

  public getProfiles(retailerId: number): Observable<SocialMediaProfile[]> {
    const uri = this.baseUrl + `${retailerId}/social-connections`;
    return this.http.get<SocialMediaProfile[]>(uri, this.options);
  }

  public save(retailerId: number, request: SocialMediaProfile[]): Observable<SocialMediaProfile[]> {
    const uri = this.baseUrl + `${retailerId}/social-connections`;
    return this.http.post<SocialMediaProfile[]>(uri, request, this.options);
  }
}
