import {HttpHeaders} from '@angular/common/http';

export abstract class BaseService {

  options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

}
