import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {environment} from '../../../environments/environment';
import {User} from '../model/user.model';


@Injectable({
  providedIn: 'root'
})

export class ImageUploadService extends BaseService {

  baseUrl: string = environment.apiSecurityHost + '/portal/rbac/user/manage';

  constructor(private http: HttpClient) {
    super();
  }

  public save(image: any): Observable<User> {
    const formData = new FormData();
    formData.append('file', image);
    const uri = this.baseUrl + '/profile-image';
    return this.http.post<User>(uri, formData);
  }
}
