import {Component} from '@angular/core';

@Component({
  selector: 'app-leasing-history',
  templateUrl: './leasing-history.component.html',
  styleUrls: ['./leasing-history.component.scss']
})
export class LeasingHistoryComponent {

}
