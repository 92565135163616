import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {lastValueFrom, Observable} from 'rxjs';
import {RegisterUserRequest, User, UserUpdateRequest, VerifyOTPRequest} from '../model/user.model';
import {BaseService} from './base.service';
import {UserFeaturesResponseInterface} from '../model/user-features-response.interface';


@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  baseUrl: string = environment.apiSecurityHost + '/api/user';

  constructor(private http: HttpClient) {
    super();
  }

  // register
  public registerNewUser(request: RegisterUserRequest): Observable<User> {
    const uri = environment.apiSecurityHost + '/portal/rbac/user/register'
    return this.http.post<User>(uri, request, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        accept: '*/*',
        'Access-Control-Allow-Origin': 'http://localhost:4200',
      }),
    });
  }

  public registerNewUserWithApplication(request: RegisterUserRequest): Observable<any> {
    const uri = environment.apiSecurityHost + '/portal/rbac/user/register/apply'
    return this.http.post<any>(uri, request, {
      observe: 'response',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'http://localhost:4200',
      }),
    });
  }

  public verifyNewUserAccount(request: VerifyOTPRequest): Observable<any> {
    const uri = this.baseUrl + '/verify';
    return this.http.post(uri, request, {
      observe: 'response',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }

  public requestNewOTP(id: number): Observable<any> {
    const uri = this.baseUrl + '/' + id + '/verify/otp/resend';
    return this.http.post(uri, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }

  updateUserDetails(request: UserUpdateRequest): Observable<User> {
    let uri = environment.apiSecurityHost + '/portal/rbac/user/manage/user-info'
    return this.http.post<User>(uri, request, this.options);
  }

  getUserById(userId: number): Observable<User> {
    const uri = this.baseUrl + '/' + userId;
    return this.http.get<User>(uri, this.options);
  }


  getUserFeaturesAndGrants(): Promise<UserFeaturesResponseInterface> {
    const uri = environment.apiSecurityHost + '/api/rbac/user/offering/features';
    let observable$ = this.http.get<UserFeaturesResponseInterface>(uri, this.options);
    return lastValueFrom(observable$)
  }

}
