import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {CurrentContextService} from "../../../core/services/security/current-context.service";
import {GenericChart} from "../../../shared/model/generic-chart.interface";
import {BlockingQueueService} from "../../../shared/services/blocking-queue.service";
import {MonthlyRetailerStats, RetailerProductPerformance} from "../dashboards.model";

export class DashboardsRetailerReportService extends BlockingQueueService {
  private http: HttpClient

  constructor(http: HttpClient, private context: CurrentContextService) {
    super();
    this.http = http;
  }

  getBaseUrl(): string {
    const retailerId = this.context.currentRetailer.id;
    return environment.apiTransactHost + `/portal/offering/district/dashboard/retailer-report/${retailerId}`;
  }

  refreshBestMonthlyStats(callback: (data: any) => void, error: (data: any) => void): void {
    const task = () => this.http.get<MonthlyRetailerStats>(this.getBaseUrl() + '/best-stats');
    this.enqueue(task, callback, error);
  }

  refreshSalesPerformanceChart(fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void): void {
    this.refreshChart(fromDate, toDate, callback, error, 'sales-performance');
  }

  refreshRentalRateRentRatioChart(fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void): void {
    this.refreshChart(fromDate, toDate, callback, error, 'rent-performance');
  }

  refreshAvgBasketSizeChart(fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void): void {
    this.refreshChart(fromDate, toDate, callback, error, 'basket-size');
  }

  refreshBusiestSalesTimesChart(fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void): void {
    this.refreshChart(fromDate, toDate, callback, error, 'sales-times');
  }

  refreshBusiestSalesDaysChart(fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void): void {
    this.refreshChart(fromDate, toDate, callback, error, 'sales-days');
  }

  refreshBestSellers(fromDate: string, toDate: string, topPercent: number, callback: (data: any) => void, error: (data: any) => void): void {
    const requestParams = new HttpParams()
      .set('fromDate', fromDate)
      .set('toDate', toDate)
      .set('topPercentage', topPercent);
    const task = () => this.http.get<RetailerProductPerformance[]>(this.getBaseUrl() + '/best-sellers', {params: requestParams});
    this.enqueue(task, callback, error);
  }

  refreshInventoryForecast(fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void): void {
    const requestParams = new HttpParams()
      .set('fromDate', fromDate)
      .set('toDate', toDate);
    const task = () => this.http.get<RetailerProductPerformance[]>(this.getBaseUrl() + '/inventory-forecast', {params: requestParams});
    this.enqueue(task, callback, error);
  }

  refreshChart(fromDate: string, toDate: string, callback: (data: any) => void, error: (data: any) => void, urlSuffix: string): void {
    const requestParams = new HttpParams()
      .set('fromDate', fromDate)
      .set('toDate', toDate);
    const task = () => this.http.get<GenericChart>(this.getBaseUrl() + `/${urlSuffix}`, {params: requestParams});
    this.enqueue(task, callback, error);
  }
}
