import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UtilsService} from "../../services/utils.service";
import {
  ControlPayload,
  NumberType,
  Question,
  RangeOption
} from "../../../features/applications/application-form/offering-application.model";

@Component({
  selector: 'app-custom-range-selector',
  templateUrl: './custom-range-selector.component.html',
  styleUrls: ['./custom-range-selector.component.scss']
})
export class CustomRangeSelectorComponent implements OnInit, OnChanges {
  @Input() question!: Question;
  @Input() editConfig: boolean = false;
  @Input() readonly: boolean = false;
  @Input() required: boolean = false;
  @Output() rangeSelected = new EventEmitter<RangeOption>();

  selectedIncr!: RangeOption;

  constructor(public utils: UtilsService) {
  }

  ngOnInit() {
    if (this.editConfig) {
      this.updateNumberIncrements();
    }

    const selected = this.question.questionTemplate.controlPayload.increments!.filter(f => f.valueCode == this.question.value);

    if (this.question.value && selected.length > 0) {
      this.selectedIncr = selected[0];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.editConfig) {
      this.updateNumberIncrements();
    }
  }

  updateNumberIncrements(): void {
    const incrementSize = this.getQuestionControlPayload.increments![0].incrementSize;

    const start = Number(this.getQuestionControlPayload.start!);
    const end = Number(this.getQuestionControlPayload.end!);
    const range = end - start;
    const steps = Math.ceil(range / incrementSize);

    let array: RangeOption[] = [];

    if (steps > 15 || end >= start) {
      const endLabel = start + ' to ' + end;
      array = [
        new RangeOption({ label: start, valueCode: null, start: start, end: start, incrementSize: incrementSize }),
        new RangeOption({ label: endLabel, valueCode: null, start: start, end: end, incrementSize: incrementSize}),
      ];
    } else {
      for (let i = 0, l = steps; i <= l; i++) {
        const optionStart = (i > 0) ? (start + Math.min((i-1) * incrementSize, range)) : 0;
        const optionEnd = start + Math.min(i * incrementSize, range);
        const optionLabel = optionStart + ' to ' + optionEnd;

        const option = new RangeOption({
          label: optionLabel,
          valueCode: null,
          start: optionStart,
          end: optionEnd,
          incrementSize: incrementSize
        })
        array.push(option);
      }
    }

    this.getQuestionControlPayload.increments = array;

  }

  get getRangeOrder() {
    return this.getQuestionControlPayload.increments?.sort((a, b) => {
      if (a.start - b.start != 0) {
        return a.start - b.start;
      } else {
        return a.end - b.end
      }
    });
  }

  setSelectedIncr(incr: RangeOption, index: number) {
    if (index != 0 && !this.readonly) {
      this.selectedIncr = incr;
      this.rangeSelected.emit(incr);
    }
  }

  isLowerBound(incr: RangeOption) {
    const selectedIndex = this.getQuestionControlPayload.increments!.findIndex(f => f == this.selectedIncr);
    return incr == this.getQuestionControlPayload.increments![selectedIndex - 1];
  }

  get getQuestionControlPayload(): ControlPayload {
    return this.question.questionTemplate.controlPayload;
  }

  displayRangeLabel(incr: RangeOption): string {
    const start = this.utils.displayCompactNumber(incr.start);
    const end = this.utils.displayCompactNumber(incr.end);
    const defaultLabel = start + ' to ' + end;
    switch (this.getQuestionControlPayload.numberType) {
      case NumberType.NUMBER:
        return defaultLabel;
      case NumberType.PERCENTAGE:
        return start + '% to ' + end + '%';
      case NumberType.CURRENCY:
        return 'R' + start + ' to R' + end;
      default:
        return defaultLabel + ' ' + this.getQuestionControlPayload.numberType!.toLowerCase();
    }
  }
}
