<div class="page-padding page-content full-screen-height-page-default" fxLayout="column">

  <div>
    <div fxLayout="row">
      <span class="subtitle-default">Pay-out Summary</span>
    </div>
  </div>

  <div class="table-card mat-elevation-z3" fxLayout="column" fxFlexOffset="30px">
    <span class="heading-default">Pay-out summary for retailers</span>
    <form [formGroup]="filterForm" fxFlexOffset="20px">
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="50px">

        <mat-form-field class="custom-select-field" appearance="outline" floatLabel="always">
          <mat-label>Payout Status</mat-label>
          <mat-select class="custom-select-field-value" placeholder="Select" multiple formControlName="payoutStatus"
                      (selectionChange)="filterPayoutSummary($event)">
            <mat-option class="custom-select-field-option" *ngFor="let status of batchStatuses"
                        [value]="status">{{utils.displayStatus(status)}}</mat-option>
          </mat-select>
        </mat-form-field>

        <app-custom-date-filter [customDateRangePresets]="dateFilters" [showBorder]="false"
                                (dateRange)="getDateRange($event)"
                                [showClearButton]="true"
                                [initialPreset]="'1'"></app-custom-date-filter>

      </div>
    </form>

    <table mat-table class="custom-table" [dataSource]="dataSource" fxFlexOffset="25px" fxFlex="60">
      <ng-container matColumnDef="table-label">
        <th mat-header-cell class="custom-table-label" *matHeaderCellDef [attr.colspan]="displayedColumns.length"> Transaction </th>
      </ng-container>

      <ng-container matColumnDef="paymentBatchUuid">
        <th mat-header-cell *matHeaderCellDef> ID </th>
        <td mat-cell class="custom-table-cell btn-cursor"
            matTooltip="Copy to clipboard" [cdkCopyToClipboard]="element.paymentBatchUuid"
            *matCellDef="let element"> {{utils.displayUuid(element.paymentBatchUuid)}} </td>
      </ng-container>

      <ng-container matColumnDef="processedDate">
        <th mat-header-cell *matHeaderCellDef> Processed Date </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{dateUtils.displayShortDate(element.processedDate)}} </td>
      </ng-container>

      <ng-container matColumnDef="saleAmountInclTotal">
        <th mat-header-cell *matHeaderCellDef> Sale Amount </th>
        <td mat-cell class="custom-table-cell" *matCellDef="let element"> {{utils.displayRandAmount(element.saleAmountInclTotal)}} </td>
      </ng-container>

      <ng-container matColumnDef="transactionFeeInclTotal">
        <th mat-header-cell *matHeaderCellDef> Transaction Fee </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{utils.displayRandAmount(element.transactionFeeInclTotal)}} </td>
      </ng-container>

      <ng-container matColumnDef="turnoverRentalFeeInclTotal">
        <th mat-header-cell *matHeaderCellDef> Rental Fee </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{utils.displayRandAmount(element.turnoverRentalFeeInclTotal)}} </td>
      </ng-container>

      <ng-container matColumnDef="rollupNetBalancePayableRounded">
        <th mat-header-cell *matHeaderCellDef> Pay-out Amount </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{utils.displayRandAmount(element.rollupNetBalancePayableRounded)}} </td>
      </ng-container>

      <ng-container matColumnDef="processStatus">
        <th mat-header-cell *matHeaderCellDef> Pay-out Status </th>
        <td mat-cell class="custom-table-cell btn-cursor" *matCellDef="let element"> {{utils.displayStatus(element.processStatus)}} </td>
      </ng-container>

      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="['table-label']"></tr>
      <tr mat-header-row class="custom-table-header" *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="custom-table-row" *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator #paginator class="custom-paginator"
                   [length]="dataSource.totalElements"
                   [pageSize]="10"
                   [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>


</div>
