import {Injectable} from "@angular/core";
import {defaultColorTheme} from "./theme.model";
import {ColorUtilsService} from "./colorUtils.service";
import {CurrentContextService} from "../app/core/services/security/current-context.service";
import {LocationOffering} from '../app/core/model/location-offering.interface';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor(private colorUtils: ColorUtilsService, private currentContext: CurrentContextService) {

    let currentOffering: LocationOffering | null = currentContext.getCurrentOffering();
    if (currentOffering) {
      this.setThemeColors(currentOffering);
    } else {
      this.setThemeColors(null);
    }

    this.currentContext.getOfferingChangeEvent().subscribe(offering => {
      if (offering && offering.landlordTheme) {
        this.setThemeColors(offering);
      } else {
        this.setThemeColors(null);
      }
    })
  }

  public setThemeColors(locationOffering: LocationOffering | null): void {

    let theme = null;

    if (locationOffering) {
      if (locationOffering.landlordTheme && !locationOffering.offeringTheme) {
        theme = locationOffering.landlordTheme;
      }
      if (locationOffering.offeringTheme) {
        theme = locationOffering.offeringTheme
      }
    }

    if (theme) {
      document.documentElement.style.setProperty('--primary-color', theme.primary);
      document.documentElement.style.setProperty('--primary-color-h', this.colorUtils.hexToHsl(theme.primary).h.toString());
      document.documentElement.style.setProperty('--primary-color-s', this.colorUtils.hexToHsl(theme.primary).s.toString() + '%');
      document.documentElement.style.setProperty('--primary-color-l', this.colorUtils.hexToHsl(theme.primary).l.toString() + '%');
      document.documentElement.style.setProperty('--secondary-color', theme.secondary);
    } else {
      document.documentElement.style.setProperty('--primary-color', defaultColorTheme.primary);
      document.documentElement.style.setProperty('--primary-color-h', this.colorUtils.hexToHsl(defaultColorTheme.primary).h.toString());
      document.documentElement.style.setProperty('--primary-color-s', this.colorUtils.hexToHsl(defaultColorTheme.primary).s.toString() + '%');
      document.documentElement.style.setProperty('--primary-color-l', this.colorUtils.hexToHsl(defaultColorTheme.primary).l.toString() + '%');
      document.documentElement.style.setProperty('--secondary-color', defaultColorTheme.secondary);
    }
  }
}
