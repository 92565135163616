<div class="full-width info-header page-padding"
     [ngStyle]="{'background-color': backgroundWhite ? 'white' : null}"
     fxLayout="column" [class.full-screen-height]="fullHeight" *ngIf="!banner">
  <span class="subheading-default" fxFlexOffset="30px">{{section}}</span>
  <span class="title-default" fxFlexOffset="20px">{{title}}</span>
  <span class="heading-default header-desc" fxFlexOffset="10px">{{description}}</span>

  <div *ngIf="hasSearchBar" fxFlexOffset="20px">
    <div [formGroup]="searchBarForm">
      <mat-form-field appearance="outline" class="custom-text-field">
        <mat-label>{{searchBarLabel}}</mat-label>
        <input formControlName="searchValue" matInput type="text"/>
        <mat-icon class="material-icons-outlined" matIconSuffix>search</mat-icon>
      </mat-form-field>
    </div>
  </div>
</div>

<div class="full-width banner-header" [ngStyle]="{height: descriptionAboveLine ? '131px' : '154px'}"
     fxLayout="column" fxLayoutAlign="end start" [fxLayoutGap]="descriptionAboveLine ? '10px' : '20px'"
     [class.full-screen-height]="fullHeight" *ngIf="banner">
  <div class="page-padding full-width" fxLayout="row" fxLayoutAlign="space-between center" fxFlexOffset="20px">
    <span class="title-default">{{title}}</span>
    <span class="heading-regular">{{subtitle}}</span>
    <div *ngIf="hasSearchBar" class="me-5">
      <div [formGroup]="searchBarForm">
        <mat-form-field appearance="outline" class="custom-text-field" style="width: 267px;">
          <mat-label>{{searchBarLabel}}</mat-label>
          <input formControlName="searchValue" matInput type="text"/>
          <mat-icon class="material-icons-outlined search-icon-suffix" matIconSuffix>search</mat-icon>
        </mat-form-field>
      </div>
    </div>
  </div>
  <span class="heading-regular page-padding pb-3" *ngIf="descriptionAboveLine">{{description}}</span>
  <mat-divider class="full-width" *ngIf="!descriptionAboveLine"></mat-divider>
  <span class="heading-default page-padding" style="padding-bottom: 20px" *ngIf="!descriptionAboveLine">{{description}}</span>
</div>

