import {Component} from '@angular/core';
import {LoadingService} from '../../../../core/services/loading.service';
import {RetailerContactsService} from './retailer-contacts.service';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {lastValueFrom} from 'rxjs';
import {CurrentContextService} from '../../../../core/services/security/current-context.service';
import {RetailerContacts, RetailerContactUpdate} from '../../../../core/model/retailer.model';
import {countryCodes, countryPhoneCodes} from '../../../../core/model/country-phone-codes.model';

@Component({
  selector: 'app-retailer-contacts',
  templateUrl: './retailer-contacts.component.html',
  styleUrls: ['./retailer-contacts.component.scss']
})
export class RetailerContactsComponent {

  edit: boolean = false;

  contactsForm: FormGroup;
  loading: boolean = false;
  countryCodes: countryPhoneCodes[];
  addingContactForm: FormGroup;
  addingContact: boolean = false;
  itemToDelete: number | null = null;
  isRemovePanelOpen: boolean = false;


  constructor(public loader: LoadingService,
              private service: RetailerContactsService,
              private fb: FormBuilder,
              private contextService: CurrentContextService) {
    this.contactsForm = this.fb.group({
      contacts: this.fb.array([])
    });
    this.countryCodes = countryCodes;
    this.addingContactForm = new FormGroup({
      contactTypeCode: new FormControl({value: "MOBILE_PHONE", disabled: false}, Validators.required),
      retailerId: new FormControl({value: this.contextService.currentRetailer.id, disabled: false}),
      valueCode: new FormControl("", Validators.required),
      value: new FormControl("", Validators.required)
    })
  }

  get contacts(): FormArray {
    return this.contactsForm.controls['contacts'] as FormArray;
  }

  ngOnInit(): void {
    this.buildForm()
  }

  onSubmit(): void {
    this.loading = true
    lastValueFrom(this.service.saveAllRetailerContacts(this.contextService.getCurrentRetailer().id, this.transformFormArrayToRetailerUpdate()))
      .then(() => {
        this.contacts.clear();
        this.buildForm();
        this.edit = false;
      })
      .catch((error: string) => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  saveIndividualContact(): void {
    let retailerId: number = this.contextService.getCurrentRetailer().id;
    this.loading = true;
    lastValueFrom(this.service.saveAllRetailerContacts(retailerId, [{
      contactTypeCode: this.addingContactForm.get('contactTypeCode')!.value,
      retailerId: retailerId,
      valueCode: this.addingContactForm.get('valueCode')!.value,
      value: this.addingContactForm.get('value')!.value
    }]))
      .then(() => {
        this.loading = false;
        this.addingContact = false;
        this.contacts.clear();
        this.buildForm();
      })
      .finally(() => {
        this.loading = false;
      });
  }

  toggleEdit(): void {
    this.edit = !this.edit;
    if (this.edit) {
      this.contactsForm.enable();
    } else {
      this.contactsForm.disable();
    }
  }

  displayConfirmDelete(contact: AbstractControl<any>): void {
    this.itemToDelete = contact.getRawValue().id;
    this.isRemovePanelOpen = true;
  }

  removeContact($event: boolean): void {
    if ($event) {
      this.loading = true;
      lastValueFrom(this.service.deleteOne(this.itemToDelete))
        .then(() => {
          this.itemToDelete = null;
          this.contacts.clear();
          this.buildForm();
        })
        .catch((error: string) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
          this.isRemovePanelOpen = false;
          this.edit = false;
        });
    }
    this.isRemovePanelOpen = false;
  }

  private buildForm(): void {
    this.loading = true;
    lastValueFrom(this.service.getAllRetailerContacts(this.contextService.getCurrentRetailer().id))
      .then((res: { content: RetailerContacts[] }) => {
        this.loading = false;
        res.content.forEach((contact: RetailerContacts) => {
          this.contacts.push(this.fb.group({
            id: [contact.id],
            contactTypeCode: [contact.contactType.code],
            retailerId: [contact.retailerId],
            valueCode: [contact.valueCode],
            value: [this.stripValueCode(contact.value)]
          }));
        });
        this.contactsForm.disable();
      })
      .catch((error: string) => {
        console.log(error);
      });
  }

  private stripValueCode(valueCode: string): string {
    if (valueCode.charAt(0) === '-' || valueCode.charAt(0) === '+') {
      return valueCode.substring(1);
    }
    return valueCode;
  }

  private transformFormArrayToRetailerUpdate(): RetailerContactUpdate[] {
    const retailerId: number = this.contextService.getCurrentRetailer().id;
    return this.contacts.controls.map((item: any) => {
      const result: RetailerContactUpdate = {
        id: item.value.id,
        contactTypeCode: item.value.contactTypeCode,
        retailerId: retailerId,
        valueCode: item.value.valueCode,
        value: item.value.value
      }
      return result
    });
  }
}
