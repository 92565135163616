import {Injectable} from '@angular/core';
import {BaseService} from '../../../../core/services/base.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Observable} from 'rxjs';
import {RetailerContacts, RetailerContactUpdate} from '../../../../core/model/retailer.model';
import {CurrentContextService} from '../../../../core/services/security/current-context.service';

@Injectable({
  providedIn: 'root'
})
export class RetailerContactsService extends BaseService {

  baseUrl: string = environment.apiRetailersHost + '/portal/retailer'

  constructor(private http: HttpClient,
              private contextService: CurrentContextService) {
    super();
  }

  getAllRetailerContacts(retailerId: number): Observable<{ content: RetailerContacts[] }> {
    return this.http.get<{ content: RetailerContacts[] }>(this.baseUrl + `/${retailerId}/contacts`);
  }

  saveAllRetailerContacts(retailerId: number, requestBody: RetailerContactUpdate[]): Observable<RetailerContacts[]> {
    return this.http.post<RetailerContacts[]>(this.baseUrl + `/${retailerId}/contacts`, requestBody);
  }

  deleteOne(itemToDelete: number | null): Observable<void> {
    const retailerId = this.contextService.getCurrentRetailer().id;
    return this.http.delete<void>(this.baseUrl + `/${retailerId}/contacts/${itemToDelete}`);
  }
}
