<div class="px-3 py-2 pb-3 full-screen-height" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
  <div class="full-width pre-auth-header-div" fxLayout="column" fxLayoutGap="20px"  fxLayoutAlign="center center">
    <h1 class="title-large">Log In</h1>
    <h2 class="subtitle-regular">Don't have an account? <span (click)="gotoSignUp()" class="heading-primary-1 btn-cursor">Sign Up</span>
    </h2>
  </div>
  <div class=" p3 full-width flex-row" fxLayoutAlign="center center">
    <form (ngSubmit)="onSubmit()" [formGroup]="loginForm" class="pre-auth-form" fxLayout="column" fxLayoutAlign="space-between center">
      <!--  fxLayoutGap="30px" -->
      <mat-form-field appearance="outline" class="custom-text-field" floatLabel="always" hideRequiredMarker>
        <mat-label>Email</mat-label>
        <input formControlName="email"
               matInput
               pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$"
               placeholder="Email address"
               required
               type="email"/>
        <mat-error class="link-error" *ngIf="loginForm.get('email')!.hasError('required')">Email is required</mat-error>
        <mat-error class="link-error" *ngIf="loginForm.get('email')!.hasError('pattern')">Email is not valid</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="custom-text-field" floatLabel="always" hideRequiredMarker>
        <mat-label>Password</mat-label>
        <input
          [type]="hidePassword ? 'password' : 'text'" formControlName="password"
          matInput
          placeholder="password"
          required/>
        <mat-icon (click)="hidePassword = !hidePassword" class="btn-cursor icon-password-suffix"
                  matIconSuffix>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        <mat-error class="link-error" *ngIf="loginForm.get('password')!.hasError('required')">Password is required</mat-error>
      </mat-form-field>
      <mat-error class="link-error" *ngIf="loginForm.get('password')!.hasError('login-failed')">Username or password is incorrect
      </mat-error>
      <button class="button-primary" mat-button style="text-align: center; color: white" type="submit" [disabled]="loader.loading$ | async">
        <div class="button-text">Login with Email</div>
      </button>
      <div (click)="userForgotPassword()" class="heading-regular btn-cursor" style="text-align: center">Forgot your password?</div>
    </form>
  </div>

  <div class=" p3 full-width flex-row" fxLayoutAlign="center center">
    <app-footer-links></app-footer-links>
  </div>
</div>
