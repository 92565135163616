import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthenticationService} from './authentication.service';
import {CurrentContextService} from './current-context.service';
import {environment} from '../../../../environments/environment';

@Injectable({providedIn: 'root'})
export class TokenInterceptor implements HttpInterceptor {

  constructor(private auth: AuthenticationService,
              private contextService: CurrentContextService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = new HttpHeaders(this.addSysContextHeaders(req));
    const cloned = req.clone({
      headers: headers
    });
    return next.handle(cloned);
  }

  private addSysContextHeaders(req: any) {
    let headersObject: { [key: string]: any } = {
      'x-system-id': 'PORTAL'
    }

    if (this.auth.getToken()) {
      headersObject['Authorization'] = `Bearer ${this.auth.getToken()}`;
    }

    if (this.contextService.getCurrentOffering() != null && !this.isRequestForUser(req)) {
      headersObject['x-offering-id'] = this.contextService.getCurrentOffering()!.offeringUuid.toUpperCase();
    }

    if (this.contextService.getCurrentLandlordLocation() != null) {
      let currentLandlordLocation = this.contextService.getCurrentLandlordLocation()!;
      headersObject['x-location-id'] = currentLandlordLocation.uuid;
      headersObject['x-landlord-id'] = currentLandlordLocation.landlordUuid;
    }

    req.headers.keys().forEach((ea: string) => {
      headersObject[ea as keyof Object] = req.headers.get(ea);
    });

    return Object.entries(headersObject)
      .reduce((a, [k, v]) => (v === null ? a : (a[k as keyof Object] = v, a)), {});
  }

  private isRequestForUser(req: any): boolean {
    return req.url.includes(`${environment.apiSecurityHost}/api/user`) && req.method.toLowerCase() == 'get';
  }
}
