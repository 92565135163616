import {Component, OnInit} from '@angular/core';
import {defaultChartOptions} from "../../dashboards/chart-options/chart-options.model";
import {ChartOptionsService} from "../../dashboards/chart-options/chart-options.service";
import {
  TurnoverCaptureData,
  TurnoverCaptureMonth,
  TurnoverCaptureStatus,
  TurnoverType
} from "./turnover-capture.model";
import {DateUtilsService} from "../../../shared/services/dateUtils.service";
import {UtilsService} from "../../../shared/services/utils.service";
import {DomSanitizer} from "@angular/platform-browser";
import {MatIconRegistry} from "@angular/material/icon";
import {ChartConfiguration, ChartData, ChartTypeRegistry, TooltipItem} from "chart.js";
import {chartColors} from "../../dashboards/chart-options/chart-colors.model";
import {TurnoverCaptureService} from "./turnover-capture.service";

@Component({
  selector: 'app-turnover-capture',
  templateUrl: './turnover-capture.component.html',
  styleUrls: ['./turnover-capture.component.scss']
})
export class TurnoverCaptureComponent implements OnInit {

  datasource: TurnoverCaptureMonth[] = [];

  displayedColumns: string[] = ['status', 'fromDate', 'toDate', 'turnoverType', 'amount', 'capturedBy', 'updateDate'];
  selectedMonth!: TurnoverCaptureMonth;

  turnoverCaptureData: ChartData<'bar'> = {labels: [], datasets: [{data: []}]};
  turnoverCaptureChartOptions: ChartConfiguration<'bar'>['options'];

  editedItems: TurnoverCaptureData[] = [];

  constructor(public dateUtils: DateUtilsService,
              public utils: UtilsService,
              private domSanitizer: DomSanitizer,
              private matIconRegistry: MatIconRegistry,
              private turnoverCaptureService: TurnoverCaptureService,
              private chartOptionsService: ChartOptionsService) {
    this.matIconRegistry.addSvgIcon('done', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/done.svg'));
    this.matIconRegistry.addSvgIcon('exclamation', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/exclamation.svg'));
    this.matIconRegistry.addSvgIcon('back-arrow-button', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/back-arrow-button.svg'));

    let chartOptions = JSON.parse(JSON.stringify(defaultChartOptions));
    this.chartOptionsService.setCurrencyAxis(chartOptions!, "y");
    this.turnoverCaptureChartOptions = chartOptions;
  }

  ngOnInit() {
    this.selectedMonth = this.datasource[0];
    this.loadTurnoverCaptureData();
    this.loadTurnoverCaptureChart();
  }

  loadTurnoverCaptureData() {
    this.turnoverCaptureService.getTurnoverOverCaptureForm().subscribe({
      next: value => {
        this.sortFormItems(value);
        this.datasource = value;
        this.selectedMonth = value[0];
      },
      error: err => console.log(err)
    })
  }

  loadTurnoverCaptureChart() {
    this.turnoverCaptureService.getTurnoverOverCaptureChart().subscribe({
      next: value => {
        const chartData = value.chartSeries[0];
        chartData.chartData.reverse();
        this.turnoverCaptureData = {
          labels: chartData.chartData.map(m => m.label),
          datasets: [
            {
              label: chartData.name,
              data: chartData.chartData.map(m => m.data),
              backgroundColor: chartColors.BLUE,
              borderColor: chartColors.BLUE,
              borderRadius: 5
            }
          ]
        }
      },
      error: err => console.log(err)
    })
  }

  changeFormValue(amount: number | null, formItem: TurnoverCaptureData) {
    if (amount!= null) {
      this.editedItems.push(formItem);
    } else {
      this.editedItems = this.editedItems.filter(f => f.uuid != formItem.uuid);
    }
  }

  saveTurnoverFormItems() {
    this.turnoverCaptureService.saveTurnoverFormItems(this.editedItems).subscribe({
      next: value => {
        this.datasource = value;
        this.editedItems = [];
      },
      error: err => console.log(err)
    })
  }

  cancelChanges() {
    this.loadTurnoverCaptureData();
    this.editedItems = [];
  }

  sortFormItems(months: TurnoverCaptureMonth[]) {
    months.forEach(month => {
      month.turnoverFormItems = month.turnoverFormItems.sort((a, b) => this.dateUtils.isBefore(a.periodStartDate, b.periodStartDate) ? -1 : 1);
    })
  }

  isSelectedMonth(month: string) {
    return this.selectedMonth.turnoverMonth == month;
  }

  protected readonly TurnoverCaptureStatus = TurnoverCaptureStatus;
  protected readonly TurnoverType = TurnoverType;
}
