import {AbstractControl} from '@angular/forms';
import validator from 'validator';

/**
 * Provides ReactiveForms::Validator version of validator.js functionality for use in forms.
 * See <a href="https://www.npmjs.com/package/validator"> validator.js</a>
 */
export class CustomValidators {

  static websiteUrl(control: AbstractControl): { [key: string]: any } | null {
    if (control?.value) {
      return validator.isURL(control.value, {require_protocol: false}) ? null : {websiteUrlInvalid: true};
    }
    return null;
  }

  static email(control: AbstractControl): { [key: string]: any } | null {
    if (control?.value) {
      return validator.isEmail(control.value) ? null : {websiteUrlInvalid: true};
    }
    return null;
  }

}
