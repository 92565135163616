import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {FormControl, FormGroup} from "@angular/forms";
import {GenericDatasource} from "../../../../shared/datasource/generic.datasource";
import {POSSaleStatus, SaleStatus, TransactionStatus, TransactionSummary} from "../../accounts.model";
import {DateRange} from "@angular/material/datepicker";
import {AccountsService} from "../../accounts.service";
import {UtilsService} from "../../../../shared/services/utils.service";
import {DateUtilsService} from "../../../../shared/services/dateUtils.service";
import {PaginatorService} from "../../../../shared/services/paginator.service";
import {
  CustomDateRangeChangeEventModel
} from "../../../../shared/components/custom-date-filter/custom-date-range-change-event.model";
import {tap} from "rxjs";
import {CurrentContextService} from "../../../../core/services/security/current-context.service";
import {
  CustomDateRangeModel,
  TimeFrames
} from "../../../../shared/components/custom-date-filter/custom-date-range.model";

@Component({
  selector: 'app-transaction-schedule',
  templateUrl: './transaction-schedule.component.html',
  styleUrls: ['./transaction-schedule.component.scss']
})
export class TransactionScheduleComponent implements OnInit, AfterViewInit  {
  @ViewChild('paginator') paginator!: MatPaginator;
  filterForm: FormGroup;

  dataSource = new GenericDatasource<TransactionSummary>(this.accountsService);
  displayedColumns = ['transactionInvoiceNumber', 'transactionDate', 'saleAmountIncl', 'transactionFeeIncl', 'turnoverRentalFeeIncl', 'netBalancePayableRetailerInclRounded', 'posStatus', 'processingStatus'];

  dateRange: DateRange<Date | null> = new DateRange<Date | null>(null, null);
  dateFilters: CustomDateRangeModel[] = [
    new CustomDateRangeModel('1', 7, TimeFrames.DAYS, 'Last 7 days'),
    new CustomDateRangeModel('2', 1, TimeFrames.MONTH, 'This month')
  ]


  posStatuses: string[] = Object.keys(POSSaleStatus);
  saleStatuses: string[] = Object.keys(SaleStatus);
  transactionStatuses!: any[];

  constructor(private accountsService: AccountsService,
              public utils: UtilsService,
              public dateUtils: DateUtilsService,
              private paginatorService: PaginatorService,
              private currentContext: CurrentContextService) {
    this.filterForm = new FormGroup({
      posStatus: new FormControl([]),
      processingStatus: new FormControl([]),
    })
  }

  ngOnInit() {
    this.dataSource = new GenericDatasource<TransactionSummary>(this.accountsService);
    this.dateRange = this.dateFilters.at(0)!.getDateRange();
    this.dataSource.loadData(`/${this.currentContext.currentRetailer.id}/accounts/transactions/schedule`,
      {size: '10', page: '0', sort: 'transactionDate,desc'},
      [{
        name: 'fromDate',
        val: this.dateUtils.displayShortDate(this.dateRange.start)
      },
        {name: 'toDate', val: this.dateRange.end ? this.dateUtils.displayShortDate(this.dateRange.end) : null}
      ]);

    this.setTransactionStatuses();
  }

  ngAfterViewInit() {
    this.paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    this.paginator.page
      .pipe(
        tap(() => this.loadTransactionsSchedule()))
      .subscribe();

    this.filterForm.valueChanges.subscribe(() => this.loadTransactionsSchedule());
  }

  loadTransactionsSchedule() {
    const filters = [
      {name: 'posStatus', val: this.filterForm.get('posStatus')?.value},
      {name: 'processingStatus', val: this.filterForm.get('processingStatus')?.value},
      {name: 'fromDate', val: this.dateUtils.displayShortDate(this.dateRange.start)},
      {name: 'toDate', val: this.dateRange.end ? this.dateUtils.displayShortDate(this.dateRange.end) : null},
    ];

    let page;
    if (this.paginator){
      page = {size: this.paginator.pageSize.toString(), page: this.paginator.pageIndex.toString(), sort: 'transactionDate,desc'}
    } else {
      page = {size: '10', page: '0', sort: 'transactionDate,desc'}
    }
    this.dataSource.loadData(`/${this.currentContext.currentRetailer.id}/accounts/transactions/schedule`, page, filters);
  }

  getDateRange(event: CustomDateRangeChangeEventModel) {
    this.dateRange = event.dateRange;
    this.loadTransactionsSchedule()
  }

  setTransactionStatuses() {
    const pending = [TransactionStatus.NEW, TransactionStatus.PENDING_PAYMENT, TransactionStatus.STAGED_IN_PAYMENT_BATCH, TransactionStatus.SUBMITTED_FOR_PAYMENT, TransactionStatus.PENDING_REFUND_RECOVERY, TransactionStatus.STAGED_FOR_RECOVERY, TransactionStatus.SUBMITTED_FOR_RECOVERY]
      .map(m => m.toString());
    const error = Object.keys(TransactionStatus).filter(f => f.includes('ERROR')).map(m => m.toString());
    this.transactionStatuses = Object.keys(TransactionStatus).map(m => {
      if (pending.includes(m) || error.includes(m)) {
        return;
      }
      return { label: this.utils.displayStatus(m), val: [m]};
    }).filter(f => f);
    this.transactionStatuses.push({label: 'Pending payment', val: pending});
    this.transactionStatuses.push({label: 'Error', val: error});
  }
}
