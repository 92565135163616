import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../../core/services/security/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {LoadingService} from "../../../core/services/loading.service";
import {CurrentContextService} from '../../../core/services/security/current-context.service';
import {AuthGuard} from "../../../core/services/security/auth-guard.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading: boolean = false;
  hidePassword = true;


  constructor(private authService: AuthenticationService,
              private domSanitizer: DomSanitizer,
              private matIconRegistry: MatIconRegistry,
              private route: ActivatedRoute,
              private router: Router,
              public loader: LoadingService,
              private currentContext: CurrentContextService,
              private authGuard: AuthGuard) {
    this.matIconRegistry.addSvgIcon('icon-show-password',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/actions/icon-show-password.svg'));
    this.matIconRegistry.addSvgIcon('icon-hide-password',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/actions/icon-hide-password.svg'));

    this.loginForm = new FormGroup({
      email: new FormControl({value: '', disabled: this.loading}, {
        validators: [Validators.required, Validators.email],
      }),
      password: new FormControl({value: '', disabled: this.loading}, {validators: [Validators.required]}),
    });

  }

  ngOnInit() {
  }

  userForgotPassword(): void {
    this.router.navigate([`/forgot-password`])
      .catch((error: string) => {
        console.log(error)
      })
  }

  onSubmit(): void {
    let value = this.loginForm.value;
    this.loading = true;


    this.authService.doLogin({email: value.email, secret: value.password})
      .then((loggedIn: boolean) => {
          this.loading = false;
          let returnUrl = this.route.snapshot.paramMap.get('returnUrl');
          let queryParams: any = {}

          // get all query params exclude return url
          this.route.snapshot.queryParamMap.keys.filter(key => {
            return key !== 'returnUrl'
          }).forEach(key => {
            queryParams[key] = this.route.snapshot.queryParamMap.get(key);
          });

          // check if a return Url is being included in the query params and not the navigation
          if (this.route.snapshot.queryParamMap.keys.includes('returnUrl') && returnUrl === null || returnUrl === undefined) {
            returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
          }

          let currentLocationCode: string | null = this.currentContext.getCurrentLocationCode();
          returnUrl = returnUrl ? returnUrl : `/${currentLocationCode}/home`;
          if (!this.authService.isVerified && loggedIn) {
            this.authGuard.tryNavigateToVerify(queryParams);
          }

          //check that a retailer has been set in context:
          if (!this.currentContext.isRetailerSet()) {
            returnUrl = `/${currentLocationCode}/home`;
            this.router.navigate([`${currentLocationCode}/retailer/brand-info`, {returnUrl}])
              .catch((err: string) => {
                console.log(err);
              });
            return;
          }

          // login success -> go to referrer/homepage
          this.router.navigate([returnUrl], {queryParams})
            .catch((reason: string) => {
              console.log(reason)
            });
        },
        (error: any) => {
          this.loading = false;
          console.log(error)
          // login failed -> reset password and show error
          this.loginForm.get('password')!.setValue(null);
          this.loginForm.get('password')!.setErrors({'login-failed': true});
        }
      );
  }

  gotoSignUp(): void {
    this.router.navigate([`/${this.currentContext.getCurrentLocationCode()}/join`])
      .catch((error: string) => {
        console.error(error);
      })
  }
}
