import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {FormControl, FormGroup} from '@angular/forms';
import {ThemeService} from "../../../../themes/theme-service.service";

@Component({
  selector: 'app-public-retail-spaces',
  templateUrl: './public-retail-spaces.component.html',
  styleUrls: ['./public-retail-spaces.component.scss']
})
export class PublicRetailSpacesComponent {

  rentalSpaceForm: FormGroup;
  offeringNameSearchValue: string = "";


  constructor(private router: Router) {
    this.rentalSpaceForm = new FormGroup({
      offeringName: new FormControl('')
    });
  }

  embeddedPage(): boolean {
    return this.router.url.includes('=embed');
  }

  searchOfferings($event: string): void {
    this.offeringNameSearchValue = $event;
  }
}
