<div class="full-width py-3 px-4" fxLayout="column" fxLayoutAlign="start start">
  <div class="full-width" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="15px">
    <div class="full-width mb-0" fxLayout="row" fxLayoutAlign="end center">
      <button class="button-close-top" mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div class="full-width" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="15px">
      <div class="title-default">Dashboard Data</div>
      <div class="body-1-regular-1">The information displayed on the dashboards is carefully calculated to provide the
        best representation of the data provided. However, some data may not appear as expected due to certain
        parameters:
      </div>

      <div fxLayout="column">
        <span class="body-1-default">Offering dates</span>
        <span class="body-1-regular-2" fxFlexOffset="5px">Only data within the range of the offering's start date, the current date and the offering's end date will be displayed. Filters are automatically adjusted accordingly.</span>
      </div>

      <div fxLayout="column">
        <span class="body-1-default">Lease dates</span>
        <span class="body-1-regular-2" fxFlexOffset="5px">Only data within the range of the lease's start date, the current date and the lease's end date will be displayed. Filters are automatically adjusted accordingly.</span>
        <span class="body-1-regular-2">If sales occurred outside of your lease period, they might not be included in certain calculations.</span>
      </div>

      <div fxLayout="column">
        <span class="body-1-default">Data collection</span>
        <span class="body-1-regular-2" fxFlexOffset="5px">Sales and product data are updated periodically. This means that as data is imported, it will be displayed.</span>
      </div>

      <div class="body-1-regular-1">These dashboards are only as accurate as the space, dates and sales recorded for
        your lease. If you are concerned about your lease's set up, please contact your landlord for support.
      </div>

    </div>

  </div>

</div>
