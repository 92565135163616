<div class="page-content page-padding" fxLayout="column">
  <div fxLayoutGap="15px" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
      <span class="subtitle-default">Billing details</span>
      <button (click)="toggleIsEditing()" *ngIf="!isEditing" class="button-action" mat-flat-button [disabled]="editing == 'banking' || !!(loader.loading$ | async)"><div class="button-text">Update</div></button>
      <button (click)="onSaveChanges()" *ngIf="isEditing" class="button-action" mat-flat-button [disabled]="editing == 'banking' || !!(loader.loading$ | async)"><div class="button-text">Submit</div></button>
      <button (click)="toggleIsEditing()" *ngIf="isEditing" class="button-action-cancel" mat-flat-button [disabled]="editing == 'banking' || !!(loader.loading$ | async)"><div class="button-text">Cancel</div></button>
    </div>
    <app-info-panel [type]="'info'"
                    [message]="'You can update your billing information anytime.'"></app-info-panel>
  </div>

  <div class="full-width" fxLayout="row" fxLayoutGap="20px" fxFlexOffset="30px">
    <div class="info-card" style="padding: 30px 0;" fxLayout="column">
      <form [formGroup]="userBillingForm" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="35px">

        <div class="full-width px-5" fxLayout="row" fxLayoutAlign="space-between start">
          <mat-form-field [ngClass]="isEditing ? 'custom-text-field' : 'custom-text-autofill'" appearance="outline" floatLabel="always" hideRequiredMarker style="width: 400px;">
            <mat-label>Company name</mat-label>
            <input #billingName formControlName="billingName" matInput type="text"/>
            <mat-error class="link-error" *ngIf="userBillingForm.get('billingName')!.hasError('required')">
              Company name is required
            </mat-error>
          </mat-form-field>
        </div>

        <mat-divider class="full-width"></mat-divider>

        <div class="full-width px-5" fxLayout="row" fxLayoutAlign="space-between start">
          <mat-form-field [ngClass]="isEditing ? 'custom-text-field' : 'custom-text-autofill'" appearance="outline" floatLabel="always" hideRequiredMarker style="width: 400px;">
            <mat-label>Company registration number</mat-label>
            <input #compReg formControlName="compReg" matInput type="text"/>
            <mat-error class="link-error" *ngIf="userBillingForm.get('compReg')!.hasError('required')">
              Company registration is required
            </mat-error>
          </mat-form-field>
        </div>

        <mat-divider class="full-width"></mat-divider>

        <div class="full-width px-5" fxLayout="row" fxLayoutAlign="space-between start">
          <mat-form-field [ngClass]="isEditing ? 'custom-text-field' : 'custom-text-autofill'" appearance="outline" floatLabel="always" hideRequiredMarker style="width: 400px;">
            <mat-label>Billing address</mat-label>
            <input
              matInput
              formControlName="address"
              matGoogleMapsAutocomplete
              (onAutocompleteSelected)="onAddressAutocompleteSelected($event)"
              (onLocationSelected)="onAddressLocationSelected($event)"
              (keyup)="resetGeometry()"
              #billingAddress
            />
            <mat-error class="link-error" *ngIf="userBillingForm.controls.address.hasError('required')">Billing address is required</mat-error>
            <mat-error class="link-error" *ngIf="userBillingForm.controls.address.hasError('invalidLocation')">Invalid location. Please select a location using the search function.</mat-error>
          </mat-form-field>
     </div>


        <mat-divider class="full-width"></mat-divider>

        <div class="full-width px-5" fxLayout="row" fxLayoutAlign="space-between start">
          <mat-form-field [ngClass]="isEditing ? 'custom-text-field' : 'custom-text-autofill'" appearance="outline" floatLabel="always" hideRequiredMarker style="width: 400px;">
            <mat-label>VAT registration number</mat-label>
            <input matInput type="text" formControlName="vatReg" #vatRegistration>
            <mat-error class="link-error" *ngIf="userBillingForm.get('vatReg')!.hasError('required')">
              VAT registration number is required
            </mat-error>
          </mat-form-field>
        </div>


        <mat-divider class="full-width"></mat-divider>

        <div class="full-width px-5" fxLayout="row" fxLayoutAlign="space-between start">
          <mat-form-field [ngClass]="isEditing ? 'custom-text-field' : 'custom-text-autofill'" appearance="outline" floatLabel="always" hideRequiredMarker style="width: 400px;">
            <mat-label>Email</mat-label>
            <input
              matInput
              type="email"
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$"
              #email
              [email]="true"
              formControlName="billingEmail"
            />
            <mat-error class="link-error" *ngIf="userBillingForm.get('billingEmail')!.hasError('email')">
              Email is not valid
            </mat-error>
            <mat-error class="link-error" *ngIf="userBillingForm.get('billingEmail')!.hasError('required')">
              Email is required
            </mat-error>
          </mat-form-field>
        </div>

        <mat-divider class="full-width"></mat-divider>

        <div class="full-width px-5" fxLayout="row" fxLayoutAlign="space-between start">
          <mat-form-field [ngClass]="isEditing ? 'custom-text-field' : 'custom-text-autofill'" appearance="outline" floatLabel="always" hideRequiredMarker style="width: 400px;">
            <mat-label>Phone number</mat-label>
            <input matInput type="text" formControlName="phoneNum" #phoneNum [pattern]="mobNumberPattern">
            <mat-error class="link-error" *ngIf="userBillingForm.get('phoneNum')!.hasError('required')">
              Phone number is required
            </mat-error>
            <mat-error class="link-error" *ngIf="userBillingForm.get('phoneNum')!.hasError('pattern')">
              Mobile number not valid, please enter 9 digits
            </mat-error>
          </mat-form-field>
        </div>
      </form>
    </div>
  </div>
</div>
