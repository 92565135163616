<div class="full-width" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
  <div class="full-width" fxFlexOffset="10px" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
    <span class="subtitle-default">Social Media Information</span>
    <button (click)="toggleEdit()" *ngIf="!edit" [disabled]="loader.loading$ | async" class="button-action"
            mat-flat-button>
      <div class="button-text">Update</div>
    </button>
    <button (click)="onSubmit()" *ngIf="edit" [disabled]="loader.loading$ | async" class="button-action"
            mat-flat-button>
      <div class="button-text">Submit</div>
    </button>
    <button (click)="toggleEdit()" *ngIf="edit" [disabled]="loader.loading$ | async" class="button-action-cancel"
            mat-flat-button>
      <div class="button-text">Cancel</div>
    </button>
  </div>

  <div class="full-width" fxFlexOffset="30px" fxLayout="row" fxLayoutGap="20px">
    <div class="info-card" fxLayout="column" fxLayoutGap="20px">
      <span class="heading-default">Social Media Links</span>

      <div [formGroup]="socialMediaProfilesForm" class="full-width" fxLayout="column" fxLayoutGap="15px">
        <ng-container formArrayName="profiles">
          <ng-container *ngFor="let profileType of profiles.controls; let index = index">
            <div [formGroupName]="index">
              <mat-form-field [ngClass]="edit ? 'custom-text-field' : 'custom-text-autofill'" appearance="outline"
                              floatLabel="always" fxFlexOffset="20px"
                              hideRequiredMarker="true">
                <mat-label>{{profileType.get('socialMediaProfileType')!.value.value.name}}</mat-label>
                <input [id]="'urlInput_' + profileType" formControlName="url" matInput type="text">
              </mat-form-field>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>

  </div>
</div>

