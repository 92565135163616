export enum SessionKey {
  CURRENT_USER_KEY = 'currentUser',
  CURRENT_USER_ID = 'currentUserId',
  CURRENT_RETAILER_KEY = 'currentRetailer',
  CURRENT_OFFERING_KEY = 'currentOffering',
  USER_GRANTS_KEY = 'userGrants',
  CURRENT_LEASE_KEY = 'currentLease',
  TOKEN_KEY = 'token',
  TOKEN_EXPIRY_KEY = 'tokenExpiresOn',
  // TEMP_TOKEN_KEY = 'temp-token',
  CURRENT_LANDLORD_LOCATION = "currentLandlordLocation",
  FLATTENED_USER_GRANTS = "flattenedGrants",
}
