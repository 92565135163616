<div class="full-width page-padding page-content" fxLayout="column" [class.full-screen-height-page-default]="!leaseSummary">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <span class="subtitle-default">Lease Details</span>
    <div *ngIf="!leaseSummary">
      <app-info-panel [type]="'info'" [message]="'You don\'t have a current lease at the moment'"></app-info-panel>
    </div>
  </div>

  <div class="full-width" fxLayout="row" fxLayoutGap="20px" fxFlexOffset="30px" *ngIf="leaseSummary">
    <div class="info-card" fxLayout="column">

      <span class="heading-default">Lease Summary</span>

      <div fxLayout="column" fxLayoutGap="15px" fxFlexOffset="20px">
        <div fxLayout="row" fxLayoutGap="15px">
          <div fxFlex="33">
            <mat-form-field class="custom-text-autofill" appearance="outline">
              <mat-label>Retailer ID</mat-label>
              <input matInput type="text" [value]="leaseSummary.retailerId + ' ' + leaseSummary.retailerCompanyName" disabled>
            </mat-form-field>
          </div>
          <div fxFlex="33">
            <mat-form-field class="custom-text-autofill" appearance="outline">
              <mat-label>Lease ID</mat-label>
              <input matInput type="text" [value]="utils.displayUuid(leaseSummary.uuid)" disabled>
            </mat-form-field>
          </div>
          <div fxFlex="34" fxLayout="row">
            <mat-form-field class="custom-text-autofill" appearance="outline" floatLabel="always">
              <mat-label>Lease Status</mat-label>
              <input matInput disabled>
              <div matPrefix [class]="status.className" class="ms-3 mt-1" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" *ngIf="getLeaseStatus(leaseSummary) as status">
                <mat-icon [class.material-icons-outlined]="status.outline">{{status.icon}}</mat-icon>
                <span class="footer-default">{{status.status}}</span>
              </div>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="15px">
          <div fxFlex="33">
            <mat-form-field class="custom-text-autofill" appearance="outline">
              <mat-label>Commencement Date</mat-label>
              <input matInput type="text" [value]="dateUtils.displayShortDate(leaseSummary.leaseStartDate)" disabled>
            </mat-form-field>
          </div>
          <div fxFlex="33">
            <mat-form-field class="custom-text-autofill" appearance="outline">
              <mat-label>Expiry Date</mat-label>
              <input matInput type="text" [value]="dateUtils.displayShortDate(leaseSummary.leaseEndDate)" disabled>
            </mat-form-field>
          </div>
          <div fxFlex="34">
            <mat-form-field class="custom-text-autofill" appearance="outline">
              <mat-label>Lease Period</mat-label>
              <input matInput type="text" [value]="dateUtils.displayDateDuration(leaseSummary.leaseStartDate, leaseSummary.leaseEndDate)" disabled>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="15px">
          <div fxFlex="33">
            <mat-form-field class="custom-text-autofill" appearance="outline">
              <mat-label>Location</mat-label>
              <input matInput type="text" [value]="leaseSummary.leaseLocation" disabled>
            </mat-form-field>
          </div>
          <div fxFlex="33">
            <mat-form-field class="custom-text-autofill" appearance="outline">
              <mat-label>Space Name or Store Number</mat-label>
              <input matInput type="text" [value]="leaseSummary.spaceNames" disabled>
            </mat-form-field>
          </div>
          <div fxFlex="34">
            <mat-form-field class="custom-text-autofill" appearance="outline">
              <mat-label>Size</mat-label>
              <input matInput type="text" [value]="leaseSummary.totalSize ? leaseSummary.totalSize.toString() + ' m²' : null" disabled>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="15px">
          <div fxFlex="33">
            <mat-form-field class="custom-text-autofill" appearance="outline">
              <mat-label>Rental Type</mat-label>
              <input matInput type="text" [value]="leaseSummary.rentalType ? displayRentalType(leaseSummary.rentalType) : '-'" disabled>
            </mat-form-field>
          </div>
          <div fxFlex="33">
            <mat-form-field class="custom-text-autofill" appearance="outline">
              <mat-label>Monthly Rental Fee</mat-label>
              <input matInput type="text" [value]="leaseSummary.monthlyPricePerSqm ? 'R ' + leaseSummary.monthlyPricePerSqm.toFixed(2) : '-'" disabled>
            </mat-form-field>
          </div>
          <div fxFlex="33"></div>
        </div>

      </div>
    </div>
  </div>

</div>
