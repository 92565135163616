<div fxLayout="column">
  <div fxLayout="row" class="filter-row-container"
       [formGroup]="regionFormGroup">
    <mat-button-toggle-group [(value)]="offeringTypesFilter" class="custom-borderless-button-toggle" multiple>
      <mat-button-toggle (click)="filterByAllOfferings()" value="ALL_RETAIL_SPACES">
        <div class="button-text">All Rental spaces</div>
      </mat-button-toggle>
      <mat-button-toggle (click)="filterOfferingsByType()" *ngFor="let offeringType of offeringTypes"
                         [value]="offeringType.typeCode">
        <div class="button-text">{{offeringType.typeName}}</div>
      </mat-button-toggle>
    </mat-button-toggle-group>
    <mat-form-field class="custom-select-field-borderless me-5" appearance="outline">
      <mat-label>Regions</mat-label>
      <mat-select class="custom-select-field-section" multiple formControlName="regions">
        <mat-option class="custom-select-field-option" *ngFor="let region of regionFilterOptions"
                    [value]="region.value">
          {{region.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <mat-drawer-container class="listing-container" hasBackdrop="false">
    <mat-drawer class="listing-drawer-container" mode="side" position="end" #listingDrawer>
      <app-offering-listing [offering]="highlightedOffering!"
                            [isPublic]="isPublicRoute"
                            (toggleDrawer)="toggleListingDrawer($event)"
                            (apply)="applyForOffering(highlightedOffering!)"
                            (enter)="enterOfferingPortal(highlightedOffering!)"
      ></app-offering-listing>
    </mat-drawer>

    <div fxLayout="column" style="padding-top: 30px">
      <div class="subtitle-default page-padding">
        All Rental Spaces
      </div>
      <div class="offerings-container full-width page-padding" fxFlexOffset="40px" fxLayoutAlign="start center">

        <div *ngFor="let offering of allOfferings">
          <mat-card (click)="toggleListingDrawer(true, offering)" class="offering-card">
            <div class="mat-card-image-container">
              <img alt="Photo of a {{offering.offeringName}}" class="carousel-overlay-under-image"
                   src="{{offering.bannerImage}}">
              <div class="highlightedOverlay" *ngIf="!!highlightedOffering && (highlightedOffering.offeringUuid == offering.offeringUuid)"></div>
              <div *ngIf="showSelectionOnHover" class="carousel-overlay">
                <div class="carousel-overlay-text" fxLayout="column" fxLayoutGap="15px">
                  <button (click)="enterOfferingPortal(offering)" *ngIf="canEnterPortal(offering.offeringUuid)"
                          class="button-primary button-large" mat-flat-button>
                    <div class="button-text">Enter Portal</div>
                  </button>
                  <button (click)="applyForOffering(offering)"
                          *ngIf="!hasSubmittedApplicationForOffering(offering.offeringUuid)"
                          class="button-outline button-large" mat-flat-button>
                    <div class="button-text">Apply</div>
                  </button>
                  <button (click)="toggleListingDrawer(true, offering)" *ngIf="hasOfferingListing(offering)"
                          class="button-outline button-large" mat-flat-button>
                    <div class="button-text">Listing Info</div>
                  </button>
                </div>
              </div>
            </div>
            <mat-card-content fxFlexOffset="20px" fxLayout="column" fxLayoutGap="5px">
              <div class="heading-regular">{{offering.locationName}} > {{utilsService.capitalizeAndLower(offering.offeringName)}}</div>
              <div fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="5px">
                <mat-icon class="material-icons-outlined location-pin-icon">{{offering.offeringAddress ? 'pin_drop' : ''}}</mat-icon>
                <div class="body-1-regular-1">{{offering.offeringAddress}}</div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>

      <div style="position: relative; bottom: 0; margin-top: 60px">
        <app-footer-links [showCopyrightOnly]="true"></app-footer-links>
      </div>

    </div>
  </mat-drawer-container>
</div>
