import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {LoadingService} from "../../../../core/services/loading.service";
import {GoogleAnalyticsService} from "../../../../core/services/google-analytics.service";
import {User} from "../../../../core/model/user.model";
import {CurrentContextService} from '../../../../core/services/security/current-context.service';

@Component({
  selector: 'app-application-signup-user',
  templateUrl: './application-signup-user.component.html',
  styleUrls: ['./application-signup-user.component.scss']
})
export class ApplicationSignupUserComponent implements OnInit {
  @Output() login = new EventEmitter<void>();
  @Output() signupUserSuccess = new EventEmitter<User>();
  @Output() submitError = new EventEmitter<string>();

  public userRegistrationForm: FormGroup;
  public errMessage: string | null = null;
  public hide = true;
  public show = true;
  public mobNumberPattern = '^((\\+91-?)|0)?[0-9]{10}$';
  public errorMsg: string | null = null;

  constructor(
    private fb: FormBuilder,
    private googleAnalyticsService: GoogleAnalyticsService,
    public loader: LoadingService,
    private context: CurrentContextService
  ) {
    this.userRegistrationForm = this.resetUserForm();
  }

  ngOnInit(): void {
    this.googleAnalyticsService.customEventEmitter('Retailer Onboarding', 'view', 'retailerRegister_view');
  }



  private resetUserForm(): FormGroup {
    return this.fb.group({
      firstname: [null, Validators.required],
      lastname: [null, Validators.required],
      email: [null, Validators.required],
      secret: [null, Validators.required],
      confirmPassword: [null, Validators.required],
    });
  }

  passwordInvalid(reset: boolean): boolean {
    const secret = this.userRegistrationForm.value.secret;
    const confirmSecret = this.userRegistrationForm.value.confirmPassword;

    if (
      (secret && secret.length < 8)
      || (/[A-Z]+/g.test(secret) === false)
      || (/[a-z]+/g.test(secret) === false)
      || (/[0-9]+/g.test(secret) === false)
      || (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(secret) === false)
    ) {
      this.userRegistrationForm.controls['secret'].setErrors({ 'not-valid': true });
      if (reset) this.userRegistrationForm.get('confirmPassword')!.setValue(null);
      this.userRegistrationForm.controls['confirmPassword'].setErrors(null);
      return true;
    }

    if (secret !== confirmSecret) {
      if (reset) this.userRegistrationForm.get('confirmPassword')!.setValue(null);
      this.userRegistrationForm.controls['confirmPassword'].setErrors({ 'no-match': true });
      return true;
    }

    return false;
  }

  public canSubmitUser(): boolean {
    this.passwordInvalid(false);
    return !this.userRegistrationForm.invalid;
  }

  // OTHER
  gotoLogin(): void {
    this.login.emit();
  }


  /**
   * Check if the form control is invalid or a specific error is present
   * @param formControlName the name of the form control
   * @param errorName the name of the error, else invalid is checked
   * @returns error state
   */
  public hasError(formControlName: string, errorName?: string): boolean {
    const control = this.userRegistrationForm.get(formControlName);
    return control!.touched && (errorName ? control!.hasError(errorName) : control!.invalid);
  }
}
