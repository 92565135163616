export interface RetailerCurationForm {
  retailerId: number;
  retailerCurationAssessmentId: number;
  form: CurationForm;
  finalised: boolean;
  outcome: string;
  updateDate: Date;
  overallScore: number,
  scoringBreakdown: ScoringBreakdown
}

export interface CurationForm {
  uuid: string;
  formCode: string;
  version: number;
  startDate: string;
  endDate: string;
  formName: string,
  isCurrentlyActive: boolean,
  categories: Category[];
}

export interface Category {
  uuid: string;
  formUuid: string;
  displayOrder: number;
  code: string;
  displayLabel: string;
  displayDescription: string;
  notes: string;
  questionGroups: QuestionGroup[];
}

export interface QuestionGroup {
  uuid: string;
  categoryUuid: string;
  displayOrder: number;
  code: string;
  displayLabel: string;
  displayDescription: string;
  questions: Question[];
  repeatedInput: boolean;
  repeatedValues: Question[][];
}

export interface Question {
  uuid: string;
  questionGroupUuid: string;
  displayOrder: number;
  required: boolean;
  value: string;
  replacedByQuestionUuid: string;
  questionTemplate: QuestionTemplate;
}

export interface QuestionTemplate {
  uuid: string;
  controlCode: string;
  controlDescription: string;
  controlType: string;
  controlPayload: ControlPayload;
}

export interface ControlPayload {
  label: string;
  precision?: number;
  min?: number;
  max?: number;
  selectType?: string;
  options?: Option[];
  format?: string;
  selectedOption?: Option;
  unselectedOption?: Option;
  start?: number;
  end?: number;
  increments?: RangeOption[];
  maxSizeMb?: number;
  fileTypes?: string[];
  files?: Option[]
  rangeType?: string;
  numberType?: NumberType;
}

export class Option {
  label!: string;
  valueCode!: string | null;

  constructor(obj: any) {
    Object.assign(this, obj);
  }
}

export class RangeOption extends Option {
  start!: number;
  end!: number;
  incrementSize!: number;
}

export enum FormState {
  REGISTER = 'register',
  REFERRER = 'referrer',
  IN_APP = 'in-app',
  EMBED = 'embed'
}

export interface ScoringBreakdown {
  preferredAndPassed: number;
  firstRating: number;
  secondThirdRating: number;
  dontPass: number;
}

export interface FormFileValue {
  fileCode: string,
  fileName: string,
  fileUuid: string,
  fileType: string
}

export enum ControlType {
  TEXT = 'TEXT',
  DATE = 'DATE',
  NUMBER = 'NUMBER',
  CHOICE = 'CHOICE',
  SELECT = 'SELECT',
  RANGE = 'RANGE',
  FILE = 'FILE',
  LOCATION = 'LOCATION',
  HEADING = 'HEADING',
  HEADING_TEXT = 'HEADING_TEXT',
  INFO_FILE = 'INFO_FILE'
}

export enum NumberType {
  NUMBER = 'NUMBER',
  PERCENTAGE = 'PERCENTAGE',
  CURRENCY = 'CURRENCY',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS'
}

export interface FormQuestionFilter {
  assessmentId: number | null;
  formCode: string;
  categoryUuid: string;
  questionGroupUuid: string;
  questionUuid: string;
  fileCode: string | null;
}
