import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: ['./info-panel.component.scss']
})
export class InfoPanelComponent {
  @Input() message!: string;
  @Input() type!: string;
  @Output() messageChange: EventEmitter<string> = new EventEmitter<string>();

  getPanelType(): { icon: string } {
    switch (this.type) {
      case 'disabled':
        return { icon: 'disabled-icon'};
      case 'warn':
        return { icon: 'warn-icon'};
      case 'error':
        return { icon: 'error-icon'};
      case 'primary':
        return { icon: 'primary-icon'};
      case 'accent':
        return { icon: 'accent-icon'};
      default:
        return { icon: 'info-icon'};
    }
  }

}
