import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';

import {BillingDetailsModel} from '../model/billing-details.model';
import {environment} from '../../../../environments/environment';
import {BaseService} from '../../../core/services/base.service';
import {CurrentContextService} from '../../../core/services/security/current-context.service';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class BillingDetailsService extends BaseService {

  baseUrl = environment.apiRetailersHost;

  constructor(private http: HttpClient, private context: CurrentContextService) {
    super();
  }

  updateBillingDetails(request: BillingDetailsModel): Observable<BillingDetailsModel> {
    const retailerId = this.context.getCurrentRetailer().id;
    const uri = this.baseUrl + `/portal/retailer/${retailerId}/billing`;
    return this.http.post<BillingDetailsModel>(uri, request);
  }

  getBillingDetailsForRetailer(): Observable<BillingDetailsModel> {
    const retailerId = this.context.getCurrentRetailer().id;
    const uri = this.baseUrl + `/portal/retailer/${retailerId}/billing`;
    return this.http.get<BillingDetailsModel>(uri);
  }
}
