import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {FormControl, FormGroup} from "@angular/forms";
import {GenericDatasource} from "../../../../shared/datasource/generic.datasource";
import {BatchStatus, RetailerPayoutSummary} from "../../accounts.model";
import {DateRange} from "@angular/material/datepicker";
import {UtilsService} from "../../../../shared/services/utils.service";
import {AccountsService} from "../../accounts.service";
import {DateUtilsService} from "../../../../shared/services/dateUtils.service";
import {PaginatorService} from "../../../../shared/services/paginator.service";
import {tap} from "rxjs";
import {
  CustomDateRangeChangeEventModel
} from "../../../../shared/components/custom-date-filter/custom-date-range-change-event.model";
import {CurrentContextService} from "../../../../core/services/security/current-context.service";
import {
  CustomDateRangeModel,
  TimeFrames
} from "../../../../shared/components/custom-date-filter/custom-date-range.model";
import {MatSelectChange} from '@angular/material/select';

@Component({
  selector: 'app-payout-summary',
  templateUrl: './payout-summary.component.html',
  styleUrls: ['./payout-summary.component.scss']
})
export class PayoutSummaryComponent implements OnInit, AfterViewInit {
  @ViewChild('paginator') paginator!: MatPaginator;
  filterForm: FormGroup;

  dataSource = new GenericDatasource<RetailerPayoutSummary>(this.accountsService);
  displayedColumns = ['paymentBatchUuid', 'processedDate', 'saleAmountInclTotal', 'transactionFeeInclTotal', 'turnoverRentalFeeInclTotal', 'rollupNetBalancePayableRounded', 'processStatus'];

  dateRange: DateRange<Date | null> = new DateRange<Date | null>(null, null);
  dateFilters: CustomDateRangeModel[] = [
    new CustomDateRangeModel('1', 7, TimeFrames.DAYS, 'Last 7 days'),
    new CustomDateRangeModel('2', 1, TimeFrames.MONTH, 'This month')
  ]

  batchStatuses: string[] = Object.keys(BatchStatus);

  constructor(private accountsService: AccountsService, public utils: UtilsService, public dateUtils: DateUtilsService, private paginatorService: PaginatorService, private currentContext: CurrentContextService) {
    this.filterForm = new FormGroup({
      payoutStatus: new FormControl(this.batchStatuses)
    })
  }

  ngOnInit() {
    this.dataSource = new GenericDatasource<RetailerPayoutSummary>(this.accountsService);
    this.dataSource.loadData(`/${this.currentContext.currentRetailer.id}/accounts/payouts/summary`, {size: '10', page: '0', sort: 'processedDate,desc'}, [
      {name: 'batchStatuses', val: this.filterForm.get('payoutStatus')?.value},
      {name: 'fromDate', val: this.dateUtils.displayShortDate(this.dateRange.start)},
      {name: 'toDate', val: this.dateRange.end ? this.dateUtils.displayShortDate(this.dateRange.end) : null},
    ]);
  }

  ngAfterViewInit() {
    this.paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    this.paginator.page
      .pipe(
        tap(() => this.loadPayoutSummary()))
      .subscribe();
  }

  loadPayoutSummary() {
    const filters = [
      {name: 'batchStatuses', val: this.filterForm.get('payoutStatus')?.value},
      {name: 'fromDate', val: this.dateUtils.displayShortDate(this.dateRange.start)},
      {name: 'toDate', val: this.dateRange.end ? this.dateUtils.displayShortDate(this.dateRange.end) : null},
    ]
    let page;
    if (this.paginator) {
      page = {
        size: this.paginator.pageSize.toString(),
        page: this.paginator.pageIndex.toString(),
        sort: 'processedDate,desc'
      }
    } else {
      page = {size: '10', page: '0', sort: 'processedDate,desc'}
    }
    this.dataSource.loadData(`/${this.currentContext.currentRetailer.id}/accounts/payouts/summary`, page, filters);
  }

  getDateRange(event: CustomDateRangeChangeEventModel) {
    this.dateRange = event.dateRange;
    this.loadPayoutSummary()
  }

  filterPayoutSummary($event: MatSelectChange): void {
    this.loadPayoutSummary();
  }
}
