import {Component} from '@angular/core';

@Component({
  selector: 'app-settings-billing-and-banking',
  templateUrl: './settings-billing-and-banking.component.html',
  styleUrls: ['./settings-billing-and-banking.component.scss']
})
export class SettingsBillingAndBankingComponent {
  editing: "billing" | "banking" | null = null;
}
