import {HttpClient} from "@angular/common/http";
import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {DateRange} from "@angular/material/datepicker";
import {MatDialog} from "@angular/material/dialog";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {LocationOffering} from "../../../core/model/location-offering.interface";
import {CurrentContextService} from "../../../core/services/security/current-context.service";
import {
  CustomDateRangeChangeEventModel
} from "../../../shared/components/custom-date-filter/custom-date-range-change-event.model";
import {CustomDateRangeModel} from "../../../shared/components/custom-date-filter/custom-date-range.model";
import {DateUtilsService} from "../../../shared/services/dateUtils.service";
import {PaginatorService} from "../../../shared/services/paginator.service";
import {UtilsService} from "../../../shared/services/utils.service";
import {ChartOptionsService} from "../chart-options/chart-options.service";
import {DashboardsInfoDialogComponent} from "../common/dashboards-info-dialog/dashboards-info-dialog.component";
import {DashboardUtilsService} from "../dashboard-utils.service";
import {
  DashboardChart,
  MonthlyRetailerStats,
  MonthlyStat,
  RetailerProductPerformance,
  StatisticType
} from "../dashboards.model";
import {DashboardsRetailerReportChartConfig} from "./dashboards-retailer-report-chart-config";
import {DashboardsRetailerReportService} from "./dashboards-retailer-report.service";

@Component({
  selector: 'app-dashboards-retailer-report',
  templateUrl: './dashboards-retailer-report.component.html',
  styleUrls: ['./dashboards-retailer-report.component.scss']
})
export class DashboardsRetailerReportComponent implements OnInit, AfterViewInit {
  @ViewChild('bestSellersPaginator') bestSellersPaginator!: MatPaginator;
  @ViewChild('forecastPaginator') forecastPaginator!: MatPaginator;

  @ViewChild('sortProducts', { read: MatSort, static: false }) set productsContent(sort: MatSort) {
    if (this.bestSellersDatasource) this.bestSellersDatasource.sort = sort;
  }
  @ViewChild('sortInventory', { read: MatSort, static: false }) set inventoryContent(sort: MatSort) {
    if (this.inventoryForecastDatasource) this.inventoryForecastDatasource.sort = sort;
  }

  retailerName!: string;

  chartConfig: DashboardsRetailerReportChartConfig;
  dashboardService: DashboardsRetailerReportService;

  // best stats

  bestStats: MonthlyRetailerStats = new MonthlyRetailerStats({
    bestMonthlyStats: [],
    tradingOutsideOfLeasePeriod: false
  });

  // dashboard charts

  salesPerformanceChart: DashboardChart<'bar' | 'line'>;
  rentPerformanceChart: DashboardChart<'bar'>;
  avgBasketSizeChart: DashboardChart<'line'>;
  busiestSalesTimesChart: DashboardChart<'bar'>;
  busiestSalesDaysChart: DashboardChart<'bar'>;

  // dashboard tables

  bestSellersDatasource!: MatTableDataSource<RetailerProductPerformance>;
  inventoryForecastDatasource!: MatTableDataSource<RetailerProductPerformance>;

  bestSellersDescription!: string;
  inventoryForecastDescription!: string;

  bestSellersLoading: boolean = false;
  inventoryForecastLoading: boolean = false;

  displayedColumnsSellers: string[] = ['icon', 'productName', 'quantitySold', 'saleAmount', 'contributingPercentage'];
  displayedColumnsForecast: string[] = ['icon', 'productName', 'quantitySold', 'avgQuantitySold', 'currentInventoryLevel', 'weeksToLast'];

  // filtering

  dateRanges: DateRange<Date | null>[] = [
    new DateRange<Date | null>(null, null),
    new DateRange<Date | null>(null, null),
    new DateRange<Date | null>(null, null),
    new DateRange<Date | null>(null, null),
    new DateRange<Date | null>(null, null)
  ];

  customDateRangePresets1: CustomDateRangeModel[] = [];
  customDateRangePresets2: CustomDateRangeModel[] = [];
  dbOffering!: LocationOffering;

  topPercentSalesOptions: number[] = [20, 40, 60, 80, 100];

  topFilter: number = 100;

  //

  constructor(
    public utils: UtilsService,
    public dateUtils: DateUtilsService,
    private paginatorService: PaginatorService,
    private dashboardUtils: DashboardUtilsService,
    httpClient: HttpClient,
    chartOptionsService: ChartOptionsService,
    private context: CurrentContextService,
    private matDialog: MatDialog) {
    this.dashboardService = new DashboardsRetailerReportService(httpClient, context);
    this.chartConfig = new DashboardsRetailerReportChartConfig(this, chartOptionsService, this.dateUtils);

    this.salesPerformanceChart = {
      chartData: {labels: [], datasets: [{data: []}]},
      chartName: 'Sales vs Trading Density',
      chartDescription: 'The total turnover in comparison to sales made per square meter. This graph will show whether sales and trading densities are increasing or decreasing over time. Typically the higher the trading density the better.',
      config: this.chartConfig.salesPerformance.options,
      chartType: 'line',
      stats: {
        totalSales: new MonthlyStat('Sales', 'totalSales', 'Total sales made. \n Total money spent by customers', StatisticType.CURRENCY),
        tradingDensity: new MonthlyStat('Trading density', 'tradingDensity', 'Amount of sales per square meter of the leased space', StatisticType.CURRENCY_PER_SQM),
      },
      loading: false
    };

    this.rentPerformanceChart = {
      chartData: {labels: [], datasets: [{data: []}]},
      chartName: 'Rental Rate vs Rent Ratio',
      chartDescription: 'The Rent Ratio is the percentage of turnover contributed towards rent – the lower this percentage the better. This graph can be used to understand whether rent ratios are improving or worsening over time. A stable, sustainable rent ratio is ideal; sudden fluctuations or increases may indicate potential issues.',
      config: this.chartConfig.rentPerformance.options,
      chartType: 'bar',
      stats: {
        rentalRate: new MonthlyStat('Rental Rate', 'rentalRate', 'Rent charged per square meter for the leased space', StatisticType.CURRENCY_PER_SQM),
        rentRatio: new MonthlyStat('Rent Ratio', 'rentRatio', 'Sales to Rental fee ratio.', StatisticType.PERCENTAGE),
      },
      loading: false
    };

    this.avgBasketSizeChart = {
      chartData: {labels: [], datasets: [{data: []}]},
      chartName: 'Average Basket Size',
      chartDescription: 'The basket size is the average size of a sale during the selected period. This can give a good idea on the value and number of items a customer buys at a time.',
      config: this.chartConfig.avgBasketSize.options,
      chartType: 'line',
      stats: {},
      loading: false
    };

    this.busiestSalesTimesChart = {
      chartData: {labels: [], datasets: [{data: []}]},
      chartName: 'Hourly Sales',
      chartDescription: 'The total revenue generated per hour of the day, during the selected period. This gives an indication of the most popular trading times.',
      config: this.chartConfig.busiestSalesTimes.options,
      chartType: 'bar',
      stats: {
        salesPerHour: new MonthlyStat('Hourly sales', 'salesPerHour', 'Average sales made per hour', StatisticType.CURRENCY),
      },
      loading: false
    };

    this.busiestSalesDaysChart = {
      chartData: {labels: [], datasets: [{data: []}]},
      chartName: 'Daily Sales',
      chartDescription: 'The total revenue generated per day of the week, during the selected period. This gives an indication of the busiest trading days.',
      config: this.chartConfig.busiestSalesDays.options,
      chartType: 'bar',
      stats: {
        salesPerDay: new MonthlyStat('Daily sales', 'salesPerDay', 'Average sales made per day', StatisticType.CURRENCY),
      },
      loading: false
    };

    this.bestSellersDescription = 'This table shows how well each product is performing. The information can be sorted by any of the columns to understand the contribution each product makes towards total sales.';
    this.inventoryForecastDescription = 'This table estimates how many weeks the current stock will last, based on actual inventory data and quantity sold for the selected period. This calculation is dependent on the accuracy of the data recorded in the point-of-sale software.'
  }

  ngOnInit() {
    this.retailerName = this.context.currentRetailer.companyName;
    this.dbOffering = this.context.getCurrentOffering()!
    this.loadBestMonthlyStats();
    this.setCustomDateRange();
  }

  ngAfterViewInit() {
    this.bestSellersPaginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    this.forecastPaginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
  }

  setCustomDateRange() {
    this.customDateRangePresets1 = this.dashboardUtils.customDateRangeChartPreset(this.dbOffering.offeringStartDate!);
    this.customDateRangePresets2 = this.dashboardUtils.customDateRangeTablePreset(this.dbOffering.offeringStartDate!);
  }

  loadBestMonthlyStats() {
    if (this.retailerName) {
      const next = this.chartConfig.bestMonthlyStats.next;
      const error = this.chartConfig.bestMonthlyStats.error;
      this.dashboardService.refreshBestMonthlyStats(next, error);
    }

  }

  loadSalesPerformanceChart() {
    if (this.dateRanges[0].start && this.dateRanges[0].end) {
      this.salesPerformanceChart.loading = true
      const start = this.dateRanges[0].start;
      const end = this.dateRanges[0].end;
      const next = this.chartConfig.salesPerformance.next;
      const error = this.chartConfig.salesPerformance.error;
      this.dashboardService.refreshSalesPerformanceChart(this.dateUtils.displayShortDate(start)!, this.dateUtils.displayShortDate(end)!, next, error);
    }
  }

  loadRentPerformanceChart() {
    if (this.dateRanges[1].start && this.dateRanges[1].end) {
      this.rentPerformanceChart.loading = true;
      const start = this.dateRanges[1].start;
      const end = this.dateRanges[1].end;
      const next = this.chartConfig.rentPerformance.next;
      const error = this.chartConfig.rentPerformance.error;
      this.dashboardService.refreshRentalRateRentRatioChart(this.dateUtils.displayShortDate(start)!,
        this.dateUtils.displayShortDate(end)!, next, error);
    }
  }

  loadAvgBasketSizeChart() {
    if (this.dateRanges[1].start && this.dateRanges[1].end) {
      this.avgBasketSizeChart.loading = true;
      const start = this.dateRanges[1].start;
      const end = this.dateRanges[1].end;
      const next = this.chartConfig.avgBasketSize.next;
      const error = this.chartConfig.avgBasketSize.error;
      this.dashboardService.refreshAvgBasketSizeChart(this.dateUtils.displayShortDate(start)!,
        this.dateUtils.displayShortDate(end)!, next, error);
    }
  }

  loadBusiestSalesTimesChart() {
    if (this.dateRanges[2].start && this.dateRanges[2].end) {
      this.busiestSalesTimesChart.loading = true;
      const start = this.dateRanges[2].start;
      const end = this.dateRanges[2].end;
      const next = this.chartConfig.busiestSalesTimes.next;
      const error = this.chartConfig.busiestSalesTimes.error;
      this.dashboardService.refreshBusiestSalesTimesChart(this.dateUtils.displayShortDate(start)!,
        this.dateUtils.displayShortDate(end)!, next, error);
    }
  }

  loadBusiestSalesDaysChart() {
    if (this.dateRanges[2].start && this.dateRanges[2].end) {
      this.busiestSalesDaysChart.loading = true;
      const start = this.dateRanges[2].start;
      const end = this.dateRanges[2].end;
      const next = this.chartConfig.busiestSalesDays.next;
      const error = this.chartConfig.busiestSalesDays.error;
      this.dashboardService.refreshBusiestSalesDaysChart(this.dateUtils.displayShortDate(start)!,
        this.dateUtils.displayShortDate(end)!, next, error);
    }
  }

  loadBestSellersList() {
    if (this.dateRanges[3].start && this.dateRanges[3].end) {
      this.bestSellersLoading = true;
      const start = this.dateRanges[3].start;
      const end = this.dateRanges[3].end;
      const topPercent = this.topFilter;
      const next = this.chartConfig.bestSellersList.next;
      const error = this.chartConfig.bestSellersList.error;
      this.dashboardService.refreshBestSellers(this.dateUtils.displayShortDate(start)!,
        this.dateUtils.displayShortDate(end)!, topPercent, next, error);
    }
  }

  loadInventoryForecastList() {
    if (this.dateRanges[4].start && this.dateRanges[4].end) {
      this.inventoryForecastLoading = true;
      const start = this.dateRanges[4].start;
      const end = this.dateRanges[4].end;
      const next = this.chartConfig.inventoryForecastList.next;
      const error = this.chartConfig.inventoryForecastList.error;
      this.dashboardService.refreshInventoryForecast(this.dateUtils.displayShortDate(start)!,
        this.dateUtils.displayShortDate(end)!, next, error);
    }
  }

  loadCharts() {
    this.loadBestMonthlyStats();
    this.loadSalesPerformanceChart();
    this.loadRentPerformanceChart();
    this.loadAvgBasketSizeChart();
    this.loadBusiestSalesTimesChart();
    this.loadBusiestSalesDaysChart();
    this.loadBestSellersList();
    this.loadInventoryForecastList();
  }

  getDateRange(event: CustomDateRangeChangeEventModel, index: number) {
    this.dateRanges[index] = event.dateRange;
    switch (index) {
      case 0:
        this.loadSalesPerformanceChart();
        break;
      case 1:
        this.loadRentPerformanceChart();
        this.loadAvgBasketSizeChart();
        break
      case 2:
        this.loadBusiestSalesTimesChart();
        this.loadBusiestSalesDaysChart();
        break;
      case 3:
        this.loadBestSellersList();
        break;
      case 4:
        this.loadInventoryForecastList();
        break;
    }
  }

  openInfoDialog() {
    this.matDialog.open(DashboardsInfoDialogComponent, {
      panelClass: 'dialog-large',
      height: '450px',
      width: '600px',
      backdropClass: 'dialog-backdrop-opaque',
    })
  }

}

