<div class="page-padding py-3 background-white" fxLayout="column">
  <div
    class="curate-page-header"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxFlexOffset="20px"
  >
    <div class="title-default" [ngClass.gt-sm]="'title-large'">Application form</div>
    <div class="subtitle-regular">
      Tell us a bit more about your business and how you run it
    </div>
  </div>

  <div
    class="questionnaire-container"
    fxLayout="column"
    fxLayoutAlign="start center"
    fxFlexOffset="20px"
    fxFlexOffset.gt-sm="50px"
    [ngClass.gt-sm]="'p-3'"
  >
    <mat-stepper
      #pageStepper
      color="primary"
      linear
      class="questionnaire-wrapper"
      [orientation]="(stepperOrientation | async)!"
      fxLayoutGap="20px"
    >
      <mat-step
        [completed]="steps[0].complete"
        [label]="steps[0].label"
        [optional]="steps[0].optional"
        [editable]="steps[0].editable"
      >
        <app-application-signup-user
          #signupUserComponent
          *ngIf="signup"
          (login)="toggleSignup(false)"
        >
        </app-application-signup-user>

        <app-application-form-login
          #loginComponent
          *ngIf="!signup"
          (signup)="toggleSignup(true)"
        >
        </app-application-form-login>

        <div class="info-card-grey-footer full-width"
             fxLayout="row" fxLayoutAlign="end center">
          <button mat-flat-button class="button-primary" (click)="step1Next()" [disabled]="!canSubmit()">
            <span class="button-text">Next</span>
          </button>
        </div>
      </mat-step>

      <mat-step
        [completed]="steps[1].complete"
        [label]="steps[1].label"
        [optional]="steps[1].optional"
        [editable]="steps[1].editable"
      >
        <app-application-signup-retailer
          #signupRetailerComponent
        ></app-application-signup-retailer>
        <div class="info-card-grey-footer"
             fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px">
          <button mat-flat-button class="button-secondary" matStepperPrevious>
            <span class="button-text">Back</span>
          </button>
          <button (click)="signUpRetailerNext()" [disabled]="!canSubmit()" class="button-primary" mat-flat-button
                  matStepperNext>
            <span class="button-text">Next</span>
          </button>
        </div>
      </mat-step>

      <mat-step [completed]="steps[2].complete"
                [label]="steps[2].label"
                [optional]="steps[2].optional"
                [editable]="steps[2].editable">
        <app-offering-application-form class="full-width"
                #offeringApplicationForm
                (assessmentStatus)="assessmentStatusChange($event)"
                (submitError)="submitError($event)">
        </app-offering-application-form>

        <mat-divider></mat-divider>

        <div class="info-card-grey-middle full-width flex-row" fxLayoutAlign="center center">
          <app-footer-links [footerText]="FOOTER_TEXT_STYLE.APPLICATION"></app-footer-links>
        </div>

        <div class="info-card-grey-footer"
             fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px">
          <button mat-flat-button class="button-secondary" (click)="step2Back()">
            <span class="button-text">Back</span>
          </button>
          <button mat-flat-button class="button-primary" (click)="triggerSubmit()" [disabled]="!canTriggerSubmit()">
            <span class="button-text">Next</span>
          </button>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Done</ng-template>
        <div class="info-card-grey subtitle-regular px-2 text-center" fxLayout="column" fxLayoutAlign="center center">
          <div>Your application has successfully been submitted.</div>
          <div>You can view your application and other listings by clicking the button below</div>
        </div>
        <div class="info-card-grey-footer"
             fxLayout="row" fxLayoutAlign="center center">
          <button mat-flat-button class="button-primary" (click)="routeUserAfterSubmit()">
            <span class="button-text">View listings</span>
          </button>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
</div>
