export abstract class Auditable {
  uuid?: string;
  id?: number
  active?: boolean;
  createDate?: Date;
  createUserId?: number;
  updateDate?: Date;
  updateUserId?: number;
  verified?: boolean;
}
