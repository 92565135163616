import {Component, Input, OnInit} from '@angular/core';
import {UtilsService} from "../../../../shared/services/utils.service";
import {DateUtilsService} from "../../../../shared/services/dateUtils.service";
import {LeasingService} from "../../leasing.service";
import {LeaseSummary} from "../../leasing.model";

@Component({
  selector: 'app-lease-details',
  templateUrl: './lease-details.component.html',
  styleUrls: ['./lease-details.component.scss']
})
export class LeaseDetailsComponent implements OnInit {
  @Input() leaseSummary!: LeaseSummary | null;

  constructor(public utils: UtilsService, public dateUtils: DateUtilsService, private leasingService: LeasingService) {
  }

  ngOnInit() {
  }

  displayRentalType(type: string): string {
    return this.leasingService.getRentalType(type);
  }

  getLeaseStatus(lease: LeaseSummary) {
    switch (lease.leaseStatus) {
      case 'APPROVED':
        return { status: this.utils.displayStatus('APPROVED'), icon: 'check_circle', outline: true, className: 'success-chip'};
      case 'ACTIVE':
        return { status: this.utils.displayStatus('ACTIVE'), icon: 'check_circle', outline: false, className: 'success-chip'};
      case 'EXPIRED':
        return { status: this.utils.displayStatus('EXPIRED'), icon: 'check_circle', outline: false, className: 'disabled-chip'};
      case 'TERMINATED':
        return { status: this.utils.displayStatus('TERMINATED'), icon: 'cancel', outline: false, className: 'disabled-chip'};
      default:
        return { status: this.utils.displayStatus('PLANNED'), icon: 'check_circle', outline: true, className: 'warn-chip'};
    }
  }

}
