import {DateRange} from '@angular/material/datepicker';
import * as moment from "moment/moment";
import {DurationInputArg2} from "moment";

export class CustomDateRangeModel {
  private _presetId: string;
  private _numericValue: number;
  private _timeFrame: TimeFrames;
  private _presetLabel?: string;
  private _excludeCurrentPeriod?: boolean = true;
  private _presetDateRange?: DateRange<Date>;

  constructor(presetId: string,
              numericValue: number,
              timeFrame: TimeFrames,
              presetLabel?: string,
              _excludeCurrentPeriod?: boolean,
              presetDateRange?: DateRange<Date>,
              isFutureDate?: boolean) {
    this._presetId = presetId;
    this._numericValue = numericValue;
    this._timeFrame = timeFrame;
    this._presetLabel = presetLabel;
    this._presetDateRange = presetDateRange;
    this._excludeCurrentPeriod = _excludeCurrentPeriod;
    this._isFutureDate = isFutureDate;
  }

  private _isFutureDate?: boolean = false;

  public getReadableFormat(): string {
    if (this._presetLabel) {
      return this._presetLabel;
    }
    return `${this._numericValue} ${this._timeFrame}` + (this._numericValue > 1 ? 's' : '');
  }
  /*@param firstDayOfWeekIndex: starts at 0 for Sunday, 1 for Monday etc*/
  private getFirstDayOfWeek(currentDate: Date, firstDayOfWeekIndex: number): Date {
    const dayOfWeek = currentDate.getDay(),
      firstDayOfWeek = new Date(currentDate),
      diff = dayOfWeek >= firstDayOfWeekIndex ?
        dayOfWeek - firstDayOfWeekIndex :
        6 - dayOfWeek

    firstDayOfWeek.setDate(currentDate.getDate() - diff)
    firstDayOfWeek.setHours(0, 0, 0, 0)

    return firstDayOfWeek
  }

  private getPreviousWeeks(currentDate: Date, numberOfWeeks: number): Date {
    currentDate.setDate(currentDate.getDate() - (7 * numberOfWeeks));
    return currentDate;
  }

  public getDateRange(): DateRange<Date> {
    let startDate: Date;
    let currentDate = new Date();
    let endDate: Date;
    switch (this._timeFrame) {
      case TimeFrames.DAYS:
        startDate = currentDate;
        endDate = currentDate;
        if (this._isFutureDate) {
          endDate.setDate(endDate.getDate() + this._numericValue);
          return new DateRange<Date>(startDate, endDate);
        } else {
          endDate.setDate(endDate.getDate() - this._numericValue);
          return new DateRange<Date>(endDate, new Date());
        }
      case TimeFrames.WEEK:
        if (this._isFutureDate) {
          startDate = currentDate;
          endDate = this.getNextWeeks(this.getFirstDayOfWeek(currentDate, 1), this._numericValue)
        } else {
          startDate = this.getPreviousWeeks(
            this.getFirstDayOfWeek(currentDate, 1),
            this._numericValue
          );
          // endDate = new Date(this.getFirstDayOfWeek(currentDate, 1).getDate() - (this.numericValue * 7));
          endDate = this._excludeCurrentPeriod ? this.getLastDayOfPreviousPeriod(TimeFrames.WEEK) : new Date();
        }
        return new DateRange<Date>(startDate, endDate);
      case TimeFrames.MONTH:
        if (this._isFutureDate) {
          currentDate.setMonth(currentDate.getMonth() + this._numericValue);
          startDate = moment(currentDate).startOf('month').toDate();
          endDate = moment(startDate).endOf('month').toDate();
        } else {
          startDate = this.getPreviousMonths(this._numericValue);
          endDate = this._excludeCurrentPeriod ? this.getLastDayOfPreviousPeriod(TimeFrames.MONTH) : new Date();
        }
        return new DateRange<Date>(startDate, endDate);
      case TimeFrames.YEAR:
        if (this._isFutureDate) {
          currentDate.setFullYear(currentDate.getFullYear() + this._numericValue);
          startDate = this.getFirstDayOfYear(currentDate);
          endDate = moment(startDate).endOf('year').toDate();
        } else {
          startDate = this.getPreviousYears(this._numericValue);
          endDate = this._excludeCurrentPeriod ? this.getLastDayOfPreviousPeriod(TimeFrames.YEAR) : new Date();
        }
        return new DateRange<Date>(startDate, endDate);
      case TimeFrames.DATE_RANGE:
        return new DateRange<Date>(this._presetDateRange!.start, this._presetDateRange!.end);
      default:
        startDate = endDate = new Date();
    }
    return new DateRange(startDate, endDate);
  }

  private getPreviousMonths(numberOfMonths: number): Date {
    let currentDate = new Date();
    let currentMonth = currentDate.getMonth();
    currentDate.setMonth(currentDate.getMonth() - numberOfMonths);

    if (this._numericValue === 0) {
      return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    }

// If still in same month, set date to last day of
// previous month
    if (currentDate.getMonth() == currentMonth) currentDate.setDate(0);
    currentDate.setHours(0, 0, 0, 0);

// Get The first date of the month
    return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  }

  private getNextWeeks(currentDate: Date, numberOfWeeks: number): Date {
    currentDate.setDate(currentDate.getDate() + (7 * numberOfWeeks));
    return currentDate;
  }


  private getPreviousYears(numberOfYears: number): Date {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    currentDate.setFullYear(currentDate.getFullYear() - numberOfYears);

// If still in same year, set date to first day of current year
    if (currentDate.getFullYear() == currentYear) currentDate.setMonth(0);
    currentDate.setHours(0, 0, 0, 0);

// Get The first date of the year
    return new Date(currentDate.getFullYear(), 0, 1)
  }

  private getFirstDayOfYear(currentDate: Date): Date {
    return new Date(currentDate.getFullYear(), 0, 1);
  }

  private getLastDayOfPreviousPeriod(timeFrame: TimeFrames): Date {
    let period: DurationInputArg2 = "day";
    switch (timeFrame) {
      case TimeFrames.WEEK:
        period = "week";
        break;
      case TimeFrames.MONTH:
        period = "month";
        break;
      case TimeFrames.YEAR:
        period = "year";
        break;
    }
    return new Date(moment().subtract(1, period).endOf(period).format('YYYY-MM-DD'));
  }

  get presetId(): string {
    return this._presetId;
  }

  set presetId(value: string) {
    this._presetId = value;
  }

  get numericValue(): number {
    return this._numericValue;
  }

  set numericValue(value: number) {
    this._numericValue = value;
  }

  get timeFrame(): TimeFrames {
    return this._timeFrame;
  }

  set timeFrame(value: TimeFrames) {
    this._timeFrame = value;
  }

  set isFutureDate(value: boolean) {
    this._isFutureDate = value;
  }
}

export enum TimeFrames {
  DAYS = 'Day',
  WEEK = 'Week',
  MONTH = 'Month',
  YEAR = 'Year',
  DATE_RANGE = 'Date Range'
}
