<div *ngIf="payload" class="curation-payload page-padding"
     [class.form-page]="offeringApplicationService.getFormState == FormState.IN_APP"
     [class.page-padding]="offeringApplicationService.getFormState == FormState.IN_APP">
  <div class="info-card form-info-card px-0" fxLayout="column"
       [class.info-card-grey]="offeringApplicationService.getFormState != FormState.IN_APP">
    <div class="full-width" *ngFor="let cat of sortItemsByDisplayOrder(payload.categories); let i = index; let lastCat = last">
      <div fxLayout="column" fxLayoutAlign="start start">

        <div class="question-card full-width ps-0" fxLayout="column" fxLayoutGap="10px" fxFlexOffset="30px"
             *ngFor="let questionGroup of sortItemsByDisplayOrder(cat.questionGroups); let j = index; let lastQG = last">

          <div *ngIf="!questionGroup.repeatedInput; else repeatingQuestionTemplate" class="full-width"
               fxLayout="row" fxLayoutAlign="start start">

            <div class="question-card-header" fxLayout="column" fxLayoutAlign="start start" style="width: 24px">
              <mat-icon class="more-icon">more_vert</mat-icon>
            </div>

            <div
              *ngFor="let question of sortItemsByDisplayOrder(questionGroup.questions); let k = index; let lastQ = last"
              class="full-width px-4" fxLayout="column"
              fxLayoutAlign="start start"
              fxLayoutGap="5px" fxLayoutGap.gt-sm="15px">

              <ng-container *ngIf="getQuestionTemplate(question); let qt">
                <div class="full-width" fxLayout="column" fxLayoutGap="20px">
                  <pre class="pt-1"
                       [ngClass]="(qt.controlType == ControlType.HEADING || qt.controlType == ControlType.HEADING_TEXT)
                       ? 'subtitle-default' : 'heading-bold'">{{payload!.formCode.includes('MVP') ? qt.controlPayload.label : qt.controlDescription}}</pre>
                  <div>

                    <div *ngIf="qt.controlType == ControlType.HEADING_TEXT">
                      <pre class="heading-regular">{{qt.controlPayload.label ? qt.controlPayload.label : 'Your sub-heading goes here'}}</pre>
                    </div>

                    <div *ngIf="qt.controlType == ControlType.TEXT">
                      <mat-form-field class="custom-text-field-full-width form-input-field" appearance="outline" hideRequiredMarker>
                        <input matInput placeholder="Enter your answer" [required]="question.required"
                               [disabled]="offeringApplicationService.isReadonly"
                               [(ngModel)]="question.value">
                        <mat-error class="link-error" *ngIf="question.required && !question.value">This field is required</mat-error>
                      </mat-form-field>
                    </div>

                    <div *ngIf="qt.controlType == ControlType.SELECT">
                      <mat-selection-list class="custom-selection-list-outline"
                                          fxLayout="column" fxLayoutGap="5px"
                                          [(ngModel)]="question.value"
                                          [required]="question.required"
                                          [disabled]="offeringApplicationService.isReadonly"
                                          *ngIf="qt.controlPayload.selectType == 'MULTI'">
                        <mat-list-option *ngFor="let option of getQuestionOptions(question)"
                                         [value]="option.valueCode"
                                         togglePosition="before">
                          <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
                            <div>{{option.label}}</div>
                          </div>
                        </mat-list-option>
                      </mat-selection-list>

                      <mat-selection-list class="custom-selection-list-outline"
                                          fxLayout="column" fxLayoutGap="5px"
                                          [multiple]="false"
                                          [(ngModel)]="question.value"
                                          [required]="question.required"
                                          *ngIf="qt.controlPayload.selectType == 'SINGLE'">
                        <mat-list-option *ngFor="let option of getQuestionOptions(question)"
                                         [value]="option.valueCode"
                                         togglePosition="before">
                          <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
                            <div>{{option.label}}</div>
                          </div>
                        </mat-list-option>
                      </mat-selection-list>
                    </div>

                    <div *ngIf="qt.controlType == ControlType.CHOICE">
                      <mat-selection-list class="custom-selection-list-outline"
                                          fxLayout="column" fxLayoutGap="5px"
                                          [(ngModel)]="question.value"
                                          [required]="question.required"
                                          [multiple]="false">
                        <mat-list-option togglePosition="before" [value]="qt.controlPayload.selectedOption!.valueCode"
                                         *ngIf="offeringApplicationService.isReadonly ? question.value[0] == qt.controlPayload.selectedOption!.valueCode : true">Yes</mat-list-option>
                        <mat-list-option togglePosition="before" [value]="qt.controlPayload.unselectedOption!.valueCode"
                                         *ngIf="offeringApplicationService.isReadonly ? question.value[0] == qt.controlPayload.unselectedOption!.valueCode : true">No</mat-list-option>
                      </mat-selection-list>
                    </div>

                    <div *ngIf="qt.controlType == ControlType.LOCATION">
                      <mat-form-field class="custom-text-field-full-width form-input-field" appearance="outline" hideRequiredMarker>
                        <input
                          matInput
                          matGoogleMapsAutocomplete
                          [placeholder]="question.value ? question.value.address.formatted_address! : 'Enter your location'"
                          [required]="question.required"
                          [disabled]="offeringApplicationService.isReadonly"
                          (onAutocompleteSelected)="onAddressAutocompleteSelected($event)"
                          (onLocationSelected)="onAddressLocationSelected($event); changeValue($event, i, j, k, qt.controlType.toString(), qt)">
                        <mat-error class="link-error" *ngIf="question.required && !question.value">This field is required</mat-error>
                      </mat-form-field>
                    </div>

                    <div *ngIf="qt.controlType == ControlType.FILE" fxLayout="row" fxLayoutGap="15px">

                      <div *ngFor="let file of getQuestionFiles(question)">
                        <input [id]="'file-upload-' + file.valueCode! + '-' + qt.uuid" type="file" #fileUpload
                               [required]="question.required"
                               [disabled]="offeringApplicationService.isReadonly"
                               (change)="changeValue($event, i, j, k, qt.controlType.toString(), qt, file.valueCode!)"
                               [accept]="qt.controlPayload.fileTypes!" hidden/>

                        <label class="btn-cursor" [for]="'file-upload-' + file.valueCode! + '-' + qt.uuid">
                          <div class="file-upload-container btn-cursor"
                               fxLayout="column" fxLayoutAlign="end center" matRipple
                               *ngIf="!getFileInfo(question, file.valueCode!) else fileImage">
                            <mat-icon class="material-icons-outlined">upload_file</mat-icon>
                            <div class="body-1-regular-2 pt-3">Upload {{file.label}}</div>
                            <div class="body-1-regular-2 pb-3">Click to upload</div>
                          </div>
                        </label>

                        <mat-error class="link-error" *ngIf="question.required && !checkFileValidity(question, file.valueCode!, fileUpload)">This field is required</mat-error>
                        <mat-error class="link-error" *ngIf="fileUploadErrorMessage(question, file.valueCode!)">{{fileUploadErrorMessage(question, file.valueCode!)}}</mat-error>

                        <ng-template #fileImage>
                          <div fxLayout="column"  fxLayoutGap="15px" *ngIf="getFileInfo(question, file.valueCode!) && getFileInfo(question, file.valueCode!)!.fileName != ''">
                            <div class="file-preview">
                              <img class="file-preview-image"
                                   [src]="getImageUrl(question, file.valueCode!)"
                                   *ngIf="isFileTypeImage(question, file.valueCode!)">

                              <div class="file-upload-container btn-cursor"
                                   fxLayout="column" fxLayoutAlign="end center" matRipple
                                   *ngIf="!isFileTypeImage(question, file.valueCode!)"
                                   [class.top-div]="offeringApplicationService.isReadonly"
                                   [matTooltip]="offeringApplicationService.isReadonly ? 'Click to download file' : ''"
                                   (click)="downloadFormFile(qt.uuid, file.valueCode!)">
                                <mat-icon class="material-icons-outlined">upload_file</mat-icon>
                                <div class="body-1-regular-2 py-3 file-name">{{getFileInfo(question, file.valueCode!)!.fileName}}</div>
                              </div>

                              <div class="file-preview-content" fxLayout="column" fxLayoutAlign="space-between center">
                                <button class="button-close-small button-background" mat-icon-button fxFlexAlign="start"
                                        (click)="removeImage(question, $event, file.valueCode!, fileUpload)">
                                  <mat-icon *ngIf="!offeringApplicationService.isReadonly">close</mat-icon>
                                </button>

                                <div fxFlexOffset="5px" class="body-1-regular-2 py-3 file-name btn-cursor"
                                     *ngIf="isFileTypeImage(question, file.valueCode!)"
                                     [matTooltip]="offeringApplicationService.isReadonly ? 'Click to download file' : ''"
                                     (click)="downloadFormFile(qt.uuid, file.valueCode!)">
                                  {{getFileInfo(question, file.valueCode!)!.fileName}}
                                </div>
                              </div>

                            </div>
                          </div>
                        </ng-template>
                      </div>

                    </div>

                    <div *ngIf="qt.controlType == ControlType.INFO_FILE" fxLayout="row" fxLayoutGap="15px">

                      <div *ngFor="let file of getQuestionFiles(question)">

                        <label class="btn-cursor">
                          <div class="file-upload-container btn-cursor"
                               fxLayout="column" fxLayoutAlign="end center" matRipple
                               (click)="downloadInfoFile(qt.uuid, file.valueCode!)">
                            <mat-icon class="material-icons-outlined">file_download</mat-icon>
                            <div class="body-1-regular-2 pt-3">{{file.label}}</div>
                            <div class="body-1-regular-2 pb-3">Click to Download</div>
                          </div>
                        </label>
                      </div>

                    </div>

                    <div *ngIf="qt.controlType == ControlType.DATE">

                      <mat-form-field class="custom-date-picker-full-width form-input-field" appearance="outline" hideRequiredMarker>
                        <input matInput placeholder="Select your date"
                               [value]="question.value"
                               [required]="question.required"
                               [disabled]="offeringApplicationService.isReadonly"
                               (dateChange)="changeValue($event, i, j, k, qt.controlType.toString(), qt)"
                               [matDatepicker]="picker">
                        <mat-datepicker-toggle matIconSuffix [for]="picker">
                          <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>

                        <mat-error class="link-error" *ngIf="question.required && !question.value">This field is required</mat-error>
                      </mat-form-field>
                    </div>

                    <div *ngIf="qt.controlType == ControlType.NUMBER">
                      <mat-form-field class="custom-text-field-full-width form-input-field" appearance="outline" hideRequiredMarker>
                        <input matInput type="number" placeholder="Enter your answer"
                               [required]="question.required"
                               [disabled]="offeringApplicationService.isReadonly"
                               [(ngModel)]="question.value">
                        <div matPrefix class="ms-2 body-1-regular-1" *ngIf="qt.controlPayload.numberType == 'CURRENCY'">R </div>
                        <div matSuffix class="me-2 body-1-regular-1" *ngIf="qt.controlPayload.numberType == 'PERCENTAGE'"> %</div>
                        <mat-error class="link-error" *ngIf="question.required && !question.value">This field is required</mat-error>
                      </mat-form-field>
                    </div>

                    <div *ngIf="qt.controlType == ControlType.RANGE">
                      <app-custom-range-selector [question]="question"
                                                 [readonly]="offeringApplicationService.isReadonly"
                                                 [required]="question.required"
                                                 [editConfig]="false"
                                                 (rangeSelected)="changeValue($event, i, j,k, qt.controlType)"></app-custom-range-selector>
                    </div>

                  </div>
                </div>
              </ng-container>
            </div>

          </div>

          <ng-template #repeatingQuestionTemplate>
            <div *ngFor="let repeatedValue of questionGroup.repeatedValues;  let repeatedIdx = index; let first = first" class="full-width"
                 fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="start start">
              <div class="question-card-header" fxLayout="column" fxLayoutAlign="start start" style="width: 24px">
                <mat-icon class="more-icon" *ngIf="first">more_vert</mat-icon>
              </div>

              <div class="full-width px-4" fxLayout="column" fxLayoutGap="20px">
                <div class="heading-bold" *ngIf="first">{{questionGroup.displayLabel}}</div>

                <div class="full-width pe-4" fxLayout="row" fxLayoutGap="10px">
                  <div *ngFor="let rQuestion of repeatedValue; let rk = index" class="full-width" fxLayout="row"
                       fxLayoutAlign="center start" fxLayoutGap="5px" fxLayoutGap.gt-sm="15px">

                    <ng-container *ngIf="getQuestionTemplate(rQuestion); let rqt">
                      <div class="full-width" fxLayout="column" fxLayoutGap="10px">
                        <pre class="heading-bold pt-1">{{rqt.controlDescription}}</pre>

                        <div>

                          <div *ngIf="rqt.controlType == ControlType.HEADING_TEXT">
                            <pre class="heading-regular">{{rqt.controlPayload.label ? rqt.controlPayload.label : 'Your sub-heading goes here'}}</pre>
                          </div>

                          <div *ngIf="rqt.controlType == ControlType.TEXT">
                            <mat-form-field class="custom-text-field-full-width form-input-field" appearance="outline" hideRequiredMarker>
                              <input matInput placeholder="Enter your answer"
                                     [required]="rQuestion.required"
                                     [disabled]="offeringApplicationService.isReadonly"
                                     [(ngModel)]="rQuestion.value">
                              <mat-error class="link-error" *ngIf="rQuestion.required && !rQuestion.value">This field is required</mat-error>
                            </mat-form-field>
                          </div>

                          <div *ngIf="rqt.controlType == ControlType.SELECT">
                            <mat-selection-list class="custom-selection-list-outline"
                                                fxLayout="column" fxLayoutGap="5px"
                                                [(ngModel)]="rQuestion.value"
                                                [required]="rQuestion.required"
                                                [disabled]="offeringApplicationService.isReadonly"
                                                *ngIf="rqt.controlPayload.selectType == 'MULTI'">
                              <mat-list-option *ngFor="let option of getQuestionOptions(rQuestion)"
                                               [value]="option.valueCode"
                                               togglePosition="before">
                                <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
                                  <div>{{option.label}}</div>
                                </div>
                              </mat-list-option>
                            </mat-selection-list>

                            <mat-selection-list class="custom-selection-list-outline"
                                                fxLayout="column" fxLayoutGap="5px"
                                                [multiple]="false"
                                                [(ngModel)]="rQuestion.value"
                                                [required]="rQuestion.required"
                                                *ngIf="rqt.controlPayload.selectType == 'SINGLE'">
                              <mat-list-option *ngFor="let option of getQuestionOptions(rQuestion)"
                                               [value]="option.valueCode"
                                               togglePosition="before">
                                <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
                                  <div>{{option.label}}</div>
                                </div>
                              </mat-list-option>
                            </mat-selection-list>
                          </div>

                          <div *ngIf="rqt.controlType == ControlType.CHOICE">
                            <mat-selection-list class="custom-selection-list-outline"
                                                fxLayout="column" fxLayoutGap="5px"
                                                [(ngModel)]="rQuestion.value"
                                                [required]="rQuestion.required"
                                                [multiple]="false">
                              <mat-list-option togglePosition="before"
                                               [value]="rqt.controlPayload.selectedOption!.valueCode"
                                               *ngIf="offeringApplicationService.isReadonly ? rQuestion.value[0] == rqt.controlPayload.selectedOption!.valueCode : true">Yes</mat-list-option>
                              <mat-list-option togglePosition="before"
                                               [value]="rqt.controlPayload.unselectedOption!.valueCode"
                                               *ngIf="offeringApplicationService.isReadonly ? rQuestion.value[0] == rqt.controlPayload.unselectedOption!.valueCode : true">No</mat-list-option>
                            </mat-selection-list>
                          </div>

                          <div *ngIf="rqt.controlType == ControlType.LOCATION">
                            <mat-form-field class="custom-text-field-full-width form-input-field" appearance="outline" hideRequiredMarker>
                              <input
                                matInput
                                matGoogleMapsAutocomplete
                                placeholder="Enter your location"
                                [required]="rQuestion.required"
                                [disabled]="offeringApplicationService.isReadonly"
                                (onChange)="changeRepeatedValue($event, i, j, repeatedIdx, rk, rqt.controlType.toString())"
                                (onAutocompleteSelected)="onAddressAutocompleteSelected($event)"
                                (onLocationSelected)="onAddressLocationSelected($event)">
                              <mat-error class="link-error" *ngIf="rQuestion.required && !rQuestion.value">This field is required</mat-error>

                            </mat-form-field>
                          </div>

                          <div *ngIf="rqt.controlType == ControlType.FILE" fxLayout="row" fxLayoutGap="15px">

                            <div *ngFor="let file of getQuestionFiles(rQuestion)">
                              <input [id]="'file-upload-' + file.valueCode! + '-' + rqt.uuid" type="file" #fileUploadR
                                     [required]="rQuestion.required"
                                     [disabled]="offeringApplicationService.isReadonly"
                                     (change)="changeRepeatedValue($event, i, j, repeatedIdx, rk, rqt.controlType.toString(), rqt, file.valueCode!)"
                                     [accept]="rqt.controlPayload.fileTypes!" hidden/>

                              <label class="btn-cursor" [for]="'file-upload-' + file.valueCode! + '-' + rqt.uuid">
                                <div class="file-upload-container btn-cursor"
                                     fxLayout="column" fxLayoutAlign="end center" matRipple
                                     *ngIf="!getFileInfo(rQuestion, file.valueCode!) else fileImageR">
                                  <mat-icon class="material-icons-outlined">upload_file</mat-icon>
                                  <div class="body-1-regular-2 pt-3">Upload {{file.label}}</div>
                                  <div class="body-1-regular-2 pb-3">Click to upload</div>
                                </div>
                              </label>

                              <mat-error class="link-error" *ngIf="rQuestion.required && !checkFileValidity(rQuestion, file.valueCode!, fileUploadR)">This field is required</mat-error>

                              <ng-template #fileImageR>
                                <div fxLayout="column"  fxLayoutGap="15px" *ngIf="getFileInfo(rQuestion, file.valueCode!) && getFileInfo(rQuestion, file.valueCode!)!.fileName != ''">
                                  <div class="file-preview">
                                    <img class="file-preview-image" [src]="getImageUrl(rQuestion, file.valueCode!)" *ngIf="isFileTypeImage(rQuestion, file.valueCode!)">

                                    <div class="file-upload-container btn-cursor"
                                         fxLayout="column" fxLayoutAlign="end center" matRipple
                                         *ngIf="!isFileTypeImage(rQuestion, file.valueCode!)"
                                         [matTooltip]="offeringApplicationService.isReadonly ? 'Click to download file' : ''"
                                         (click)="downloadFormFile(rqt.uuid, file.valueCode!)">
                                      <mat-icon class="material-icons-outlined">upload_file</mat-icon>
                                      <div class="body-1-regular-2 py-3 file-name">{{getFileInfo(rQuestion, file.valueCode!)!.fileName}}</div>
                                    </div>

                                    <div class="file-preview-content" fxLayout="column" fxLayoutAlign="space-between center">
                                      <button class="button-close-small button-background" mat-icon-button fxFlexAlign="start"
                                              (click)="removeImage(rQuestion, $event, file.valueCode!, fileUploadR)">
                                        <mat-icon *ngIf="!offeringApplicationService.isReadonly">close</mat-icon>
                                      </button>

                                      <div fxFlexOffset="5px" class="body-1-regular-2 py-3 file-name btn-cursor"
                                           *ngIf="isFileTypeImage(rQuestion, file.valueCode!)"
                                           [matTooltip]="offeringApplicationService.isReadonly ? 'Click to download file' : ''"
                                           (click)="downloadFormFile(rqt.uuid, file.valueCode!)">
                                        {{getFileInfo(rQuestion, file.valueCode!)!.fileName}}
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </ng-template>
                            </div>

                          </div>

                          <div *ngIf="rqt.controlType == ControlType.INFO_FILE" fxLayout="row" fxLayoutGap="15px">

                            <div *ngFor="let file of getQuestionFiles(rQuestion)">

                              <label class="btn-cursor">
                                <div class="file-upload-container btn-cursor"
                                     fxLayout="column" fxLayoutAlign="end center" matRipple
                                     (click)="downloadInfoFile(rqt.uuid, file.valueCode!)">
                                  <mat-icon class="material-icons-outlined">file_download</mat-icon>
                                  <div class="body-1-regular-2 pt-3">{{file.label}}</div>
                                  <div class="body-1-regular-2 pb-3">Click to Download</div>
                                </div>
                              </label>
                            </div>

                          </div>

                          <div *ngIf="rqt.controlType == ControlType.DATE">

                            <mat-form-field class="custom-date-picker-full-width form-input-field" appearance="outline" hideRequiredMarker>
                              <input matInput placeholder="Select your date"
                                     [required]="rQuestion.required"
                                     [disabled]="offeringApplicationService.isReadonly"
                                     (dateChange)="changeRepeatedValue($event, i, j, repeatedIdx, rk, rqt.controlType.toString())"
                                     [matDatepicker]="picker">
                              <mat-datepicker-toggle matIconSuffix [for]="picker">
                                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                              </mat-datepicker-toggle>
                              <mat-datepicker #picker></mat-datepicker>
                              <mat-error class="link-error" *ngIf="rQuestion.required && !rQuestion.value">This field is required</mat-error>

                            </mat-form-field>
                          </div>

                          <div *ngIf="rqt.controlType == ControlType.NUMBER">
                            <mat-form-field class="custom-text-field-full-width form-input-field" appearance="outline" hideRequiredMarker>
                              <input matInput type="number" placeholder="Enter your answer"
                                     [required]="rQuestion.required"
                                     [disabled]="offeringApplicationService.isReadonly"
                                     [(ngModel)]="rQuestion.value">
                              <div matPrefix class="ms-2 body-1-regular-1" *ngIf="rqt.controlPayload.numberType == 'CURRENCY'">R </div>
                              <div matSuffix class="me-2 body-1-regular-1" *ngIf="rqt.controlPayload.numberType == 'PERCENTAGE'"> %</div>
                              <mat-error class="link-error" *ngIf="rQuestion.required && !rQuestion.value">This field is required</mat-error>

                            </mat-form-field>
                          </div>

                          <div *ngIf="rqt.controlType == ControlType.RANGE">
                            <app-custom-range-selector [question]="rQuestion"
                                                       [required]="rQuestion.required"
                                                       [readonly]="offeringApplicationService.isReadonly"
                                                       [editConfig]="false"
                                                       (rangeSelected)="changeRepeatedValue($event, i, j, repeatedIdx, rk, rqt.controlType)"></app-custom-range-selector>
                          </div>

                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>

              </div>

            </div>
          </ng-template>

          <div class="full-width" fxLayout="column" [fxHide]="lastCat && lastQG">
            <mat-divider class="full-width" fxFlexOffset="30px"></mat-divider>
          </div>

        </div>

      </div>

    </div>

  </div>
</div>



