import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MapsAddressUtilService {

  public decodeAddressComponentsWithInterface(addressComponents: google.maps.places.PlaceResult) {
    let retailerAddress: any = {};
    for (const addr of addressComponents.address_components!) {
      retailerAddress[addr.types[0]] = addr.long_name;

      if (addr.types[0].includes('country') || addr.types[0].includes('administrative_area_level_1')) {
        retailerAddress[addr.types[0] + '_code'] = addr.short_name;
      }
    }
    return retailerAddress;
  }

  public decodeAddressComponents(addressComponents: any[]): any {

    let retailerAddress: any = {};
    for (const addr of addressComponents) {
      retailerAddress[addr.types[0]] = addr.long_name;

      if (addr.types[0].includes('country') || addr.types[0].includes('administrative_area_level_1')) {
        retailerAddress[addr.types[0] + '_code'] = addr.short_name;
      }
    }
    return retailerAddress;
  }

  public parseAddressData(geometry: { latitude: any; longitude: any; }, place: { address_components: any[]; formatted_address: any; url: any; place_id: any; name: any; }, addressTypeCode ?: string): { address: any, addressPlace: any } {
    const address = this.decodeAddressComponents(place.address_components);
    address.addressTypeCode = addressTypeCode ? addressTypeCode : 'PHYSICAL';
    address.formatted_address = place.formatted_address;
    address.lat = geometry.latitude;
    address.long = geometry.longitude;
    address.maps_url = place.url;
    address.place_id = place.place_id;
    address.place_name = place.name;

    const addressPlace = {
      place_id: place.place_id,
      place: JSON.stringify(place)
    };

    return {address, addressPlace};
  }
}
