<div fxLayout="column">

  <app-page-header [banner]="false"
                   [section]="'Applications'"
                   [title]="'Get handpicked by the landlord'"
                   [description]="'The forms module allows you to see your latest application submission. Keep your application up to date to help the landlord identify placement opportunities.'">

  </app-page-header>

  <div class="page-content page-padding">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="start center">
        <span class="subtitle-default">Application details</span>
        <button *ngIf="offeringApplicationService.isReadonly" mat-flat-button class="button-action" (click)="toggleApplicationReadOnly(false)" [disabled]="(loader.loading$ | async) || (offering && !offering.hasActiveForm)"><div class="button-text">Update</div></button>
        <button *ngIf="!offeringApplicationService.isReadonly" mat-flat-button class="button-action" (click)="triggerSubmit()" [disabled]="(loader.loading$ | async) || !canSubmit()"><div class ="button-text">Submit</div></button>
        <button *ngIf="!offeringApplicationService.isReadonly" mat-flat-button class="button-action-cancel" (click)="toggleApplicationReadOnly(true)" [disabled]="loader.loading$ | async"><div class ="button-text">Cancel</div></button>
      </div>

      <div *ngIf="this.lastUpdatedMessage === null; else fullWording">
        <app-info-panel [type]="'info'"
                        [message]="'You can update your application anytime'"></app-info-panel>
      </div>
      <ng-template #fullWording>
        <app-info-panel [type]="'info'"
                        [message]="this.lastUpdatedMessage + 'You can update your application anytime'"></app-info-panel>
      </ng-template>

    </div>
  </div>

  <app-offering-application-form [lastUpdated]="lastUpdated"
                                 (lastUpdatedChange)="changeLastUpdated($event)"
                                 #offeringApplicationForm></app-offering-application-form>

  <div class="form-page page-padding pb-4">
    <div class="px-4 info-card-white-footer" fxLayout="row" fxLayoutAlign="center center">
<!--      <div style="width: 64px;"></div>-->

      <div *ngIf="!offeringApplicationService.isReadonly" class="next-button-container" fxLayout="row" fxLayoutAlign="center center"
           fxLayoutGap="25px">

        <button
          (click)="triggerSubmit()"
          [disabled]="(loader.loading$ | async) || !canSubmit()" class="button-primary"
          mat-flat-button
          style="width: 200px !important;"
        >
          <div class="button-text">{{ (loader.loading$ | async) ? "Working..." : "Submit Application" }}</div>
          <mat-icon *ngIf="(loader.loading$ | async)">
            <mat-spinner diameter="20"></mat-spinner>
          </mat-icon>
        </button>
      </div>

<!--      <div style="width: 64px;">-->
<!--        <div class="logo-container"-->
<!--             fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">-->
<!--          <img [src]="poweredByLogo" alt="system-logo" class="logo">-->
<!--          <div class="footer-regular">Powered by Nter</div>-->
<!--        </div>-->
<!--      </div>-->

    </div>
  </div>

</div>
