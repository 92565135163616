import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators,} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../core/services/user.service';
import {CurrentContextService} from '../../../core/services/security/current-context.service';
import {GoogleAnalyticsService} from '../../../core/services/google-analytics.service';
import {User} from '../../../core/model/user.model';
import {lastValueFrom} from 'rxjs';
import {FOOTER_TEXT_STYLE} from '../../../core/components/footer/footer-links/footer-links.component';
import {LoadingService} from "../../../core/services/loading.service";


@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.scss'],
})
export class RegisterUserComponent implements OnInit {
  registrationForm;
  public errMessage: string | null = null;
  public loading = false;
  hide = true;
  show = true;
  SIGNUP: FOOTER_TEXT_STYLE = FOOTER_TEXT_STYLE.SIGNUP;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private fb: FormBuilder,
    private context: CurrentContextService,
    private googleAnalyticsService: GoogleAnalyticsService,
    public loader: LoadingService
  ) {
    this.registrationForm = this.resetForm();
  }

  ngOnInit(): void {
  }

  submit() {
    if (this.passwordInvalid()) {
      return;
    }

    const user = this.registrationForm.value;

    this.loading = true;
    this.errMessage = null;
    lastValueFrom(this.userService.registerNewUser(user))
      .then((res: User) => {
          this.loading = false;
          this.context.setCurrentUserInContext(res);
          this.googleAnalyticsService.customEventEmitter('Retailer Onboarding', 'submit', 'hello_signup_submit');
          return Promise.resolve(true);
        },
        (err) => {
          this.loading = false;
          switch (err.status) {

            case 400:
              if ('ValidationException' === err.headers.get('error_code')) {
                this.errMessage = '' + err.headers.get('error_message');
              }
              break;
            default:
              console.log(err);
              this.errMessage = 'An unexpected error occurred, please try again';
          }
        }
      )
      .then((success: boolean | void) => {
        if (success === true) {
          let currentLocationCode: string | null = this.context.getCurrentLocationCode();
          this.router.navigate([
            `/${currentLocationCode}/account/verify`,
            {returnUrl: `${currentLocationCode}/retailer/brand-info`},
          ])
            .catch((error: string) => {
              console.error(error);
            });
        }
      });
  }

  gotoLogin(): void {
    this.router.navigate([`/${this.context.getCurrentLocationCode()}/login`])
      .catch((error: string) => {
        console.error(error);
      });
  }

  canSubmit(): boolean {
    return this.registrationForm.invalid || this.loading;
  }

  private resetForm(): FormGroup {
    return this.fb.group({
      firstname: [null, Validators.required],
      lastname: [null, Validators.required],
      email: [null, Validators.required],
      secret: [null, Validators.required],
      confirmPassword: [null, Validators.required],
    });
  }

  private passwordInvalid(): boolean {
    const secret = this.registrationForm.value.secret;
    const confirmSecret = this.registrationForm.value.confirmPassword;

    if (
      (secret.length < 8)
      || (/[A-Z]+/g.test(secret) === false)
      || (/[a-z]+/g.test(secret) === false)
      || (/[0-9]+/g.test(secret) === false)
      || (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(secret) === false)
    ) {
      this.registrationForm.controls['secret'].setErrors({'not-valid': true});
      this.registrationForm.get('confirmPassword')!.setValue(null);
      this.registrationForm.controls['confirmPassword'].setErrors(null);
      return true;
    }

    if (secret !== confirmSecret) {
      this.registrationForm.get('confirmPassword')!.setValue(null);
      this.registrationForm.controls['confirmPassword'].setErrors({'no-match': true});
      return true;
    }

    return false;
  }
}
