import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {CurrentContextService} from './security/current-context.service';
import {
  FormQuestionFilter,
  FormState,
  RetailerCurationForm
} from '../../features/applications/application-form/offering-application.model';
import {ActivatedRoute, Router} from "@angular/router";


@Injectable({
  providedIn: 'root'
})
export class OfferingApplicationService {

  private formState = new BehaviorSubject<FormState>(FormState.REGISTER);
  private applicationNew = new BehaviorSubject<boolean>(true);
  private processStep = new BehaviorSubject<number>(0);
  private readOnly = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private contextService: CurrentContextService, private router: Router, private route: ActivatedRoute) {
  }

  public getNewPublicAssessment(offeringUuid: string): Observable<RetailerCurationForm> {
    const uri = environment.apiRetailersHost + `/api/public/offering/${offeringUuid}/application`;

    let httpOpts = this.buildOfferingUuidHeader(offeringUuid);
    return this.http.get<RetailerCurationForm>(uri, httpOpts);
  }

  public getNewAssessment(offeringUuid?: string | null): Observable<RetailerCurationForm> {
    const uri = this.buildUrl() + '/new-template';

    if (offeringUuid !== null && offeringUuid !== undefined) {
      let httpOpts = this.buildOfferingUuidHeader(offeringUuid);
      return this.http.get<RetailerCurationForm>(uri, httpOpts);
    }
    return this.http.get<RetailerCurationForm>(uri);
  }

  public getCurrentByRetailerIdAndOffering(offeringUuid?: string | null): Observable<any> {
    if (offeringUuid !== null && offeringUuid !== undefined) {
      const httpOpts = this.buildOfferingUuidHeader(offeringUuid);
      return this.http.get<any>(this.buildUrl(), httpOpts);
    }
    return this.http.get<any>(this.buildUrl());
  }

  public getRetailerCurationStatus(offeringUuid?: string | null): Observable<string> {
    const uri = this.buildUrl() + '/status';
    if (offeringUuid !== null && offeringUuid !== undefined) {
      const httpOpts = this.buildOfferingUuidHeader(offeringUuid);
      return this.http.get<any>(uri, httpOpts);
    }
    return this.http.get(uri, {responseType: 'text'});
  }

  public getRetailerApplicationStatusForOffering(offeringUuid: string): Observable<string> {
    const uri = this.buildUrl() + `/status/${offeringUuid}`;
    let headersObject: { [key: string]: any } = {
      'x-offering-id': offeringUuid,
    };
    let httpOptions: Object = {
      headers: new HttpHeaders(headersObject),
      responseType: 'text'
    };
    return this.http.get<string>(uri, httpOptions);
  }

  public saveAssessment(request: any, offeringUuid?: string | null, correlationUuid?: string | null): Observable<RetailerCurationForm> {
    let httpOpts = this.buildHeaders(offeringUuid, correlationUuid);
    if (httpOpts != null) {
      return this.http.post<RetailerCurationForm>(this.buildUrl() + `/submit`, request, {headers: httpOpts});
    }
    return this.http.post<RetailerCurationForm>(this.buildUrl(), request);
  }

  public uploadFormFile(fileToUpload: File, correlationUuid: string | null): Observable<string> {
    const formData = new FormData();
    formData.append('file', fileToUpload);
    let httpOpts = this.buildHeaders(null, correlationUuid);

    if (httpOpts != null) {
      return this.http.post<string>(this.buildUrl() + `/file-upload`, formData, {headers: httpOpts, responseType: 'json'});
    }
    return this.http.post<string>(this.buildUrl() + `/file-upload`, formData, { responseType: 'json' });
  }

  public downloadFormFile(fileUuid: string, questionFilter: FormQuestionFilter): Observable<ArrayBuffer> {
    const requestParams = new HttpParams()
      .set('filter', JSON.stringify(questionFilter));
    const options = { responseType: 'arraybuffer' as 'json', params: requestParams };
    return this.http.get<ArrayBuffer>(this.buildUrl() + `/file-download/${fileUuid}`, options);
  }

  public requestCurationUpdate(request: string): any {
    const uri = this.buildUrl() + '/update';
    return this.http.post<any>(uri, request);
  }

  private buildUrl(): string {
    const retailerId = this.contextService.currentRetailer.id;
    return environment.apiRetailersHost + `/portal/offering/application/${retailerId}`;
  }

  private buildOfferingUuidHeader(offeringUuid: string): Object {
    let headersObject: { [key: string]: any } = {
      'x-offering-id': offeringUuid,
    };
    return {
      headers: new HttpHeaders(headersObject),
    };
  }

  public preSave(assessment: any, applicationOfferingUuid: null | string): Observable<string> {
    let httpOpts = this.buildHeaders(applicationOfferingUuid, null);
    if (httpOpts != null) {
      return this.http.post<string>(`${this.buildUrl()}/preSubmit`, assessment, {headers: httpOpts});
    }
    return this.http.post<string>(`${this.buildUrl()}/preSubmit`, assessment);
  }

  public get getFormState(): FormState {
    return this.formState.value;
  }

  public get isApplicationNew(): boolean {
    return this.applicationNew.value;
  }

  public get getProcessStep(): number {
    return this.processStep.value;
  }

  public get isReadonly(): boolean {
    return this.readOnly.value;
  }

  public setFormState(state: FormState): void {
    this.formState.next(state);
  }

  public toggleApplicationNew(isNew: boolean): void {
    this.applicationNew.next(isNew);
  }

  public setProcessStep(step: number): void {
    this.processStep.next(step);
  }

  public toggleFormReadOnly(readonly: boolean): void {
    this.readOnly.next(readonly);
  }

  private buildHeaders(offeringUuid: string | null | undefined, correlationId: string | null | undefined): HttpHeaders {
    let httpHeaders: HttpHeaders = new HttpHeaders();
    if (offeringUuid !== undefined && offeringUuid !== null) {
      httpHeaders = httpHeaders.set('x-offering-id', offeringUuid);
    }
    if (correlationId !== undefined && correlationId !== null) {
      httpHeaders = httpHeaders.set('x-correlation-id', correlationId);
    }
    return httpHeaders;
  }
}
