<div fxLayout="column" fxLayoutAlign="center">
  <div class="dialog-header my-2" fxLayout="row" fxLayoutAlign="space-between center">
    <span style="width: 24px"></span>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
      <div class="mat-headline filter-heading">Filter</div>
    </div>
    <mat-icon (click)="close()" class="btn-cursor mb-3">close</mat-icon>
  </div>
  <div class="separator-band"></div>

  <mat-dialog-content fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="15px" style="overflow: hidden">
    <div *ngFor="let cat of filterItems | keyvalue"
         class="full-width"
         fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
      <div *ngIf="cat.value.length > 0"
           style="text-transform: capitalize; font-weight: bolder;">{{ displayCategoryName(cat.key) }}</div>
      <div class="full-width" fxLayout="row wrap"
           fxLayoutAlign="space-between start">
        <div *ngFor="let item of cat.value"
             fxFlex="47" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px"
               fxLayoutGap.gt-sm="10px">
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px" fxLayoutGap.gt-sm="10px">
              <mat-icon class="material-icons-outlined service-icon">{{ item.icon }}</mat-icon>
              <p class="error-popup-description">{{ item.name }}</p>
            </div>
            <mat-slide-toggle [(ngModel)]="item.checked" [checked]="item.checked"></mat-slide-toggle>
          </div>
        </div>
      </div>

    </div>

  </mat-dialog-content>

  <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="15px">
    <div fxFlex="40">
      <button
        (click)="clearFilter()"
        class="form-button-pd mat-button-light mat-elevation-z4"
        mat-button style="width: 100% !important;"
      >
        <mat-icon class="material-icons-outlined">filter_alt_off</mat-icon>
        Clear
      </button>
    </div>
    <div fxFlex="60">
      <button
        (click)="applyFilter()"
        class="form-button-pd mat-button-dark mat-elevation-z4" mat-button style="width: 100% !important;"
      >
        Apply
      </button>
    </div>
  </div>
</div>
