import {Injectable} from "@angular/core";
import * as uuid from 'uuid'

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  uuidRegex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
  randFormatCompact = new Intl.NumberFormat('en-ZA', {style: 'currency', currency: 'ZAR', maximumFractionDigits: 0, notation: 'compact'});

  capitalizeAndLower(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  toTitleCase(sentence: string): string {
    return sentence.split(" ").map((l: string) => l[0].toUpperCase() + l.substr(1)).join(" ")
  }

  displayUuid(uuid: string): string {
    if (uuid) {
      return uuid.substring(0, 8).toUpperCase();
    }
    return uuid;
  }

  displayStatus(status: string): string {
    if (status) {
      const words = status.split('_');
      const formatted = words.map(word => {
        const lowerCase = word.toLowerCase();
        return lowerCase[0].toUpperCase() + lowerCase.slice(1);
      });
      return formatted.join(' ');
    }
    return '';
  }

  displayString(string: string | null, length: number): string {
    if (string != null) {
      if (string.length > length) {
        return (string.slice(0, length - 3) + '...');
      }
      return string;
    }
    return '-';
  }

  displayListAsString(list: string[]) {
    if (list) {
      return list.join(', ');
    }
    return '';
  }

  displayNumber(amount: number | null, showZero?: boolean): string {
    if (amount) {
      return amount.toLocaleString('en-ZA', {maximumFractionDigits: 0});
    }
    return showZero ? '0' : '-';
  }

  displayRandAmount(amount: number | null): string {
    if (amount) {
      return amount.toLocaleString('en-ZA', {style:'currency', currency: 'ZAR', maximumFractionDigits: 0});
    }
    return '-';
  }

  displayCompactNumber(amount: number | null): string {
    if (amount == 0) {
      return '0';
    }
    if (amount) {
      const formatter = Intl.NumberFormat('en', { notation: 'compact' });
      return formatter.format(amount);
    }
    return '-';
  }


  isValidUuid(inputUuid: string): boolean {
    return this.uuidRegex.test(inputUuid);
  }

  displayRandAmountWithDecimal(amount: number | null, zero?: boolean): string {
    if (!amount && zero) amount = 0.001;
    if (amount) {
      return amount.toLocaleString('en-ZA', {style:'currency', currency: 'ZAR', maximumFractionDigits: 2});
    }
    return '-';
  }

  displayCompactRandAmount(amount: number | null): string {
    if (amount) {
      return this.randFormatCompact.format(amount);
    }
    return '-';
  }

  displayDecimal(amount: number | null, digits: number): string {
    if (amount) {
      return amount.toLocaleString('en-ZA', {maximumFractionDigits: digits});
    }
    return '-';
  }

  openLinkInNewTab(link: string): void {
    window.open(link, '_blank');
  }

  generateUuid(prefix: string): string {
    return prefix + '-' + uuid.v4();
  }

  arrayNotEmptyFilter<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
  }
}
