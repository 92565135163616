import {Injectable} from '@angular/core';
import {FormState} from "../../features/applications/application-form/offering-application.model";

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  menu: MenuSection[] = [{
    label: 'Forms',
    order: 1,
    selected: true,
    subSections: [
      {
        label: 'Application',
        order: 1,
        icon: 'remove_from_queue',
        url: 'forms/applications',
        urlParams: {formState: FormState.IN_APP},
        featureCodes: [
          'district_retailer_applications_edit',
          'district_retailer_applications_view',
        ]
      },
      {
        label: 'Turnover',
        order: 3,
        icon: 'add_to_queue',
        url: 'forms/turnover',
        urlParams: {},
        featureCodes: [
          'retailer_forms_turnover_edit',
          'retailer_forms_turnover_view',
        ]
      }
    ]
  },
    {
      label: 'Leases',
      order: 2,
      selected: true,
      subSections: [{
        label: 'Current lease',
        order: 1,
        url: 'leases/current-lease',
        urlParams: {},
        icon: 'assignment_ind',
        featureCodes: [
          'district_retailer_leases_edit',
          'district_retailer_leases_view',
          'leasing_current_lease_view',
        ]
      },
        {
          label: 'Leasing history',
          order: 2,
          url: 'leases/history',
          urlParams: {},
          icon: 'assignment',
          featureCodes: [
            'leasing_leasing_history_view',
            'leasing_leasing_history_view_all'
          ]
        }]
    },
    {
      label: 'Accounts',
      order: 3,
      selected: true,
      subSections: [{
        label: 'Transactions',
        order: 1,
        icon: 'sell',
        url: 'accounts/transactions',
        urlParams: {},
        featureCodes: [
          "retailer_accounts_transactions_view_schedule",
          "retailer_accounts_transactions_view_all",
          "retailer_accounts_transactions_view_chart",
        ]
      },
        {
          label: 'Pay-outs',
          order: 2,
          icon: 'account_balance_wallet',
          url: 'accounts/payouts',
          urlParams: {},
          featureCodes: [
            'district_retailer_accounts_edit',
            'accounts_payouts_view',
          ]
        }]
    },
    {
      label: 'Settings',
      order: 4,
      selected: true,
      subSections: [
        {
          label: 'Company information',
          order: 1,
          icon: 'room_preferences',
          iconSVG: false,
          url: 'settings/brand-information',
          urlParams: {},
          featureCodes: ['retailer_settings_brand_info_view',
            'retailer_settings_brand_info_edit']
        },
        {
          label: 'Billing and payment',
          order: 2,
          icon: 'credit_score',
          iconSVG: false,
          url: 'settings/billing',
          urlParams: {},
          featureCodes: [
            'retailer_settings_billing_view',
            'retailer_settings_billing_edit',
          ]
        },
        {
          label: 'Users and access',
          order: 3, icon: 'manage_accounts',
          iconSVG: false,
          url: 'settings/users',
          urlParams: {},
          featureCodes: [
            'retailer_settings_users_edit',
            'retailer_settings_users_view'
          ]
        }
      ]
    }
  ];

  public getMenu(): MenuSection[] {
    return this.menu;
  }
}


export interface MenuSection {
  label: string;
  order: number;
  selected?: boolean;
  subSections?: MenuSection[];
  icon?: string;
  url?: string;
  urlParams?: object;
  iconSVG?: boolean;
  featureCodes?: string[]
}
