import {Inject, Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class GenericService<T = any> {

  baseUrl: string = '';

  constructor(protected http: HttpClient, @Inject('baseUrl') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  getAll(suffix: string, page: { size: string, page: string, sort: string }, params?: {name: string, val: any}[]): Observable<{content: T[]}> {
    let requestParams = new HttpParams()
      .set('sort', page.sort)
      .set('size', page.size)
      .set('page', page.page);

    if (params) {
      params.filter(f => f.val !== null).forEach(ea => {requestParams = requestParams.append(ea.name, ea.val)});
    }
    return this.http.get<{content: T[]}>(this.baseUrl + suffix, {params: requestParams});
  }

  getOne(suffix: string, id: string): Observable<T> {
    return this.http.get<T>(this.baseUrl + suffix + `/${id}`);
  }
}
