<div fxLayout="column" fxLayoutAlign="center">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <span style="width: 24px"></span>
    <div class="title-default">{{ header ? header : 'Oops!' }}</div>
    <mat-icon (click)="close()" class="btn-cursor mb-2">close</mat-icon>
  </div>
  <mat-divider fxFlexOffset="20px"></mat-divider>

  <mat-dialog-content fxLayout="column" fxLayoutAlign="center center">
    <div class="full-width" fxLayout="row" fxLayoutAlign="center center">
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px">
        <p class="heading-default">{{ description }}</p>
        <p *ngIf="boldDescription" class="subheading-default">
          {{ boldDescription }}
        </p>
        <p>Please contact a system administrator</p>
      </div>
    </div>
  </mat-dialog-content>
</div>
