<div class="full-width" fxLayout="column" fxLayoutGap="30px">
  <div class="dashboard-card full-width" fxLayout="column" fxLayoutGap="10px" *ngFor="let stat of chartStats">
    <div class="dashboard-icon">
      <mat-icon class="material-icons-outlined">{{icon}}</mat-icon>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div class="subtitle-default">{{ chart.stats[stat].statName }}</div>
      <div class="body-1-default">{{ dashboardUtils.displayBestStat(chart.stats[stat], fullNumber) }}</div>
    </div>
    <div fxLayout="column" fxLayoutGap="5px">
      <div class="body-1-regular-2">{{ chart.stats[stat].statDescription }}</div>
      <div class="body-2-default" *ngIf="chart.dateRange">
        ({{ dateUtils.displayDateWithFormat(chart.dateRange.start, 'DD MMM YYYY') }} to
        {{ dateUtils.displayDateWithFormat(chart.dateRange.end, 'DD MMM YYYY') }})
      </div>
    </div>
  </div>
</div>
