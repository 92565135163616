<div class="question-card full-width ps-0" fxLayout="column" fxLayoutGap="10px">
    <div class="full-width" fxLayout="row" fxLayoutAlign="start start">
        <div class="question-card-header" fxLayout="column" fxLayoutAlign="start start" style="width: 24px">
            <mat-icon class="more-icon">more_vert</mat-icon>
        </div>
        <div fxLayout="column" fxLayoutAlign="start start" fxFlexOffset="20px" fxLayoutGap="20px" fxLayoutGap.gt-sm="15px"
            class="full-width">
            <div class="pt-1 full-width form-field-padding-wrapper">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
    <div *ngIf="!lastRow" class="full-width" fxLayout="column">
        <mat-divider class="full-width" fxFlexOffset="30px"></mat-divider>
    </div>
</div>
