<div *ngIf="selectedOffering == null; else homePageLanding" fxLayout="column">
  <app-page-header [banner]="true"
                   [backgroundWhite]="true"
                   [fullHeight]="false"
                   [description]="'Select one of the retail rental offerings below to access.'"
                   [descriptionAboveLine]="true"
                   [hasSearchBar]="true"
                   [searchBarLabel]="'Search rental space'"
                   (searchBarValueChange)="searchOfferings($event)"
                   [title]="'Pick Your Retail Space'">
  </app-page-header>

  <div class="full-screen-height-page-banner full-width" fxLayout="row">
    <app-offering-carousel
                           [changeContext]="true"
                           [selectedOffering]="selectedOffering"
                           [offeringNameSearchVal]="this.offeringNameSearchValue"
                           [isPublicRoute]="false"
                           [showSelectionOnHover]="true">
    </app-offering-carousel>

  </div>
</div>

<ng-template #homePageLanding>
  <div>
    <app-page-header [banner]="false"
                     [fullHeight]="true"
                     [description]="'Discover all the features you can use to view and manage your rental space. Based on your trading status you can click on the side menu to start exploring.'"
                     [section]="'Home Page'"
                     [title]="getTitle()">
    </app-page-header>
  </div>
</ng-template>
