<div class="px-3 py-2 pb-3 password-reset-gap-container full-screen-height" fxLayout="column" fxLayoutAlign="center center"
     fxLayoutGap="15px">

  <div class="full-width flex-column pre-auth-header-div" fxLayoutAlign="center center">
    <h1 class="pre-auth-header"> Please verify your email</h1>
    <h2 class="pre-auth-sub-header"> We have sent a One Time Pin to {{this.userEmail}}</h2>
  </div>

  <div fxFlexOffset="30px"></div>

  <div class="flex-row">
    <div class=" p3 full-width flex-column gap-important" fxLayoutAlign="center center" fxLayoutGap="15px">
      <div class="otp-text" fxFlexOffset="10px" fxFlexOffset.gt-sm="15px">
        <ng-otp-input
          #ngOtpInput
          (onInputChange)="onOtpChange($event)"
          [config]="{
              length: 4,
              allowNumbersOnly: true,
              containerClass: 'otp-wrapper-element',
              inputClass: 'otp-input-element'
            }"
        ></ng-otp-input>
      </div>
      <mat-error *ngIf="errMessage">{{ errMessage }}</mat-error>

      <div class="" fxFlexOffset="30px" fxFlexOffset.gt-sm="30px" fxLayoutGap="20px">
        <button style="width: 200px" (click)="onVerify()" [disabled]="loading || len < 4 || (loader.loading$ | async)" class="button-primary" mat-flat-button>
          <div class="button-text">{{ loading ? 'Working...' : 'Verify'}}</div>
          <mat-icon *ngIf="loading">
            <mat-spinner diameter="20"></mat-spinner>
          </mat-icon>
        </button>
      </div>

      <div class="text-center" fxFlexOffset.gt-sm="15px">
        Didn’t receive an OTP?
        <a (click)="!loading ? onResendOTP() : null" [routerLink]="" class="link-text btn-cursor">Resend OTP.</a>
      </div>
    </div>
  </div>
</div>

