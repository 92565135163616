import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {GenericDatasource} from "../../../../shared/datasource/generic.datasource";
import {DailyTransactionsSummary} from "../../accounts.model";
import {AccountsService} from "../../accounts.service";
import {DateUtilsService} from "../../../../shared/services/dateUtils.service";
import {ChartConfiguration, ChartData} from "chart.js";
import {DateRange} from "@angular/material/datepicker";
import {barChartOptions, barChartPlugins} from "./chart-config.constant";
import {PaginatorService} from "../../../../shared/services/paginator.service";
import {UtilsService} from "../../../../shared/services/utils.service";
import {
  CustomDateRangeChangeEventModel
} from "../../../../shared/components/custom-date-filter/custom-date-range-change-event.model";
import {tap} from "rxjs";
import {CurrentContextService} from "../../../../core/services/security/current-context.service";
import {
  CustomDateRangeModel,
  TimeFrames
} from "../../../../shared/components/custom-date-filter/custom-date-range.model";
import {chartColors} from "../../../dashboards/chart-options/chart-colors.model";

@Component({
  selector: 'app-transaction-summary',
  templateUrl: './transaction-summary.component.html',
  styleUrls: ['./transaction-summary.component.scss']
})
export class TransactionSummaryComponent implements OnInit, AfterViewInit {
  @ViewChild('paginator') paginator!: MatPaginator;

  dataSource = new GenericDatasource<DailyTransactionsSummary>(this.accountsService);
  dataSourceDisplayed = new GenericDatasource<DailyTransactionsSummary>(this.accountsService);
  displayedColumns = ['transactionDate', 'saleAmountIncl', 'transactionFeeIncl', 'turnoverRentalFeeIncl', 'netBalancePayableRetailerInclRounded', 'chart'];

  // @ts-ignore
  chartOptions: ChartConfiguration<'line'>['options'] = barChartOptions;
  chartPlugins = barChartPlugins;
  chartData: ChartData<'line'> = {
    labels: [],
    datasets: [
      {data: []}
    ]
  }

  dateRange: DateRange<Date | null> = new DateRange<Date | null>(null, null);
  dateFilters: CustomDateRangeModel[] = [
    new CustomDateRangeModel('1', 1, TimeFrames.DAYS, 'Today'),
    new CustomDateRangeModel('2', 7, TimeFrames.DAYS, 'Last 7 Days')
  ]

  constructor(private accountsService: AccountsService,
              public dateUtils: DateUtilsService,
              private paginatorService: PaginatorService,
              public utils: UtilsService,
              private currentContext: CurrentContextService) {
    this.dateRange = this.dateFilters.at(1)!.getDateRange();
  }

  ngOnInit() {
    this.dataSource = new GenericDatasource<DailyTransactionsSummary>(this.accountsService);
    const filters = [
      {name: 'fromDate', val: this.dateUtils.displayShortDate(this.dateRange.start)},
      {name: 'toDate', val: this.dateRange.end ? this.dateUtils.displayShortDate(this.dateRange.end) : null},
    ]
    this.dataSource.loadData(`/${this.currentContext.currentRetailer.id}/accounts/transactions/summary`, {
      size: '10',
      page: '0',
      sort: 'transactionDate,desc'
    }, filters);
    this.loadChartData();

    this.dataSource.loading$.subscribe({
      next: value => {
        if (!value) {
          this.checkTableRows();
        }
      }
    })
  }

  ngAfterViewInit() {
    this.paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    this.paginator.page
      .pipe(
        tap(() => {this.loadTransactionsSummary(); this.loadChartData()}))
      .subscribe();
  }

  loadTransactionsSummary() {
    const filters = [
      {name: 'fromDate', val: this.dateUtils.displayShortDate(this.dateRange.start)},
      {name: 'toDate', val: this.dateRange.end ? this.dateUtils.displayShortDate(this.dateRange.end) : null},
    ]
    let page;
    if (this.paginator) {
      page = {
        size: this.paginator.pageSize.toString(),
        page: this.paginator.pageIndex.toString(),
        sort: 'transactionDate,desc'
      }
    } else {
      page = {size: '10', page: '0', sort: 'transactionDate,desc'}
    }
    this.dataSource.loadData(`/${this.currentContext.currentRetailer.id}/accounts/transactions/summary`, page, filters);
  }

  loadChartData() {
    const filters = [
      {name: 'fromDate', val: this.dateUtils.displayShortDate(this.dateRange.start)},
      {name: 'toDate', val: this.dateRange.end ? this.dateUtils.displayShortDate(this.dateRange.end) : null},
    ]
    this.accountsService.getDailyTransactionChartData(filters).subscribe({
      next: value => {
        this.chartData = {
          labels: value.chartData.map(m => this.dateUtils.displayShortDate(m.dateLabel)),
          datasets: [{
            data: value.chartData.map(m => m.data),
            backgroundColor: chartColors.BLUE,
            borderColor: chartColors.BLUE,
            label: 'Sales',
            pointBackgroundColor: chartColors.BLUE,
            pointBorderColor: chartColors.BLUE,
            fill: true,
            spanGaps: true,
            pointRadius: 0
          }]
        }
      }
    })
  }

  getDateRange(event: CustomDateRangeChangeEventModel) {
    this.dateRange = event.dateRange;
    this.loadTransactionsSummary();
    this.loadChartData();
  }

  checkTableRows() {
    const finalPage = this.paginator.pageIndex == (this.dataSource.totalPages - 1);
    const rowsToAdd = this.dataSource.totalPages * 10 - this.dataSource.totalElements;
    if (this.dataSource && finalPage) {
      const data = this.dataSource.getData();
      for (let i = 0; i < rowsToAdd; i++) data.push(Object.create(null));
      this.dataSourceDisplayed.updateData(data);
    } else {
      this.dataSourceDisplayed.updateData(this.dataSource.getData());
    }
  }


}
