import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {LeaseStatus, LeaseSummary} from "../../leasing.model";
import {GenericDatasource} from "../../../../shared/datasource/generic.datasource";
import {LeasingService} from "../../leasing.service";
import {DateUtilsService} from "../../../../shared/services/dateUtils.service";
import {tap} from "rxjs";
import {PaginatorService} from "../../../../shared/services/paginator.service";
import {CurrentContextService} from "../../../../core/services/security/current-context.service";
import {UtilsService} from "../../../../shared/services/utils.service";

@Component({
  selector: 'app-leasing-schedule',
  templateUrl: './leasing-schedule.component.html',
  styleUrls: ['./leasing-schedule.component.scss']
})
export class LeasingScheduleComponent implements OnInit, AfterViewInit {
  @ViewChild('paginator') paginator!: MatPaginator;

  displayedColumns: string[] = ['startDate', 'endDate', 'spaceName', 'size', 'rentalType', 'monthlyRentalFee', 'expiresIn', 'leaseStatus'];
  dataSource = new GenericDatasource<LeaseSummary>(this.leasingService);

  constructor(private leasingService: LeasingService,
              public utils: UtilsService,
              public dateUtils: DateUtilsService,
              private paginatorService: PaginatorService,
              public currentContext: CurrentContextService) {
  }

  ngOnInit() {
    this.dataSource = new GenericDatasource<LeaseSummary>(this.leasingService);

    this.dataSource.loadData(`/${this.currentContext.currentRetailer.id}/leasing/history`, {size: '10', page: '0', sort: 'leaseStartDate,desc'}, []);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.dataSource.totalElements > 0) {
        this.loadPaginator();
      }
    }, 500)
  }

  loadPaginator() {
    this.paginator._intl.getRangeLabel = this.paginatorService.getRangeDisplayText;
    this.paginator.page
      .pipe(
        tap(() => this.loadLeases()))
      .subscribe();
  }

  loadLeases() {
    const page = {size: this.paginator.pageSize.toString(), page: this.paginator.pageIndex.toString(), sort: 'leaseStartDate,desc'}
    this.dataSource.loadData(`/${this.currentContext.currentRetailer.id}/leasing/history`, page, []);
  }

  getLeaseStatus(lease: LeaseSummary) {
    switch (lease.leaseStatus) {
      case LeaseStatus.APPROVED:
        return { status: this.utils.displayStatus('APPROVED'), icon: 'check_circle', outline: true, className: 'success-chip'};
      case LeaseStatus.ACTIVE:
        return { status: this.utils.displayStatus('ACTIVE'), icon: 'check_circle', outline: false, className: 'success-chip'};
      case LeaseStatus.EXPIRED:
        return { status: this.utils.displayStatus('EXPIRED'), icon: 'check_circle', outline: false, className: 'disabled-chip'};
      case LeaseStatus.TERMINATED:
        return { status: this.utils.displayStatus('TERMINATED'), icon: 'cancel', outline: false, className: 'disabled-chip'};
      default:
        return { status: this.utils.displayStatus('PLANNED'), icon: 'check_circle', outline: true, className: 'warn-chip'};
    }
  }

  displayRentalType(type: string | null) {
    return this.leasingService.getRentalType(type);
  }
}
