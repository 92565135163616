import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {IndustryCategory, RegisterRetailer, Retailer} from '../model/retailer.model';
import {BaseService} from './base.service';

@Injectable({
  providedIn: 'root',
})
export class RetailerService extends BaseService {
  baseUrl: string = environment.apiRetailersHost + '/portal/retailer';

  constructor(private http: HttpClient) {
    super();
  }

  public registerNewRetailer(request: RegisterRetailer): Observable<Retailer> {
    const uri = this.baseUrl;
    return this.http.post<Retailer>(uri, request, this.options);
  }

  public updateRetailer(request: any): Observable<any> {
    const uri = this.baseUrl;
    return this.http.put<Retailer>(uri, request, this.options);
  }

  public getRetailer(): Observable<Retailer> {
    const uri = this.baseUrl;
    return this.http.get<Retailer>(uri, this.options);
  }

  public getIndustries(): Observable<IndustryCategory[]> {
    const uri = environment.apiRetailersHost + '/api/retailer-industries';
    return this.http.get<IndustryCategory[]>(uri, this.options);
  }

  public getPublicIndustries(): Observable<IndustryCategory[]> {
    const uri = environment.apiRetailersHost + '/api/public/retailer-industries';
    return this.http.get<IndustryCategory[]>(uri, this.options);
  }
}
