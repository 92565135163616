import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

import {CommunicationDialogInterface} from '../communication-dialog.interface';
import {FilterItems} from '../../../../core/model/district.model';

@Component({
  selector: 'app-filter-popup',
  templateUrl: './filter-popup.component.html',
  styleUrls: ['./filter-popup.component.scss']
})
export class FilterPopupComponent implements OnInit {
  @Output() filterDistricts = new EventEmitter<any>();

  filterItems: {
    services: FilterItems[],
    products: FilterItems[],
    lease_types: FilterItems[]
  };

  constructor(private dialogRef: MatDialogRef<FilterPopupComponent>,
              @Inject(MAT_DIALOG_DATA) data: CommunicationDialogInterface) {
    this.filterItems = data.filters ? data.filters : {services: [], products: [], lease_types: []};
  }

  ngOnInit(): void {
  }

  clearFilter(): void {
    Object.keys(this.filterItems).forEach(key => {
      this.filterItems[key as keyof typeof this.filterItems].forEach(item => item.checked = false);
    });
  }

  applyFilter(): void {
    this.filterDistricts.emit(this.filterItems);
    this.close();
  }

  confirm(): void {
    this.dialogRef.close(true);
  }

  close(): void {
    this.dialogRef.close(false);
  }

  displayCategoryName(text: string): string {
    return text.split('_').join(' ');
  }

}
