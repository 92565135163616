<div fxLayout="column">

  <app-page-header [banner]="true"
                   [backgroundWhite]="true"
                   [fullHeight]="false"
                   [description]="'Select one of the retail rental offerings below to access.'"
                   [descriptionAboveLine]="true"
                   [hasSearchBar]="true"
                   [searchBarLabel]="'Search rental space'"
                   (searchBarValueChange)="searchOfferings($event)"
                   [title]="'Pick Your Retail Space'">
  </app-page-header>


  <div class="full-screen-height-page-banner full-width" fxLayout="row">
    <app-offering-carousel style="width: 100%;"
      [changeContext]="true"
      [isPublicRoute]="true"
      [offeringNameSearchVal]="this.offeringNameSearchValue"
      [showSelectionOnHover]="true">
    </app-offering-carousel>
  </div>
</div>
