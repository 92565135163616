import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {LocationOffering, TradingHours} from '../../../../core/model/location-offering.interface';
import {GermanAddress, Location} from '@angular-material-extensions/google-maps-autocomplete';
import {FormControl, FormGroup} from '@angular/forms';
import {AgmMap, MapsAPILoader} from '@agm/core';
import {environment} from '../../../../../environments/environment';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {GenericTypeDefinition} from "../../../../core/model/retailer.model";
import {MatSnackBar} from "@angular/material/snack-bar";
import PlaceResult = google.maps.places.PlaceResult;
import {CurrentContextService} from "../../../../core/services/security/current-context.service";

@Component({
  selector: 'app-offering-listing',
  templateUrl: './offering-listing.component.html',
  styleUrls: ['./offering-listing.component.scss']
})
export class OfferingListingComponent implements OnInit {
  @ViewChild("agmMap") agmMap!: AgmMap;
  @Input() offering!: LocationOffering;
  @Input() isPublic!: boolean;
  @Output() toggleDrawer = new EventEmitter<boolean>();
  @Output() apply = new EventEmitter<void>();
  @Output() enter = new EventEmitter<void>();

  public zoom: number = 0;
  public latitude: number = 0;
  public longitude: number = 0;
  public selectedAddress: PlaceResult | null = null;
  addressForm: FormGroup;
  private listOfDays: TradingHours[] = [
    {
      name: "Monday"
    },
    {
      name: "Tuesday"
    },
    {
      name: "Wednesday"
    },
    {
      name: "Thursday"
    },
    {
      name: "Friday"
    },
    {
      name: "Saturday"
    },
    {
      name: "Sunday"
    },
    {
      name: "Public Holidays"
    }
  ];

  shortenCategories: boolean = true;
  showMoreLabel: string = 'Show more';

  constructor(private cd: ChangeDetectorRef,
              private mapsAPILoader: MapsAPILoader,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer,
              private _snackBar: MatSnackBar,
              private context: CurrentContextService) {
    this.matIconRegistry.addSvgIcon('back-arrow-button', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../assets/icons/back-arrow-button.svg'));
    this.matIconRegistry.addSvgIcon('location-away', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../assets/icons/location_away.svg'));


    this.addressForm = new FormGroup({
      address: new FormControl('')
    });

    if (this.offering && this.offering.offeringAddress) {
      this.addressForm.get('address')!.setValue(this.offering.offeringAddress);
      this.mapsAPILoader.load().then(() => {
        this.geocode(this.offering.offeringAddress!).then(place => {
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
        })
          .catch(err => {
            console.log(err);
          });
      });
    }
    this.addressForm.disable();
  }

  ngOnInit(): void {
    this.zoom = 10;
    this.cd.detectChanges();
    this.setCurrentPosition();
  }

  geocode(address: string): Promise<any> {
    const geocoder = new google.maps.Geocoder();
    return new Promise((resolve, reject) => {
      geocoder.geocode(
        {
          address: address
        },
        (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            resolve(results[0]);
          } else {
            reject(new Error(status));
          }
        }
      );
    });
  }

  onAutocompleteSelected(result: PlaceResult) {
    this.onLocationSelected({longitude: result.geometry!.location.lat(), latitude: result.geometry!.location.lng()})
  }

  onLocationSelected(location: Location) {
    this.latitude = location.latitude;
    this.longitude = location.longitude;
  }

  onGermanAddressMapped($event: GermanAddress) {
    this.latitude = $event.geoLocation!.latitude;
    this.longitude = $event.geoLocation!.longitude;
  }

  getTradingHours(): TradingHours[] | null {
    return this.listOfDays;
  }

  displayTimeRange(day: TradingHours): string {

    if (this.offering && this.offering.offeringListing!.tradingHours) {
      let index: number = this.offering.offeringListing!.tradingHours.findIndex((trading: TradingHours) => trading.name.toLowerCase() === day.name.toLowerCase());
      if (index >= 0) {
        let chosenDay = this.offering.offeringListing!.tradingHours.at(index)!;
        if (chosenDay.startTime && chosenDay.endTime) {
          return `${chosenDay.startTime} - ${chosenDay.endTime}`;
        }
      }
    }
    return 'Closed';
  }

  getUrlToListing(): string {
    return `${environment.apiPortal}/${this.context.getCurrentLocationCode()}/${this.offering.offeringCode}/home`;
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', { duration: 1000, verticalPosition: 'top' });
  }

  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 12;
      });
    }
  }

  displayOfferingCategories(): GenericTypeDefinition[] {
    if (this.shortenCategories) {
      if (this.offering.offeringListing!.offeringListingIndustries.length > this.getTradingHours()!.length) {
        this.showMoreLabel = 'Show more';
        return this.offering.offeringListing!.offeringListingIndustries.slice(0, this.getTradingHours()!.length - 1);
      }
      this.showMoreLabel = '';
      return this.offering.offeringListing!.offeringListingIndustries;
    }
    this.showMoreLabel = 'Show less';
    return this.offering.offeringListing!.offeringListingIndustries;
  }

  openMapInNewTab() {
    const url = `https://www.google.com/maps/search/?api=1&query=${this.latitude},${this.longitude}`;
    window.open(url, '_blank');
  }
}
