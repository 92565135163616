import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-application-form-row-label',
  templateUrl: './application-form-row-label.component.html',
  styleUrls: ['./application-form-row-label.component.scss']
})
export class ApplicationFormRowLabelComponent {
  @Input() lastRow?: boolean = false;
}
